"use client";
import React from "react";
import { Col, Container, Row } from "reactstrap";

import Image from "next/image";

import buttonStyles from "@/styles/buttons.module.css";

import appStoreBadge from "../../../public/images/no-app-store-badge-white.svg";
import googlePlayBadge from "../../../public/images/no-google-play-badge-white.svg";
import voi from "../../../public/images/app-screenshots/no/voi3.png";
import bil from "../../../public/icons/kompaktbil.svg";
import scooter from "../../../public/icons/scooter.svg";
import DownloadLink from "@/components/Downloadlink";

export default function Voi() {
  return (
    <div>
      <div
        className="voi-header-background-cover"
        style={{
          marginBottom: "50px",
        }}
      >
        <Container>
          <Row style={{ marginBottom: "50px", marginTop: "50px" }}>
            <Col className="text-center">
              <h3 className="voi text-center" style={{ color: "white" }}>
                Bruk koden <u>VOI24</u>
              </h3>
              <h1 className="voi text-center mb-4">
                Få 24 timer gratis leiebil på første leie
              </h1>

              <DownloadLink
                className={`show-on-mobile btn ${buttonStyles.btnElectricBlue}`}
              >
                Last ned appen og kjør nå.
              </DownloadLink>

              <Row className="show-on-desktop">
                <Col>
                  <DownloadLink>
                    <Image
                      src={googlePlayBadge}
                      alt="Tilgjengelig på Google Play"
                      height={45}
                      style={{
                        marginRight: "13px",
                      }}
                    />
                  </DownloadLink>
                  <DownloadLink>
                    <Image
                      src={appStoreBadge}
                      alt="Last ned fra App Store"
                      height={45}
                    />
                  </DownloadLink>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      <div>
        <Container>
          <Image src={bil} height={40} className="px-0" alt="bil" />{" "}
          <Image src={scooter} height={40} className="px-0" alt="scooter" />{" "}
          <Image src={bil} height={40} className="px-0" alt="bil" />{" "}
          <Image src={scooter} height={40} className="px-0" alt="scooter" />
          <h2 className="big-fat-header">Sammen om en bedre by.</h2>
          <p>
            Hyre og Voi inngår samarbeid på tvers av de skandinaviske landene
            med mål om å gjøre det enda enklere for flere å kvitte seg med egen
            bil.
          </p>
          <p>
            Samarbeidet er en integrasjon mellom Hyre og Voi som gjør det mulig
            å bruke flere former for transport i en bestilling.
          </p>
          <p>
            I tillegg til at du kan parkere scooteren på parkeringsstativer ved
            Hyres parkeringsplasser, kan du enkelt finne Voi-scootere i
            Hyre-appen.
          </p>
          <Image
            src={voi}
            alt="voi i hyre appen"
            style={{ width: "100%", height: "auto" }}
          />
          <p>
            I Hyre-appens sidemeny kan du nå velge “Finn Voi-scooter” for å få
            opp et kart over alle Voi-scootere i nærheten - akkurat slik som du
            er vant med å se kartet i Voi-appen. Velg en scooter og trykk “Book
            med Voi” for å bli sendt videre til Voi-appen for å fullføre
            bestillingen og betalingen.
          </p>
        </Container>
      </div>
    </div>
  );
}
