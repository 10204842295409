"use client";
import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";

import Link from "next/link";
import Image from "next/image";
import BedriftLink from "@/components/B2BLink";
import Faq from "@/components/Faq";
import { TabContent, TabPane } from "reactstrap";

import "./bedrift.css";

import chevronRight from "../../../public/images/chevron-right.svg";
import invitasjon from "../../../public/images/b2b/invitasjon2.png";
import jobbprivat from "../../../public/images/b2b/jobb_privat_ikon.svg";
import epost from "../../../public/images/b2b/e-post_ikon.svg";
import epost2 from "../../../public/images/b2b/e-post_ikon2.svg";

export default function BedriftGetStartedLaunch() {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <div>
      {/* <Helmet title="Planlegg lansering - Hyre for Bedrift" /> */}

      <Container
        className="text-center text-md-start"
        style={{ marginTop: "70px", paddingTop: "50px" }}
      >
        <Row>
          <Col xs="12" md="8">
            <h1 className="header big-fat-header" style={{ color: "black" }}>
              Steg 3: Planlegg lansering
            </h1>
            <p>
              Før du lanserer er det lurt å sørge for at alle ansatte er godt
              informert om den nye ordningen. Overraskende invitasjoner på mail
              kan forårsake forvirring og unødvendig tidsbruk på oppklarende
              kommunikasjon.
            </p>
            <p>
              Når du inviterer et nytt medlem til bedriftskontoen vil
              vedkommende automatisk få en invitasjon på mail med informasjon om
              hvordan Hyre fungerer og hvilke tilganger man har fått. Se bilde
              for utdrag.
            </p>
            <p>
              For å gjøre onboardingen sømløs anbefaler vi å sende ut en kort
              intro mail før man legger til medlemmene. Her har vi samlet litt
              relevant informasjon du kan inkludere, samt et ferdig forslag til
              intern kommunikasjon:
            </p>
          </Col>
          <Col xs="12" md="4">
            <Image
              src={invitasjon}
              alt=""
              className="align-self-center"
              style={{ width: "100%", height: "auto" }}
            />
          </Col>
        </Row>
      </Container>

      <Container>
        <div className="bedrift">
          <div className="navigation-header">
            <a
              className={activeTab === 0 ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                setActiveTab(0);
              }}
            >
              Nyttig info
            </a>
            <a
              className={activeTab === 1 ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                setActiveTab(1);
              }}
            >
              Eksempel til e-post
            </a>
            <a
              className={activeTab === 2 ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                setActiveTab(2);
              }}
            >
              Kom i gang-PDF
            </a>
          </div>

          <TabContent activeTab={activeTab}>
            <TabPane tabId={0}>
              <Container className="text-center text-md-start mb-6 mb-md-6">
                <div className="row">
                  <div className="col-12 col-md-4 d-flex flex-column">
                    <Image
                      src={epost}
                      alt=""
                      height={150}
                      width={360}
                      className="align-self-center"
                      style={{
                        objectFit: "contain",
                        paddingTop: "30px",
                        paddingBottom: "30px",
                      }}
                    />
                    <h3 className="header" style={{ paddingBottom: "10px" }}>
                      Sjekk din epost
                    </h3>
                    <p>
                      Alle ansatte mottar automatisk en mail med instruksjoner
                      for å knyttes bedriftskontoen. De må godta invitasjonen
                      med BankID før de kan booke og kjøre bil på bedriftens
                      regning.
                    </p>
                  </div>
                  <div className="col-12 col-md-4 d-flex flex-column">
                    <Image
                      src={epost2}
                      alt=""
                      height={150}
                      width={360}
                      className="align-self-center"
                      style={{
                        objectFit: "contain",
                        paddingTop: "30px",
                        paddingBottom: "30px",
                      }}
                    />
                    <h3 className="header" style={{ paddingBottom: "10px" }}>
                      Ikke videresend invitasjonen
                    </h3>
                    <p>
                      Hver invitasjon er unik og kan ikke deles med andre.
                      Dersom en invitasjon sendes videre vil den ikke kunne
                      brukes igjen, og den som mottar en videresendt invitasjon
                      blir knyttet til feil e-postadresse.
                    </p>
                  </div>
                  <div className="col-12 col-md-4 d-flex flex-column">
                    <Image
                      src={jobbprivat}
                      alt=""
                      height={150}
                      width={360}
                      className="align-self-center"
                      style={{
                        objectFit: "contain",
                        paddingTop: "30px",
                        paddingBottom: "30px",
                      }}
                    />
                    <h3 className="header" style={{ paddingBottom: "10px" }}>
                      Logg inn på din eksisterende bruker
                    </h3>
                    <p>
                      Alle som benytter seg av Hyre, enten det er privat eller i
                      jobbsammenheng, har kun én bruker og én innlogging med
                      BankID. Det er først i selve bestillingsvinduet under
                      &quot;Betaling&quot; at den nye bedriftskontoen er synlig
                      i appen.
                    </p>
                    <BedriftLink
                      country="NOR"
                      style={{ fontSize: "1em", fontWeight: 600 }}
                    >
                      Gå til bedriftsportalen
                      <Image
                        src={chevronRight}
                        style={{ width: 24, margin: 0 }}
                        alt=""
                      />
                    </BedriftLink>
                  </div>
                </div>
              </Container>
            </TabPane>
            <TabPane tabId={1}>
              <Container className="text-center text-md-start mb-6 mb-md-6">
                <div className="row">
                  <div className="col-12 col-md-4 d-flex flex-column">
                    <h3
                      className="header"
                      style={{ paddingTop: "30px", paddingBottom: "10px" }}
                    >
                      {" "}
                      Lanseringsmail til dine ansatte
                    </h3>
                    <p>
                      Bruk denne mailen som en mail for kommunikasjon før de
                      ansatte inviteres til bedriftskontoen.
                    </p>
                    <a
                      href="https://docs.google.com/document/d/1-Iv59aNEjxRGLELCIXOD2d-z1M0X40vKvt1TswU3LFE/edit#"
                      target="_blank"
                      style={{ fontSize: "1em", fontWeight: 600 }}
                    >
                      Se mal her
                      <Image
                        src={chevronRight}
                        style={{ width: 24, margin: 0 }}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </Container>
            </TabPane>
            <TabPane tabId={2}>
              <Container className="text-center text-md-start mb-6 mb-md-6">
                <div className="row">
                  <div className="col-12 col-md-4 d-flex flex-column">
                    <h3
                      className="header"
                      style={{ paddingTop: "30px", paddingBottom: "10px" }}
                    >
                      Kom i gang for ansatte
                    </h3>
                    <p>
                      Send denne PDF-en til alle ansatte for å steg for steg
                      forklare hvordan de benytter seg av leiebil gjennom
                      bedriftens nye bedrifskonto.
                    </p>
                    <a
                      href="/hyre_for_bedrift.pdf"
                      target="_blank"
                      style={{ fontSize: "1em", fontWeight: 600 }}
                    >
                      Se PDF her
                      <Image
                        src={chevronRight}
                        style={{ width: 24, margin: 0 }}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="col-12 col-md-4 d-flex flex-column">
                    <h3
                      className="header"
                      style={{ paddingTop: "30px", paddingBottom: "10px" }}
                    >
                      Kom i gang som administratorer
                    </h3>
                    <p>
                      Send denne PDF-en til alle som skal ha
                      administratortilgang for å ta dem gjennom bedriftsportalen
                      og alle dens smarte og tidsbesparende funksjoner.
                    </p>
                    <a
                      href="/hyre_for_bedrift_admin.pdf"
                      target="_blank"
                      style={{ fontSize: "1em", fontWeight: 600 }}
                    >
                      Se PDF her
                      <Image
                        src={chevronRight}
                        style={{ width: 24, margin: 0 }}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </Container>
            </TabPane>
          </TabContent>
        </div>
      </Container>

      <div className="muted-background">
        <Container
          className="text-center text-md-start"
          style={{
            marginTop: "70px",
            paddingTop: "50px",
            paddingBottom: "40px",
          }}
        >
          <Row>
            <Col xs="12" md="6">
              <div className="p-1">
                <Link
                  href={`/bedrift/registrer`}
                  style={{ fontSize: "1em", fontWeight: 500 }}
                >
                  Steg 1: Registrer din bedrift
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
                <hr></hr>
              </div>
              <div className="p-1">
                <Link
                  href={`/bedrift/betaling`}
                  style={{ fontSize: "1em", fontWeight: 500 }}
                >
                  Steg 2: Sett opp betaling
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
                <hr></hr>
              </div>
            </Col>
            <Col xs="12" md="6">
              <div className="p-1">
                <Link
                  href={`/bedrift/lansering`}
                  style={{ fontSize: "1em", fontWeight: 600 }}
                >
                  Steg 3: Planlegg lansering
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
                <hr></hr>
              </div>
              <div className="p-1">
                <Link
                  href={`/bedrift/medlemmer`}
                  style={{ fontSize: "1em", fontWeight: 500 }}
                >
                  Steg 4: Inviter medlemmer
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
                <hr></hr>
              </div>
              <div className="p-1">
                <Link
                  href={`/bedrift/hjelp`}
                  style={{ fontSize: "1em", fontWeight: 500 }}
                >
                  Hjelp
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
                <hr></hr>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row style={{ marginBottom: "100px", marginTop: "100px" }}>
          <Col>
            <h2 className="header">Ofte stilte spørsmål</h2>
            <Faq
              faqs={[
                {
                  category: "Ofte stilte spørsmål",
                  items: [
                    {
                      title:
                        "Hvordan setter jeg opp en bedriftskonto med ulike kostnadssenter og avdelinger?",
                      content:
                        "Dersom det er ett og samme selskap som skal bli belastet for leieforholdene kan du sette opp kostnadsnøkler som sørger for at hvert leieforhold eller faktura blir merket med riktig kostandssenter eller avdeling. Hvis fakturaen skal til ulike juridiske enheter må du sette opp to forskjellige bedriftskontoer med ulikt navn, organisasjonsnummer og adresse.",
                    },
                    {
                      title: "Trenger vi å holde opplæring av ansatte?",
                      content:
                        "Nei, mest sannsynlig er det så lett å bruke Hyre at det kun trengs en kort introduksjon om tilbudet - noe som også er godt dekket i invitasjonsmailen som blir sendt til de ansatte når du inviterer dem til i bedriftskontoen. ",
                    },
                  ],
                },
              ]}
            />

            <div className="text-center pt-5">
              <Link
                href={`/hjelp`}
                style={{ fontSize: "1em", fontWeight: 600 }}
              >
                Klikk her for flere spørsmål og svar.
                <Image
                  src={chevronRight}
                  style={{ width: 24, margin: 0 }}
                  alt=""
                />
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
