"use client";
import React from "react";
import { Col, Container, Row } from "reactstrap";

import Link from "next/link";
import Image from "next/image";

import chevronRight from "../../../../public/images/chevron-right.svg";
import faktura from "../../../../public/images/b2b/fakturaavtale-oversikt.png";
import faktura3 from "../../../../public/images/b2b/fakturaavtale-oversikt3.png";

class BrukerPage extends React.Component {
  render() {
    return (
      <div>
        {/* <Helmet title="Fakturaavtale - Hyre for Bedrift" /> */}
        <Container
          className="text-center text-md-start"
          style={{
            marginTop: "100px",
            paddingTop: "70px",
            paddingBottom: "50px",
          }}
        >
          <Link href={`/bedrift/hjelp/`}>
            Hjelp Bedrift
            <Image src={chevronRight} style={{ width: 24, margin: 0 }} alt="" />
          </Link>
          <Link href={`/bedrift/hjelp/fakturaavtale`}>Fakturaavtale</Link>
          <hr></hr>
          <Row style={{ paddingTop: "10px" }}>
            <Col xs="12" md="12">
              <h1 className="big-fat-header">Sette opp fakturavtale</h1>
              <p>
                Dersom du ønsker faktura fremfor å belaste et betalingskort kan
                du enkelt søke om dette, og du vil få svar i løpet av neste
                virkedag. For å få faktura må bedriften ha kredittscore B eller
                bedre.
              </p>
              <p>
                For å søke om fakturaavtale går du til
                &quot;Innstillinger&quot;, &quot;Betaling&quot; og &quot;Be om
                fakturaavtale&quot;. Fyll inn nødvendig informasjon og velg
                &quot;Søk om fakturaavtale&quot;.
              </p>
            </Col>
          </Row>
          <Row style={{ paddingTop: "10px" }}>
            <Col xs="12" md="12">
              <p>
                <strong>Følg stegene her:</strong>
              </p>
            </Col>
            <Col xs="12" md="12">
              <p>
                <strong>Steg 1: </strong> Gå til fanen Innstillinger. Gå til
                &quot;Betaling&quot; i menyen til venstre.
              </p>
            </Col>
            <Col xs="12" md="8">
              <Image
                src={faktura}
                alt=""
                className="align-self-center"
                style={{ width: "100%", height: "auto", marginBottom: 32 }}
              />
            </Col>
            <Col xs="12" md="12">
              <p>
                <strong>Steg 2: </strong> Velg &quot;Be om fakturaavtale.&quot;
              </p>
            </Col>
            <Col xs="12" md="4">
              <Image
                src={faktura3}
                alt=""
                className="align-self-center"
                style={{ width: "100%", height: "auto", marginBottom: 32 }}
              />
            </Col>
            <Col xs="12" md="12">
              <p>
                <strong>Steg 3: </strong> Fyll inn organisasjonsnummer,
                kontaktperson, epost og telfonnummer. Velg fakturatype. Trykk
                &quot;Be om fakturaavtale&quot;.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default BrukerPage;
