import { Block, ExtractValueFromType } from "@/types";
import buttonStyles from "@/styles/buttons.module.css";
import RichText from "../components/RichText";

export const HowCanWeImprove = ({
  background_color,
  title,
  text,
  link_text,
  link_url,
}: ExtractValueFromType<Block, "how_can_we_improve_block">) => {
  return (
    <div
      className="my-3"
      style={{
        backgroundColor: background_color,
        color: background_color === "#1c5dff" ? "white" : "black",
      }}
    >
      <div className="pb-5 pt-5 container text-center">
        <div style={{ flex: 1 }}>
          <h2 className="big-fat-header pb-2 pt-2">{title}</h2>
          <div>
            <div className="pb-3">
              <RichText text={text} />
            </div>
            <div>
              <a
                className={`btn ${buttonStyles.btnElectricBlue}`}
                target="_blank"
                href={link_url}
              >
                {link_text}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
