import styles from "./richtext.module.css";

import ReactHtmlParser, { Transform, processNodes } from "react-html-parser";
import Image from "next/image";
import MobileAppRelativeLink from "../MobileAppRelativeLink";

import { DomElement } from "htmlparser2";
import chevronRight from "../../../public/images/chevron-right.svg";

const Chevron = (
  <Image
    className="chevron"
    src={chevronRight}
    style={{ width: 24, margin: 0, marginTop: -2 }}
    alt=""
  />
);

const removeGtSignFromEnd = (node: DomElement): boolean => {
  const lastChild = node.children[node.children.length - 1];
  if (!lastChild) {
    return false;
  }

  if (lastChild.type === "text") {
    if (lastChild.data.endsWith(" >")) {
      lastChild.data = lastChild.data.slice(0, -2);
      return true;
    }
    return false;
  }

  return removeGtSignFromEnd(lastChild);
};

// Note: Need to transform links in this component when we add support for them
export default function RichText({
  text,
  inline,
}: {
  text: string;
  inline?: boolean;
}) {
  // Parse and transform the inputted HTML into react elements

  // Transforms links to Next links where applicable
  // and replaces " >" at the end with chevron
  const transform: Transform = (node, index) => {
    if (node.type === "tag" && node.name === "a") {
      const shouldAppendChevron = removeGtSignFromEnd(node);

      // Convert internal page links to next.js links
      return node.attribs["internal-href"] ? (
        <MobileAppRelativeLink href={`${node.attribs["internal-href"]}`}>
          {processNodes(node.children, transform)}
          {shouldAppendChevron && Chevron}
        </MobileAppRelativeLink>
      ) : (
        <a href={node.attribs["href"]}>
          {processNodes(node.children, transform)}
          {shouldAppendChevron && Chevron}
        </a>
      );
    }

    if (node.type === "tag" && node.name === "img") {
      const isRightAligned = node.attribs["class"]?.includes("right");
      const isLeftAligned = node.attribs["class"]?.includes("left");
      const isFullWidth = node.attribs["class"]?.includes("full-width");
      let className = "";
      if (isRightAligned) className = styles["image-right"];
      else if (isLeftAligned) className = styles["image-left"];
      else if (isFullWidth) className = styles["image-full-width"];
      return (
        <div
          className={className}
          style={{
            minWidth: 300,
            marginBottom: 25,
          }}
        >
          <Image
            src={{
              src: node.attribs["src"],
              width: node.attribs["width"],
              height: node.attribs["height"],
            }}
            alt={node.attribs["alt"]}
            style={{
              width: "100%",
              height: "auto",
            }}
          />
          <p
            style={{
              fontSize: 14,
              lineHeight: 1.6,
              marginTop: 5,
              marginBottom: 0,
            }}
          >
            {node.attribs["alt"]}
          </p>
        </div>
      );
    }

    // Remove <p> tags in inline content, because CMS wraps all content in <p>
    if (inline && node.type == "tag" && node.name === "p") {
      return <>{processNodes(node.children, transform)}</>;
    }
    return undefined;
  };
  const transformedHtml = ReactHtmlParser(text, {
    transform: transform,
  });

  if (inline) {
    return <>{transformedHtml}</>;
  }
  return <div className={styles.richTextContainer}>{transformedHtml}</div>;
}
