"use client";

import React from "react";
import { Col, Container, Row } from "reactstrap";
import Link from "next/link";
import Image from "next/image";
import BedriftLink from "@/components/B2BLink";
import Faq from "@/components/Faq";

import buttonStyles from "@/styles/buttons.module.css";

import chevronRight from "../../../public/images/chevron-right.svg";

export default function BedriftGetStarted() {
  return (
    <div>
      {/* <Helmet title="Kom i gang - Hyre for Bedrift" /> */}

      <Container
        className="text-center text-md-start"
        style={{
          marginTop: "100px",
          paddingTop: "70px",
          paddingBottom: "50px",
        }}
      >
        <Row>
          <Col xs="12" md="6">
            <h1 className="header big-fat-header" style={{ color: "black" }}>
              Kom i gang med Hyre for Bedrift
            </h1>
            <p>
              Bedriftskonto på Hyre er helt kostnadsfritt, og du betaler kun for
              når du bruker bil.
            </p>
            <p>
              For å registrere din bedrift må du fylle inn bedriftsinformasjon,
              legge inn et betalingsmiddel og invitere ansatte.
            </p>
            <p>
              <BedriftLink
                country="NOR"
                className={`btn ${buttonStyles.btnElectricBlue}`}
                style={{ marginRight: "1.5em" }}
                href="sign-up"
              >
                Kom i gang på fem minutter
              </BedriftLink>
            </p>
          </Col>
          <Col xs="12" md="6">
            <div>
              <style>{`
          .embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; height: auto; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
        `}</style>
              <div className="embed-container">
                <iframe
                  src="https://player.vimeo.com/video/624859596"
                  frameBorder="0"
                  // webkitAllowFullScreen
                  // mozAllowFullScreen
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="muted-background">
        <Container
          className="text-center text-md-start"
          style={{
            marginTop: "70px",
            paddingTop: "50px",
            paddingBottom: "40px",
          }}
        >
          <Row>
            <Col xs="12" md="6">
              <div className="p-1">
                <Link
                  href={`/bedrift/registrer`}
                  style={{ fontSize: "1em", fontWeight: 500 }}
                >
                  Steg 1: Registrer din bedrift
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
                <hr></hr>
              </div>
              <div className="p-1">
                <Link
                  href={`/bedrift/betaling`}
                  style={{ fontSize: "1em", fontWeight: 500 }}
                >
                  Steg 2: Sett opp betaling
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
                <hr></hr>
              </div>
            </Col>
            <Col xs="12" md="6">
              <div className="p-1">
                <Link
                  href={`/bedrift/lansering`}
                  style={{ fontSize: "1em", fontWeight: 500 }}
                >
                  Steg 3: Planlegg lansering
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
                <hr></hr>
              </div>
              <div className="p-1">
                <Link
                  href={`/bedrift/medlemmer`}
                  style={{ fontSize: "1em", fontWeight: 500 }}
                >
                  Steg 4: Inviter medlemmer
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
                <hr></hr>
              </div>
              <div className="p-1">
                <Link
                  href={`/bedrift/hjelp`}
                  style={{ fontSize: "1em", fontWeight: 500 }}
                >
                  Hjelp
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
                <hr></hr>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row style={{ marginBottom: "100px", marginTop: "100px" }}>
          <Col>
            <h2 className="header">Ofte stilte spørsmål</h2>
            <Faq
              faqs={[
                {
                  category: "Ofte stilte spørsmål",
                  items: [
                    {
                      title: "Koster det noe å ha bedriftskonto?",
                      content:
                        "Nei, det er ingen faste kostnader å ha en bedriftskonto på Hyre. Du blir kun belastet når man faktisk leier bil, og du kan legge til så mange ansatte du ønsker.",
                    },
                    {
                      title: "Kan jeg få faktura?",
                      content:
                        "Ja, du kan søke om å få månedlig faktura eller enkeltfaktura når du opprettet bedriftskonto, og du får svar innen en virkedag.",
                    },
                    {
                      title: "Er det andre leiepriser for bedrifter?",
                      content:
                        "Du betaler de samme lave leieprisene enten du leier privat eller via en bedriftskonto på Hyre.",
                    },
                    {
                      title:
                        "Må jeg ha en ekstra bruker i tillegg til min private?",
                      content:
                        "Nei, du har samme bruker og innlogging uansett om du leier i jobb eller privat. Eneste forskjellen er at du velger et annet betalingsmiddel når du bestiller bil. ",
                    },
                  ],
                },
              ]}
            />

            <div className="text-center pt-5">
              <Link
                href={`/hjelp`}
                style={{ fontSize: "1em", fontWeight: 600 }}
              >
                Klikk her for flere spørsmål og svar.
                <Image
                  src={chevronRight}
                  style={{ width: 24, margin: 0 }}
                  alt=""
                />
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
