"use client";
import React from "react";
import { Col, Container, Row } from "reactstrap";

import Link from "next/link";
import Image from "next/image";

import buttonStyles from "@/styles/buttons.module.css";
import aboutUsStyles from "./about_us.module.css";

import ifLogoImage from "../../../public/images/logos/if-logo.png";
import chevronRight from "../../../public/images/chevron-right.svg";
import mmgLogoImage from "../../../public/images/logos/mmg-logo.png";
import bankIdLogo from "../../../public/images/logos/bankid-logo.png";
import phoneIcon from "../../../public/icons/phone.png";
import carImage from "../../../public/images/articles/79-DSC04163.jpg";
import sameieBlue from "../../../public/icons/sameie-blue.svg";
import familiebilTesla from "../../../public/icons/familiebil-el_tesla.svg";
import demographics from "../../../public/icons/demographics.svg";

export default function AboutUs() {
  return (
    <div>
      <Container style={{ marginBottom: "20px", marginTop: "120px" }}>
        <div className="row align-items-center">
          <div className="col-12 col-md-7 d-flex flex-column">
            <h3>Et teknologiselskap i stor vekst og bevegelse</h3>
            <h2 className="big-fat-header" style={{ color: "#1c5dff" }}>
              Vi i Hyre er brennende opptatt av teknologi og deler alle en
              visjon om en fremtid der ingen trenger å eie egen bil i byene.
            </h2>
          </div>
          <div className="col-12 col-md-5 d-flex flex-column">
            <Image
              src={carImage}
              alt=""
              className="align-self-center"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </div>
        <div className="row" style={{ marginTop: "30px" }}>
          <div className="col-12">
            <h1 style={{ color: "black" }}>
              Nordens største bildelingstjeneste på 4 år
            </h1>
            <p>
              Hyre er et bildelingsselskap som ble etablert i 2017 med 8 ansatte
              og lanserte første versjon av appen i 2018 med 50 biler. I dag
              teller vi 35 teknologioptimistiske ansatte og med over 1 500 biler
              er vi blitt Nordens største bildelingstjeneste.
            </p>
            <p>
              Med appen vår kan du reservere, låse opp og betale for leiebil
              uten å måtte vente i kø for plukke opp bilen. Bom, drivstoff og
              leiepriser gjøres opp automatisk i sanntid, som gjør at du får
              full oversikt over alle kostnader uten å løfte annet enn en
              finger.
            </p>
            <p>
              Bilene våre er alltid tilgjengelig og vi jobber hver dag for at vi
              aldri skal være mer enn 5 minutter unna deg. Bilflåten er eiet og
              driftet av Hyre, noe som gir oss full kontroll over kundereisen og
              sikrer deg trygg og forutsigbar tilgang til moderne biler til lave
              priser.
            </p>
            <h3>Vi elsker teknologi</h3>
            <p>
              Vi elsker hvordan teknologi gir oss mulighet til å lage
              fantastiske kundeopplevelser og utfordre status quo. De ulike
              plattformene og tjenestene vi bruker er designet og utviklet av
              våre egne ansatte, noe som gir oss en fart og fleksibilitet som få
              andre har.
            </p>
            <p>
              Hyre er et joint venture mellom Møller Mobility Group og Hyre
              Management, med MMG som største eier.
            </p>
          </div>
        </div>
      </Container>

      <Container style={{ marginBottom: "50px", marginTop: "50px" }}>
        <div className="row text-center">
          <div className="col-12">
            <h2 className="big-fat-header mb-3 px-0">
              Vi tror på en fremtid med delte, selvkjørende elbiler fordi:
            </h2>
          </div>
          <div
            className="col-12 col-md-4 d-flex flex-column"
            style={{ marginTop: "20px" }}
          >
            <Image
              src={familiebilTesla}
              alt=""
              height={100}
              width={180}
              className="align-self-center"
              style={{ objectFit: "contain" }}
            />
            <h3>Bilen i seg selv er i endring</h3>
            <p>
              Biler er nå koblet til internett, noe som muliggjør mer effektiv
              bildeling. Videre er bilen nå elektrisk og med tiden autonom.
            </p>
          </div>
          <div
            className="col-12 col-md-4 d-flex flex-column"
            style={{ marginTop: "20px" }}
          >
            <Image
              src={demographics}
              alt=""
              height={100}
              width={180}
              className="align-self-center"
              style={{ objectFit: "contain" }}
            />
            <h3>Demografien er i endring</h3>
            <p>
              Bilen er ikke lenger et viktig statussymbol, og det er færre unge
              voksne som ønsker å eie en bil. Folk ønsker enkelhet,
              fleksibilitet og frihet.
            </p>
          </div>
          <div
            className="col-12 col-md-4 d-flex flex-column"
            style={{ marginTop: "20px" }}
          >
            <Image
              src={sameieBlue}
              alt=""
              height={100}
              width={150}
              className="align-self-center"
              style={{ objectFit: "contain" }}
            />
            <h3>Bærekraft er et krav</h3>
            <p>
              Måten vi beveger oss på i dag er ikke bærekraftig. Selv med
              elbiler vil fortetting i de største byene tvinge frem alternative
              løsninger innen persontransport.
            </p>
          </div>
        </div>
      </Container>

      <div className="muted-background">
        <Container
          className="text-md-start mb-md-3"
          style={{
            marginTop: "50px",
            paddingTop: "80px",
            paddingBottom: "60px",
          }}
        >
          <h2 className="big-fat-header mb-3 px-0">Teams </h2>
          <div className="row">
            <div
              className="col-12 col-md-4 d-flex flex-column"
              style={{ marginBottom: "20px" }}
            >
              <h3>Utvikling</h3>
              <p>
                Hyre er fortsatt et lite selskap med en relativt liten
                utviklingsavdeling – så alle må gjøre litt av alt. Våre
                utviklere bygger helt nye muligheter for både kunder, ansatte og
                leverandører, og sørger for at vi ikke mister de vi allerede har
                bygget. Det betyr å bygge og vedlikeholde frontend- og
                backendsystemer, å bidra med analyser og å orkestrere
                infrastruktur og prosesser.
              </p>
              <p>
                <Link href={`/om-oss/teknologi/`} style={{ fontWeight: 600 }}>
                  Les mer om utviklingsteamet her
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
              </p>
            </div>
            <div className="col-12 col-md-4 d-flex flex-column">
              <h3>Administrasjon og Forretningsutvikling</h3>
              <p>
                Som et teknologiselskap utvikler vi ikke kun en
                bildelingsapplikasjon, men ser på det totale bildet av
                mobilitetstjenester hvor teknologien og kunnskapen vår kan
                utgjøre en forskjell. Da må alt fra økonomi, strategi, innkjøp
                og forretningsutvikling henge sammen og ha kapasitet til å gripe
                mulighetene.
              </p>
            </div>
            <div className="col-12 col-md-4 d-flex flex-column">
              <h3>Produkt</h3>
              <p>
                Med et stort antall produkter og tjenester - nye og eksisterende
                som alle skal henge sammen og gi verdi for brukerne våre har vi
                dedikerte produkteiere som planlegger, designer og er med å
                bygge alle våre nye løsninger.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4 d-flex flex-column">
              <h3>Insikt og analyse</h3>
              <p>
                For å kunne forstå mulighetene som ligger foran oss i den enorme
                mengden data som er tilgjengelig strukturerer , analyserer og
                gjør tilgjengelig verdifull informasjon til alle deler av
                selskapet slik at vi hver dag kan gjøre raske og faktabaserte
                beslutninger og veivalg.
              </p>
            </div>
            <div className="col-12 col-md-4 d-flex flex-column">
              <h3>Drift og kundeservice</h3>
              <p>
                Med en stadig voksende bilpark og flere nye kunder hver eneste
                dag er det kritisk at vi er tilgjengelig for kundene våre. Siden
                vi lanserte i 2018 har vi vært ekstremt opptatt av å levere den
                beste kundeservicen og lage de mest effektive systemene for å
                håndtere drift i stor skala.
              </p>
            </div>
            <div className="col-12 col-md-4 d-flex flex-column">
              <h3>Salg og Marked</h3>
              <p>
                Vekst kommer i stor grad av å ha et fantastisk produkt, men det
                hjelper også å sørge for at flest mulig personer og selskaper
                oppdager tjenestene vi lager. Så her jobber vi med alt fra B2B
                salg og kommunikasjon til reklame, design og merkevarebygging.
              </p>
            </div>
            <div className="col-12 col-md-4 d-flex flex-column mt-3">
              <p>
                <Link href={`/karriere/`} style={{ fontWeight: 600 }}>
                  Se alle utlyste stillinger
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
              </p>
            </div>
          </div>
        </Container>
      </div>

      <Container
        className="text-md-start mb-md-3"
        style={{ marginTop: "80px", marginBottom: "100px" }}
      >
        <h2 className="big-fat-header mb-2 px-0">Utviklingsteamet</h2>
        <p className="mb-4 mb-md-3">
          Da vi startet Hyre hadde vi en idé om at det skulle være like enkelt,
          smidig og raskt å bruke bildeling som å eie egen bil. For å oppnå
          denne idéen skulle vi bruke teknologi. Derfor er det
          utviklingsavdelingen i Hyre som er selve forretningen, og motoren som
          gir oss konkurransefortrinn. Helt siden starten har vi møtt våre
          problemer med en teknologisk løsning.
        </p>
        <p>
          Vi har stor tillit til utviklingsavdelingen vår, og dermed forsøker vi
          å legge opp arbeidsdagen på en måte som gir muligheter til å løse
          problemer, og til å bygge løsninger.
        </p>
        <p></p>

        <Link
          className={`btn ${buttonStyles.btnElectricBlue}`}
          style={{ marginRight: "1.5em" }}
          href="/om-oss/teknologi/"
          target="_blank"
        >
          Les mer om utviklingsteamet her
        </Link>
      </Container>

      <Container
        className="text-md-start mb-md-3"
        style={{ marginTop: "80px", marginBottom: "100px" }}
      >
        <h2 className="big-fat-header mb-2 px-0">
          Slik møter Hyre åpenhetsloven
        </h2>
        <p className="mb-4 mb-md-3">
          I Hyre forstår vi at vi har en viktig rolle ut mot våre leverandører.
          Vi jobber for å sikre at tjenestene våre leveres fra etisk forsvarlig
          drift. Dette er en integrert del av vår strategi.
        </p>
        <p>
          Vi stiller krav til oss selv og jobber aktivt med leverandørevaluering
          og oppfølgning. I tråd med den nye åpenhetsloven gir vi også våre
          kunder innsyn i arbeidet vårt.
        </p>
        <p>
          Har du spørsmål rundt vårt arbeid med leverandøroppfølging? Send mail
          til{" "}
          <a href="mailto:aapenhetsloven@hyre.no" target="_blank">
            aapenhetsloven@hyre.no
          </a>
          .
        </p>
        <p />

        <Link
          className={`btn ${buttonStyles.btnElectricBlue}`}
          style={{ marginRight: "1.5em" }}
          href="/om-oss/aapenhetsloven/"
          target="_blank"
        >
          Les vår redegjørelse for aktsomhetsvurderingene
        </Link>
      </Container>

      <Container style={{ marginBottom: "20px", marginTop: "150px" }}>
        <Row>
          <Col>
            <h2
              className="thin-header"
              style={{
                fontSize: 36,
              }}
            >
              Kontakt oss:
            </h2>
          </Col>
        </Row>

        <address style={{ marginBottom: "150px" }}>
          <div
            className="d-flex justify-content-center flex-wrap"
            style={{ marginRight: -16 }}
          >
            <div className="me-3 text-center text-sm-start">
              <p>
                <strong>Hyre AS</strong>
                <br />
                Orgnr. 918 719 601 <br />
                Chr. Krohgs gate 16, 0186 Oslo
              </p>
            </div>

            <div className="me-3">
              <p className={aboutUsStyles.contactList}>
                <span
                  style={{
                    width: "26px",
                    fontSize: "38px",
                    lineHeight: "26px",
                    textAlign: "center",
                    color: "black",
                    fontWeight: 900,
                    display: "inline-block",
                    verticalAlign: "middle",
                    margin: "0 30px 0 0",
                  }}
                >
                  @
                </span>
                <a href="mailto:hei@hyre.no">hei@hyre.no</a>
                <br />
                <br />
                <Image
                  src={phoneIcon}
                  width={26}
                  height={26}
                  style={{
                    margin: "0 30px 0 0",
                  }}
                  alt=""
                />
                <a href="tel:+4722444973">22 44 49 73</a>
              </p>
            </div>
          </div>
        </address>
      </Container>

      <Container>
        <Row>
          <Col>
            <h2
              className="thin-header"
              style={{
                fontSize: 36,
              }}
            >
              Partnere
            </h2>
          </Col>
        </Row>

        <Row
          style={{
            marginBottom: "120px",
          }}
        >
          <Col xs="12" sm="4">
            <div className="partner-logo-container">
              <Image src={ifLogoImage} height={80} alt="If" />
            </div>
            <h2 className={`fat-header ${aboutUsStyles.partnerHeader}`}>
              If Skadeforsikring
            </h2>
            <p className={aboutUsStyles.partnerDescription}>
              Markedets beste ansvar- og kaskoforsikring&nbsp;inkludert.
            </p>
          </Col>

          <Col xs="12" sm="4">
            <div className="partner-logo-container">
              <Image src={bankIdLogo} height={25} alt="BankID" />
            </div>
            <h2 className={`fat-header ${aboutUsStyles.partnerHeader}`}>
              Sikker med BankID
            </h2>
            <p className={aboutUsStyles.partnerDescription}>
              Samme teknologi som i nettbanken. Trygt.&nbsp;Raskt.&nbsp;Enkelt.
            </p>
          </Col>

          <Col xs="12" sm="4">
            <div className="partner-logo-container">
              <Image
                src={mmgLogoImage}
                height={70}
                alt="Møller Mobility Group"
              />
            </div>
            <h2 className={`fat-header ${aboutUsStyles.partnerHeader}`}>
              Langsiktig investor
            </h2>
            <p className={aboutUsStyles.partnerDescription}>
              Møller Mobility Group er vår viktigste
              investor&nbsp;og&nbsp;samarbeidspartner.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
