"use client";
import Link from "next/link";
import { Col, Container, Row } from "reactstrap";
import chevronRight from "../../../public/images/chevron-right.svg";
import norconsultImage from "../../../public/images/articles/HYRE_ENDRE LJONES_12_for web.jpg";
import teliaImage from "../../../public/images/articles/20221121-EIK_1996_for web.jpg";
import Image, { StaticImageData } from "next/image";

const ArticlePreview = ({
  title,
  description,
  linkText,
  path,
  image,
}: {
  title: string;
  description: string;
  linkText: string;
  path: string;
  image: string | StaticImageData;
}) => (
  <Row
    className="justify-content-between"
    style={{
      marginBottom: "40px",
      marginLeft: "0px",
      marginRight: "0px",
    }}
  >
    <Col xs="12" md="6" className="mb-2">
      <Link href={`/artikler/${path}/`}>
        <Image
          src={image}
          style={{
            height: "auto",
            boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.5)",
          }}
          alt=""
        />
      </Link>
    </Col>
    <Col xs="12" md="6">
      <h2 className="fat-header">{title}</h2>
      <p>{description}</p>
      <Link href={`/artikler/${path}/`}>
        <p style={{ fontWeight: "600", color: "#1c5dff" }}>
          {linkText}
          <Image src={chevronRight} style={{ width: 24, margin: 0 }} alt="" />
        </p>
      </Link>
    </Col>
  </Row>
);

export default function RecommendedArticles() {
  return (
    <Container className="pt-4">
      <Container className="text-center text-md-start pb-4">
        <h2 className="big-fat-header text-center mb-2 px-0">
          Anbefalt lesing
        </h2>
      </Container>
      <ArticlePreview
        title="Dropper egne firmabiler. -&nbsp;Kutter bilkostnadene nesten i to med bildeling"
        description="Vanlige folk har for lengst oppdaget at bildeling er bra for lommeboka og miljøet. Nå kommer bedriftene etter. -&nbsp;Vi kutter bilkostnadene med rundt 40 prosent, forteller Bergens-selskap om bildelingstjenesten Hyre."
        path="norconsult"
        image={norconsultImage}
        linkText="Les om samarbeidet her"
      />
      <ArticlePreview
        title="Reduserer bilpoolen med 80 prosent: -&nbsp;Viktig for å nå klimamålene våre"
        description="Bærekraft er en av de viktigste driverne når norske selskaper bytter ut eide og leasede biler til fordel for bildeling. -&nbsp;Målet er null utslipp og null avfall innen 2030, forteller bilansvarlig i Telia."
        path="telia-reduserer-bilpoolen"
        image={teliaImage}
        linkText="Les hele saken her"
      />
      <div style={{ position: "relative" }} className="text-center mb-5 mt-4">
        <Link
          style={{ fontSize: "1em", fontWeight: 600 }}
          href="/artikler/"
          className="hide-on-mobile"
        >
          Les alle artikler her
          <Image src={chevronRight} style={{ width: 24, margin: 0 }} alt="" />
        </Link>
      </div>
    </Container>
  );
}
