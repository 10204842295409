"use client";
import { Container } from "reactstrap";

export default function Help() {
  return (
    <div>
      <div className="index-header">
        <Container>
          <h1 className="hero-header text-center">Vanlige spørsmål og svar</h1>
        </Container>
      </div>
    </div>
  );
}
