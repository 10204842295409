"use client";
import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";

import Link from "next/link";
import Image from "next/image";
import BedriftLink from "@/components/B2BLink";
import Faq from "@/components/Faq";
import { TabContent, TabPane } from "reactstrap";

import "./bedrift.css";

import chevronRight from "../../../public/images/chevron-right.svg";
import csv from "../../../public/images/b2b/csv.png";
import regneark from "../../../public/images/b2b/regneark.png";
import importer from "../../../public/images/b2b/importercsv.png";
import inviter from "../../../public/images/b2b/invitermedlemmer2.png";
import send from "../../../public/images/b2b/sendinvitasjoner2.png";
import administrer from "../../../public/images/b2b/administrermedlemmer.png";

export default function BedriftGetStartedMembers() {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <div>
      {/* <Helmet title="Inviter medlemmer - Hyre for Bedrift" /> */}

      <Container
        className="text-center text-md-start"
        style={{
          marginTop: "70px",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
      >
        <Row>
          <Col xs="12" md="12">
            <h1 className="header big-fat-header" style={{ color: "black" }}>
              Steg 4: Inviter medlemmer
            </h1>
            <p>
              Når du har annonsert lanseringen er det tid for å knytte de
              ansatte til bedriftskontoen.
            </p>
            <p>
              Du kan enten invitere medlemmer én og én, eller så kan du lime inn
              en liste med e-postadresser. Du kan også laste opp en liste med
              e-postadresser som .csv.
            </p>
          </Col>
        </Row>
      </Container>

      <Container>
        <div className="bedrift">
          <div className="navigation-header">
            <a
              className={activeTab === 0 ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                setActiveTab(0);
              }}
            >
              Importer regneark
            </a>
            <a
              className={activeTab === 1 ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                setActiveTab(1);
              }}
            >
              Legg til medlemmer manuelt
            </a>
          </div>

          <TabContent activeTab={activeTab}>
            <TabPane tabId={0}>
              <Container className="text-center text-md-start mb-6 mb-md-6">
                <div className="row">
                  <div className="col-12 col-md-4 d-flex flex-column">
                    <Image
                      src={regneark}
                      alt=""
                      height={150}
                      width={360}
                      className="align-self-center"
                      style={{ objectFit: "contain", paddingTop: "30px" }}
                    />
                    <h3 className="header" style={{ paddingBottom: "10px" }}>
                      Lag et regneark
                    </h3>
                    <p>
                      Full ut et regneark med kun e-postadresser i en kolonne,
                      uten overskrift.
                    </p>
                  </div>
                  <div className="col-12 col-md-4 d-flex flex-column">
                    <Image
                      src={csv}
                      alt=""
                      height={150}
                      width={360}
                      className="align-self-center"
                      style={{ objectFit: "contain", paddingTop: "30px" }}
                    />
                    <h3 className="header" style={{ paddingBottom: "10px" }}>
                      Eksporter
                    </h3>
                    <p>Eksporter regnearket som CSV-fil.</p>
                  </div>
                  <div className="col-12 col-md-4 d-flex flex-column">
                    <Image
                      src={importer}
                      alt=""
                      height={150}
                      width={360}
                      className="align-self-center"
                      style={{ objectFit: "contain", paddingTop: "30px" }}
                    />
                    <h3 className="header" style={{ paddingBottom: "10px" }}>
                      Last opp
                    </h3>
                    <p>
                      Gå til &quot;Medlemmer&quot; i bedriftsportalen, velg
                      &quot;Inviter&quot; og velg &quot;Importer .CSV&quot; helt
                      nederst i vinduet.
                    </p>
                    <BedriftLink
                      country="NOR"
                      style={{ fontSize: "1em", fontWeight: 600 }}
                    >
                      Gå til bedriftsportalen
                      <Image
                        src={chevronRight}
                        style={{ width: 24, margin: 0 }}
                        alt=""
                      />
                    </BedriftLink>
                  </div>
                </div>
              </Container>
            </TabPane>
            <TabPane tabId={1}>
              <Container className="text-center text-md-start mb-6 mb-md-6">
                <div className="row">
                  <div className="col-12 col-md-4 d-flex flex-column">
                    <Image
                      src={inviter}
                      alt=""
                      height={200}
                      width={480}
                      className="align-self-center"
                      style={{
                        objectFit: "contain",
                        paddingTop: "30px",
                        width: "100%",
                      }}
                    />
                    <h3 className="header" style={{ paddingBottom: "10px" }}>
                      Legg inn detaljer
                    </h3>
                    <p>
                      Gå til &quot;Medlemmer&quot; i bedriftsportalen og velg
                      &quot;Inviter&quot;. Lim inn én eller flere e-postadresser
                      og velg tilgangsnivå.
                    </p>
                  </div>
                  <div className="col-12 col-md-4 d-flex flex-column">
                    <Image
                      src={send}
                      alt=""
                      height={200}
                      width={480}
                      className="align-self-center"
                      style={{
                        objectFit: "contain",
                        paddingTop: "30px",
                        width: "100%",
                      }}
                    />
                    <h3 className="header" style={{ paddingBottom: "10px" }}>
                      Send invitasjon på mail
                    </h3>
                    <p>
                      Når du har fylt inn e-postadresser og valgt tilgangsnivå
                      trykker du &quot;Send Invitasjon&quot;. Automatisk mail
                      med invitasjonslenke sendes nå ut til alle de inviterte
                      med informasjon om hvordan de kommer i gang.
                    </p>
                  </div>
                  <div className="col-12 col-md-4 d-flex flex-column">
                    <Image
                      src={administrer}
                      alt=""
                      height={200}
                      width={480}
                      className="align-self-center"
                      style={{
                        objectFit: "contain",
                        paddingTop: "30px",
                        width: "100%",
                      }}
                    />
                    <h3 className="header" style={{ paddingBottom: "10px" }}>
                      Administrer medlemmer
                    </h3>
                    <p>
                      Du kan se hvem som har godtatt invitasjonen eller ikke,
                      samt gjøre endringer i tilgangsnivåer til enhver tid i
                      listen over medlemmer.
                    </p>
                    <BedriftLink
                      country="NOR"
                      style={{ fontSize: "1em", fontWeight: 600 }}
                    >
                      Gå til bedriftsportalen
                      <Image
                        src={chevronRight}
                        style={{ width: 24, margin: 0 }}
                        alt=""
                      />
                    </BedriftLink>
                  </div>
                </div>
              </Container>
            </TabPane>
          </TabContent>
        </div>

        <div className="muted-background">
          <Container
            className="text-center text-md-start"
            style={{
              marginTop: "70px",
              paddingTop: "50px",
              paddingBottom: "40px",
            }}
          >
            <Row>
              <Col xs="12" md="6">
                <div className="p-1">
                  <Link
                    href={`/bedrift/registrer`}
                    style={{ fontSize: "1em", fontWeight: 500 }}
                  >
                    Steg 1: Registrer din bedrift
                    <Image
                      src={chevronRight}
                      style={{ width: 24, margin: 0 }}
                      alt=""
                    />
                  </Link>
                  <hr></hr>
                </div>
                <div className="p-1">
                  <Link
                    href={`/bedrift/betaling`}
                    style={{ fontSize: "1em", fontWeight: 500 }}
                  >
                    Steg 2: Sett opp betaling
                    <Image
                      src={chevronRight}
                      style={{ width: 24, margin: 0 }}
                      alt=""
                    />
                  </Link>
                  <hr></hr>
                </div>
              </Col>
              <Col xs="12" md="6">
                <div className="p-1">
                  <Link
                    href={`/bedrift/lansering`}
                    style={{ fontSize: "1em", fontWeight: 500 }}
                  >
                    Steg 3: Planlegg lansering
                    <Image
                      src={chevronRight}
                      style={{ width: 24, margin: 0 }}
                      alt=""
                    />
                  </Link>
                  <hr></hr>
                </div>
                <div className="p-1">
                  <Link
                    href={`/bedrift/medlemmer`}
                    style={{ fontSize: "1em", fontWeight: 600 }}
                  >
                    Steg 4: Inviter medlemmer
                    <Image
                      src={chevronRight}
                      style={{ width: 24, margin: 0 }}
                      alt=""
                    />
                  </Link>
                  <hr></hr>
                </div>
                <div className="p-1">
                  <Link
                    href={`/bedrift/hjelp`}
                    style={{ fontSize: "1em", fontWeight: 500 }}
                  >
                    Hjelp
                    <Image
                      src={chevronRight}
                      style={{ width: 24, margin: 0 }}
                      alt=""
                    />
                  </Link>
                  <hr></hr>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>

      <Container>
        <Row style={{ marginBottom: "100px", marginTop: "100px" }}>
          <Col>
            <h2 className="header">Ofte stilte spørsmål</h2>
            <Faq
              faqs={[
                {
                  category: "Ofte stilte spørsmål",
                  items: [
                    {
                      title: "Får man satt opp og endret ulike tilgangsivåer?",
                      content:
                        "Ja. Enten kan du være Administrator og ha tilgang til å administrere betaling, medlemmer og få oversikt over alle leieforhold knyttet til bedriftskontoen, eller så kan du være privilegert medlem som kun får mulighet til å leie bil på bedriftskontoen. Da får du også kun sette dine egne leieforhold på https://bedrift.hyre.no/ ",
                    },
                    {
                      title:
                        "Kan ansatte knytte seg til bedriftskontoen på egenhånd?",
                      content:
                        "Ja, du kan sette opp kontoen slik at alle med gyldig e-postdomene kan invitere seg selv til Bedriftskontoen via en egen link som du kan dele på intranettet eller i andre relevante kanaler.",
                    },
                  ],
                },
              ]}
            />

            <div className="text-center pt-5">
              <Link
                href={`/hjelp`}
                style={{ fontSize: "1em", fontWeight: 600 }}
              >
                Klikk her for flere spørsmål og svar.
                <Image
                  src={chevronRight}
                  style={{ width: 24, margin: 0 }}
                  alt=""
                />
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
