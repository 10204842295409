"use client";

import React from "react";
import { Col, Container, Row } from "reactstrap";
import Link from "next/link";
import Image from "next/image";
import Faq from "@/components/Faq";

import chevronRight from "../../../public/images/chevron-right.svg";
import loginn from "../../../public/images/b2b/screenshot-loginn2.png";
import registrer from "../../../public/images/b2b/screenshot-registrer2.png";
import BedriftLink from "@/components/B2BLink";

export default function BedriftGetStartedRegister() {
  return (
    <div>
      {/* <Helmet title="Registrer din bedrift - Hyre for Bedrift" /> */}

      <Container
        className="text-center text-md-start"
        style={{ marginTop: "100px", paddingTop: "40px" }}
      >
        <Row>
          <Col xs="12" md="12">
            <h1 className="header big-fat-header" style={{ color: "black" }}>
              Steg 1: Registrer din bedrift
            </h1>
            <p>
              Å sette opp en bedrifitskonto tar kun noen få minutter og det er
              helt gratis.
            </p>
          </Col>
        </Row>
      </Container>

      <Container
        className="text-center text-md-start mb-2 mb-md-3"
        style={{ marginTop: "50px" }}
      >
        <Row style={{ marginBottom: "15px" }}>
          <Col xs="12" md="6">
            <div>
              <Image
                src={loginn}
                alt=""
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </Col>
          <Col xs="12" md="6">
            <h2 className="header fat-header">Logg inn</h2>
            <p>
              For å opprette bedriftskonto på Hyre må den være knyttet mot en
              registrert bruker på Hyre. Derfor må du enten lage deg en
              Hyre-bruker, eller logge inn med eksisterende bruker når du
              oppretter en bedriftskonto.
            </p>
          </Col>
        </Row>
      </Container>

      <Container
        className="text-center text-md-start mb-2 mb-md-3"
        style={{ marginTop: "50px" }}
      >
        <Row style={{ marginBottom: "15px" }}>
          <Col xs="12" md="6">
            <div>
              <Image
                style={{ width: "100%", height: "auto" }}
                src={registrer}
                alt=""
              />
            </div>
          </Col>
          <Col xs="12" md="6">
            <h2 className="header fat-header">Registrer din bedrift</h2>
            <p>
              Fyll deretter ut nødvendig informasjon om din bedrift som navn,
              organisasjonsnummer og bedriftens kontaktinfo.
            </p>
            <BedriftLink
              country="NOR"
              style={{ fontSize: "1em", fontWeight: 600 }}
            >
              Opprett gratis bedriftskonto i dag
              <Image
                src={chevronRight}
                style={{ width: 24, margin: 0 }}
                alt=""
              />
            </BedriftLink>
          </Col>
        </Row>
      </Container>

      <div className="muted-background">
        <Container
          className="text-center text-md-start"
          style={{
            marginTop: "70px",
            paddingTop: "50px",
            paddingBottom: "40px",
          }}
        >
          <Row>
            <Col xs="12" md="6">
              <div className="p-1">
                <Link
                  href={`/bedrift/registrer`}
                  style={{ fontSize: "1em", fontWeight: 600 }}
                >
                  Steg 1: Registrer din bedrift
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
                <hr></hr>
              </div>
              <div className="p-1">
                <Link
                  href={`/bedrift/betaling`}
                  style={{ fontSize: "1em", fontWeight: 500 }}
                >
                  Steg 2: Sett opp betaling
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
                <hr></hr>
              </div>
            </Col>
            <Col xs="12" md="6">
              <div className="p-1">
                <Link
                  href={`/bedrift/lansering`}
                  style={{ fontSize: "1em", fontWeight: 500 }}
                >
                  Steg 3: Planlegg lansering
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
                <hr></hr>
              </div>
              <div className="p-1">
                <Link
                  href={`/bedrift/medlemmer`}
                  style={{ fontSize: "1em", fontWeight: 500 }}
                >
                  Steg 4: Inviter medlemmer
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
                <hr></hr>
              </div>
              <div className="p-1">
                <Link
                  href={`/bedrift/hjelp`}
                  style={{ fontSize: "1em", fontWeight: 500 }}
                >
                  Hjelp
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
                <hr></hr>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row style={{ marginBottom: "100px", marginTop: "100px" }}>
          <Col>
            <h2 className="header">Ofte stilte spørsmål</h2>
            <Faq
              faqs={[
                {
                  category: "Ofte stilte spørsmål",
                  items: [
                    {
                      title: "Koster det noe å ha bedriftskonto på Hyre?",
                      content:
                        "Nei, det koster ingen ting å ha en bedriftskonto på Hyre og det er ingen bindene avtale. Du blir kun belastet når du faktisk leier bil.",
                    },
                    {
                      title:
                        "Kan jeg som ansatt opprette en bedriftskonto på Hyre?",
                      content:
                        "Ja, så absolutt! Hvem som helst kan opprette en bedriftskonto på Hyre, og så lenge du har et betalingskort det er OK at flere belaster kan du komme i gang på få minutter. Det er ingen binding eller faste kostnader.",
                    },
                    {
                      title:
                        "Oppretter man en ny bruker når jeg lager en bedriftskonto?",
                      content:
                        "Nei, du har samme bruker og innlogging uansett om du leier i jobb eller privat. Eneste forskjellen er at du velger et annet betalingsmiddel når du bestiller bil.",
                    },
                    {
                      title:
                        "Hvorfor må jeg opprette konto med min private BankID?",
                      content:
                        "For å opprette bedriftskonto på Hyre må den være knyttet mot en registrert bruker på Hyre. Derfor må du enten lage deg en Hyre-bruker, eller logge inn med eksisterende bruker når du oppretter en bedriftskonto. Det blir den som bestiller bil som blir ansvarlig for hvert enkelt leieforhold, selv om betalingen går til ett og samme betalingskort. Ved en eventuell fakturaavtale er det Bedriften som står ansvarlig for alle kostnader. Leietaker vil alltid være ansvarlig etter gjeldende vilkår. https://hyre.no/vilkår/medlemsbetingelser/",
                    },
                  ],
                },
              ]}
            />

            <div className="text-center pt-5">
              <Link
                href={`/hjelp`}
                style={{ fontSize: "1em", fontWeight: 600 }}
              >
                Klikk her for flere spørsmål og svar.
                <Image
                  src={chevronRight}
                  style={{ width: 24, margin: 0 }}
                  alt=""
                />
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
