"use client";
import { AmountPerBlock } from "@/types";
import { ReactElement, useContext } from "react";
import { SiteContext } from "@/context";
import RichText from "./RichText";

interface PricesProps {
  prices: AmountPerBlock[];
  currency: string;
  kmPrice: AmountPerBlock;
  includedKm?: number;
  disclaimer?: string;
  wrappedClassname?: string;
  pricePointClassname?: string;
  showCurrencyAfterNumber?: boolean;
}

export const Prices = ({
  prices,
  currency,
  wrappedClassname,
  pricePointClassname,
  kmPrice,
  includedKm,
  disclaimer,
  showCurrencyAfterNumber,
}: PricesProps) => {
  const { locale } = useContext(SiteContext);
  const getAlwaysIncludedKmTranslation = (includedKm: number): ReactElement => {
    switch (locale) {
      case "no":
        return (
          <>
            Alltid inkludert{" "}
            <span className="price-amount">{includedKm} km</span> per døgn.{" "}
          </>
        );
      case "se":
        return (
          <>
            Alltid inkluderat{" "}
            <span className="price-amount">{includedKm} km</span> per dygn.{" "}
          </>
        );
      case "dk":
        return (
          <>
            Altid inklusiv{" "}
            <span className="price-amount">{includedKm} km.</span> per døgn.{" "}
          </>
        );
      default:
        return (
          <>
            Always includes{" "}
            <span className="price-amount">{includedKm} km</span> per day.{" "}
          </>
        );
    }
  };

  return (
    <>
      <div className={wrappedClassname}>
        {prices
          .filter(({ amount }) => amount !== "")
          .map(({ amount, per }) => (
            <div
              key={per}
              className={pricePointClassname}
              style={{ color: "var(--colors-electric-blue)" }}
            >
              {!showCurrencyAfterNumber && (
                <span className="small">{currency}&nbsp;</span>
              )}
              <span className="price-amount">{amount}</span>
              {showCurrencyAfterNumber && (
                <span className="small">{currency}</span>
              )}
              <span className="small">/{per}</span>
            </div>
          ))}
      </div>
      <div className="included-km header small mt-2">
        {includedKm && getAlwaysIncludedKmTranslation(includedKm)}
        {kmPrice.amount !== "" && kmPrice.per !== "" && (
          <>
            {!showCurrencyAfterNumber && `${currency} `}
            <span className={includedKm ? "" : "price-amount"}>
              {kmPrice.amount}
            </span>
            {showCurrencyAfterNumber && currency}/{kmPrice.per}
          </>
        )}
        {disclaimer && (
          <>
            {(kmPrice.amount !== "" || kmPrice.per !== "") && ". "}
            <RichText text={disclaimer} inline={true} />
          </>
        )}
      </div>
    </>
  );
};
