"use client";
import React, { useState } from "react";
import { Container } from "reactstrap";

import buttonStyles from "@/styles/buttons.module.css";
import aboutUsStyles from "./about_us.module.css";
import { useSearchParams } from "next/navigation";

export default function DeleteAccount() {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [canceled, setCanceled] = useState(false);

  const searchParams = useSearchParams();
  const id = searchParams.get("id");

  const confirm = () => {
    setConfirmLoading(true);
    fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/deletion_requests/${id}/confirm/`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          setConfirmed(true);
        }
      })
      .finally(() => {
        setConfirmLoading(false);
      });
  };

  const cancel = () => {
    if (cancelLoading || confirmLoading) {
      return;
    }
    setCancelLoading(true);
    fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/deletion_requests/${id}/cancel/`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          setCanceled(true);
        }
      })
      .finally(() => {
        setCancelLoading(false);
      });
  };

  return (
    <div className={aboutUsStyles.deleteAccountPage}>
      <Container
        style={{
          marginBottom: "250px",
        }}
      >
        <div
          style={{
            paddingTop: "50px",
          }}
        >
          <h2>Sletting av brukerkonto på Hyre</h2>
          <p>
            Du har nylig bestilt sletting av din brukerkonto på Hyre. Slettingen
            vil bli utført i henhold til gjeldende lover for personvern, gitt at
            følgende krav er oppfylt:
          </p>
          <ul>
            <li>Du har ingen kommende leieforhold hos oss.</li>
            <li>
              Du har ingen biler registrert på vår plattform med HyreConnect™
              installert.
            </li>
            <li>
              Du fortsetter ikke å bruke appen etter slettingsforespørselen er
              bekreftet.
            </li>
          </ul>
          <p>
            For at brukerkontoen din skal bli slettet må du bekrefte at du
            ønsker å gjennomføre slettingen.
          </p>

          {confirmed && <h3>Sletting av brukerkonto er bekreftet.</h3>}

          {canceled && <h3>Sletting av brukerkonto er avbrutt.</h3>}

          {!confirmed && !canceled && (
            <div>
              <p>
                <button
                  className={`btn ${buttonStyles.btnElectricBlue}`}
                  disabled={confirmLoading || cancelLoading}
                  onClick={confirm}
                >
                  {confirmLoading
                    ? "..."
                    : "Jeg bekrefter at jeg ønsker å slette min brukerkonto"}
                </button>
              </p>

              <p style={{ fontSize: "0.8em" }}>
                Dersom du likevel ikke ønsker å slette brukerkontoen din, kan
                du&nbsp;
                <a
                  style={{
                    background: "transparent",
                    borderColor: "transparent",
                    color: confirmLoading || cancelLoading ? "gray" : "#1c5dff",
                    padding: 0,
                    cursor: "pointer",
                    fontSize: "1em",
                  }}
                  onClick={cancel}
                >
                  avbryte slettingen av din brukerkonto.
                </a>
              </p>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
}
