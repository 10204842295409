"use client";
import React from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";

const MobileAppRelativeLink = (props: React.ComponentProps<typeof Link>) => {
  const path = usePathname();
  const { children, href, ...rest } = props;
  const relativeHref = path.match(/^\/mobile-app/)
    ? "/mobile-app" + href
    : href;

  return (
    <Link {...rest} href={relativeHref}>
      {children}
    </Link>
  );
};

export default MobileAppRelativeLink;
