"use client";

import React, { useState, useEffect, useRef, useContext } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import {
  CommunityVehicleStation_Website_Retrieve_,
  CommunityVehicleStation_Website_List_,
} from "../../django-types";
// @ts-ignore This does resolve. I promise.
import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer";
import mapColors from "../map/mapColors.json";
import buttonStyles from "@/styles/buttons.module.css";
import { getDeeplinkUrlForSite } from "@/deeplinks";
import { SiteContext } from "@/context";
import { formatCurrency } from "@/utils";

const retrieveCommunityVehicleStation = async (
  id: string
): Promise<CommunityVehicleStation_Website_Retrieve_> => {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/website/community_vehicle_stations/${id}/`,
    {
      mode: "cors",
      method: "GET",
      headers: {
        Accept: "application/json",
        "Hyre-Native-Version": "6.0.0",
      },
    }
  );
  return res.json();
};

interface OverViewMapProps {
  north: number;
  east: number;
  south: number;
  west: number;
  filter_querystring?: string;
  find_in_app_text: string;
  day: string;
  hour: string;
}

const OverviewMap = (props: OverViewMapProps) => {
  const [expandedVehicle, setExpandedVehicle] =
    useState<CommunityVehicleStation_Website_Retrieve_>();

  const googleMap = useRef(null);
  useEffect(() => {
    const bounds = {
      north: props.north,
      east: props.east,
      south: props.south,
      west: props.west,
    };

    if (googleMap.current) {
      const defaultBounds = {
        north: 63.45,
        east: 11.4,
        south: 58.85,
        west: 4.8,
      };
      // @ts-ignore Typing issue
      googleMap.current.fitBounds(bounds || defaultBounds, 0);
    }
  }, [props.north, props.east, props.south, props.west]);

  const [communityVehicleStations, setCommunityVehicleStations] =
    useState<CommunityVehicleStation_Website_List_[]>();

  const cvsUrl = `${process.env.NEXT_PUBLIC_API_URL}/website/community_vehicle_stations/?${props.filter_querystring}`;
  useEffect(() => {
    fetch(cvsUrl, {
      mode: "cors",
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCommunityVehicleStations(data.results);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [cvsUrl]);

  return (
    // @ts-ignore Typings doesn't allow for children, but children are allowed
    <GoogleMap
      ref={googleMap}
      defaultOptions={{
        styles: mapColors,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
      }}
    >
      <MarkerClusterer
        averageCenter
        enableRetinaIcons
        gridSize={60}
        defaultMinimumClusterSize={3}
        defaultStyles={[
          {
            url: "/images/map-cluster.png",
            height: 48,
            width: 48,
            anchor: [24, 24],
            textColor: "#ffffff",
            textSize: 14,
          },
        ]}
      >
        {communityVehicleStations?.map((community_vehicle_station) => (
          // @ts-ignore Typings doesn't allow for children, but children are allowed
          <Marker
            key={community_vehicle_station.id}
            defaultIcon={"/images/pin.png"}
            onClick={async () => {
              const vehicle = await retrieveCommunityVehicleStation(
                community_vehicle_station.id
              );
              setExpandedVehicle(vehicle);
            }}
            position={{
              lat: community_vehicle_station.point[1],
              lng: community_vehicle_station.point[0],
            }}
          >
            {expandedVehicle?.id === community_vehicle_station.id && (
              // @ts-ignore Typings doesn't allow for children, but children are allowed
              <InfoWindow onCloseClick={() => setExpandedVehicle(undefined)}>
                <VehicleCard
                  dayLabel={props.day}
                  hourLabel={props.hour}
                  findInAppLabel={props.find_in_app_text}
                  expandedVehicle={expandedVehicle}
                />
              </InfoWindow>
            )}
          </Marker>
        ))}
      </MarkerClusterer>
    </GoogleMap>
  );
};

const VehicleCard = ({
  dayLabel,
  hourLabel,
  findInAppLabel,
  expandedVehicle,
}: {
  dayLabel: string;
  hourLabel: string;
  findInAppLabel: string;
  expandedVehicle: CommunityVehicleStation_Website_Retrieve_;
}) => {
  const { site } = useContext(SiteContext);

  return (
    <div>
      <div
        style={{
          background: `url(${expandedVehicle.vehicle_station.image.url}) center / cover no-repeat`,
          position: "relative",
          height: 0,
          padding: "0 0 56.25%",
          marginBottom: "4px",
          alignSelf: "stretch",
        }}
      />
      <div style={{ padding: "12px", fontSize: 18 }}>
        <div style={{ fontWeight: "bold" }}>
          {expandedVehicle.vehicle_station.display_name}
        </div>
        <div
          style={{
            color: "#1c5dff",
            display: "flex",
            justifyContent: "space-evenly",
            gap: 16,
          }}
        >
          <div>
            {formatCurrency(expandedVehicle.price_per_day.currency)}
            &nbsp;
            <span style={{ fontWeight: "bold" }}>
              {new Intl.NumberFormat().format(
                Number(expandedVehicle.price_per_day.amount)
              )}
            </span>
            /{dayLabel}
          </div>
          <div>
            {formatCurrency(expandedVehicle.price_per_hour.currency)}
            &nbsp;
            <span style={{ fontWeight: "bold" }}>
              {new Intl.NumberFormat().format(
                Number(expandedVehicle.price_per_hour.amount)
              )}
            </span>
            /{hourLabel}
          </div>
        </div>
        <a
          style={{ marginTop: 16 }}
          href={getDeeplinkUrlForSite(site, {
            communityvehiclestation: expandedVehicle.id,
            u: "websiteoverviewmap",
          })}
          target="_blank"
          rel="noreferrer"
          className={`btn ${buttonStyles.btnSm} ${buttonStyles.btnElectricBlue}`}
        >
          {findInAppLabel}
        </a>
      </div>
    </div>
  );
};

export default withScriptjs(withGoogleMap(OverviewMap));
