"use client";
import { QRCodeSVG } from "qrcode.react";
import { useTracking } from "@/tracking";
import { getDeeplinkUrlForSite } from "@/deeplinks";
import { useContext } from "react";
import { SiteContext } from "@/context";
import { useSearchParams } from "next/navigation";

const QRLink = () => {
  const { trackingId } = useTracking();
  const { site } = useContext(SiteContext);

  const searchParams = useSearchParams();

  // Forward deeplink parameters like ?communityvehiclestation=...
  const extraParams: { [key: string]: string } = {};
  for (const [key, value] of searchParams.entries()) {
    if (key[0] !== "_") {
      extraParams[key] = value;
    }
  }

  return (
    <div style={{ border: "5px solid white" }}>
      <QRCodeSVG
        size={90}
        value={getDeeplinkUrlForSite(site, {
          l: trackingId,
          u: "websiteqr",
          ...extraParams,
        })}
        bgColor="#ffffff"
        fgColor="#1c5dff"
      />
    </div>
  );
};

export default QRLink;
