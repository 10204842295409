"use client";
import { SiteContext } from "@/context";
import { Locale, SITE } from "@/types";

export default function ClientWrapper({
  site,
  locale,
  children,
}: {
  site: SITE;
  locale: Locale;
  children: React.ReactNode;
}) {
  return (
    <SiteContext.Provider value={{ site, locale }}>
      {children}
    </SiteContext.Provider>
  );
}
