"use client";
import React from "react";
import { Col, Container, Row } from "reactstrap";

import Link from "next/link";
import Image from "next/image";

import chevronRight from "../../../../public/images/chevron-right.svg";
import leieforhold from "../../../../public/images/b2b/leieforhold-oversikt-2.png";

class BrukerPage extends React.Component {
  render() {
    return (
      <div>
        <Container
          className="text-center text-md-start"
          style={{
            marginTop: "100px",
            paddingTop: "70px",
            paddingBottom: "50px",
          }}
        >
          {/* <Helmet title="Leieforhold - Hyre for Bedrift" /> */}
          <Link href={`/bedrift/hjelp/`}>
            Hjelp Bedrift
            <Image src={chevronRight} style={{ width: 24, margin: 0 }} alt="" />
          </Link>
          <Link href={`/bedrift/hjelp/leieforhold`}>Leieforhold</Link>
          <hr></hr>
          <Row style={{ paddingTop: "10px" }}>
            <Col xs="12" md="12">
              <h1 className="big-fat-header">
                Se aktive og tidligere leieforhold
              </h1>
              <p>
                Under leieforhold kan du se alle detaljer og informasjon over
                kommende, eksisterende og tidligere leieforhold. Her kan du se
                sjåfører, kostnadsnøkler, dato, leiekostnad, skadekontrollbilder
                og annen informasjon om alle leieforhold.
              </p>
            </Col>
            <Col xs="12" md="8">
              <Image
                src={leieforhold}
                alt=""
                className="align-self-center"
                style={{ width: "100%", height: "auto", marginBottom: 32 }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default BrukerPage;
