// @ts-nocheck
/* eslint-disable */
// ^ Disable lints and checks because this file is just copied from the "website" repo
"use client";
import React, { createRef, forwardRef, useId } from "react";

import {
  Col,
  Container,
  InputGroup,
  InputGroupText,
  Input,
  Row,
  Label,
  FormGroup,
  UncontrolledTooltip,
  Collapse,
} from "reactstrap";
import {
  adminDefaults,
  adminInput,
  adminTitles,
  carTypeDefaults,
  carTypeCategories,
  costInput,
  costTitles,
  costVATRate,
  defaultCostValues,
  defaultVatOnLeasingDescription,
  damageCostPerKm,
  mobilityPlanDefaults,
  mobilityPlanOptions,
  mobilityPlanPrices,
  sourceOfDefaultCost,
  vatOnElectricLeasingDescription,
  vatOnElectricLeasingAbove500kNOKDescription,
  vatOnVanLeasingDescription,
  KgCO2ePerCategory,
  mobilityPlanToCarCategory,
  costTooltips,
  adminTooltips,
  topText,
  stepDescription,
} from "./mobilityPlanConfig";

const headerStyle = {
  paddingBottom: 15,
  fontSize: 28,
  fontWeight: 600,
  textAlign: "center",
};

const topTextStyle = {
  fontSize: 16,
  textAlign: "center",
};

const setVATOnCosts = (costs, carType, compareWithVan) => {
  //In Norway, VAT should not be included for long term (>=30 days) rentals
  //of electric vehicles with a sales price <= 500 kNOK. For long term
  //rentals of electric vehicles with a "new sales price" > 500 kNOK, the
  //amount above 500 kNOK gets VAT.
  //Furthermore, Norwegian organizations can get the VAT refunded for VAN
  //rentals, and we would therefore like to present VAN numbers excl. VAT.
  const vehicleVatRatio = !(carType.category === "electric" || compareWithVan)
    ? 1.0
    : carType.category === "electric" && parseInt(carType.worth) > 500
    ? (parseInt(carType.worth) - 500) / parseInt(carType.worth)
    : 0.0;
  const vatAdjustedCosts = {};
  for (const costKey in costs[carType.category][carType.worth][
    carType.distance
  ]) {
    //The costs from OFV are included VAT, while the costs from Hyre
    //and Autolease do not include VAT
    const vatOnDefaultCost = sourceOfDefaultCost[costKey] === "OFV";
    const costVatRateForVehicle =
      1.0 + (costVATRate[costKey] - 1.0) * vehicleVatRatio;

    const vatAdjustmentFactor = vatOnDefaultCost
      ? costVatRateForVehicle / costVATRate[costKey]
      : costVatRateForVehicle;

    vatAdjustedCosts[costKey] = Math.round(
      costs[carType.category][carType.worth][carType.distance][costKey] *
        vatAdjustmentFactor
    );
  }
  return vatAdjustedCosts;
};

const blurIfEnter = (e) => {
  if (e.keyCode === 13) {
    e.target.blur();
  }
};

const InfoIconWithTooltip = (props) => {
  const id = "info-icon-" + useId();

  return (
    <div className="p-1" id={id}>
      <div
        style={{
          width: 16,
          height: 16,
          border: "1px solid #1c5dff",
          borderRadius: 8,
          textAlign: "center",
          color: "#1c5dff",
          fontWeight: 400,
          fontSize: 16,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        i
      </div>
      <UncontrolledTooltip target={CSS.escape(id)} placement="auto">
        <small>{props.tooltipText}</small>
      </UncontrolledTooltip>
    </div>
  );
};

const Line = (props) => {
  return (
    <>
      <div
        style={{
          width: String(33 * props.selectedStep) + "%",
          backgroundColor: "#1c5dff",
          top: 15.5,
          height: 5,
          position: "absolute",
        }}
      />
      <div
        style={{
          width: String(100 - 33 * props.selectedStep) + "%",
          left: String(33 * props.selectedStep) + "%",
          backgroundColor: "#aebdd6",
          height: 5,
          top: 15.5,
          position: "absolute",
        }}
      />
    </>
  );
};

const StepDescription = (props) => {
  const leftPositions = {
    0: "0%",
    1: "33.33%",
    2: "66.66%",
    3: "100%",
  };
  const leftPosition = leftPositions[props.step];
  const size = props.selectedStep === props.step ? 36 : 26;
  return (
    <div
      style={{
        backgroundColor:
          props.selectedStep >= props.step ? "#1c5dff" : "#aebdd6",
        width: size,
        height: size,
        position: "absolute",
        zIndex: 2,
        left: `calc(${leftPosition} - ${size / 2}px)`,
        top: 18 - size / 2,
        textAlign: "center",
        borderRadius: size / 2,
        color: "white",
        fontWeight: 400,
        fontSize: 20,
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => props.setStep(props.step)}
    >
      {props.step + 1}
    </div>
  );
};

const StepBar = forwardRef((props, ref) => {
  return (
    <div ref={ref} style={{ marginTop: -57, paddingTop: 57 }}>
      {/* Negative margin to counteract the header on the page when we scroll to this element. */}
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          margin: "20px 18px 10px",
        }}
      >
        <Line selectedStep={props.selectedStep} />
        <div
          style={{
            width: "100%",
            height: 36,
            position: "relative",
          }}
        >
          {[0, 1, 2, 3].map((step) => {
            return (
              <StepDescription
                step={step}
                selectedStep={props.selectedStep}
                setStep={props.setStep}
                key={"#" + step}
              />
            );
          })}
        </div>
      </div>
      <div className="pb-3 fw-bold">{stepDescription[props.selectedStep]}</div>
    </div>
  );
});

const BackButton = (props) => {
  return (
    <div
      onClick={() => {
        props.updateStep();
      }}
      style={{
        padding: "9px 19px",
        color: "#1c5dff",
        fontSize: 20,
        fontWeight: 400,
        borderRadius: 5,
        borderStyle: "solid",
        borderWidth: 1,
        cursor: "pointer",
      }}
    >
      Tilbake
    </div>
  );
};

const NextButton = (props) => {
  return (
    <div
      onClick={() => {
        props.updateStep();
      }}
      style={{
        padding: "10px 20px",
        backgroundColor: "#1c5dff",
        color: "white",
        fontSize: 20,
        fontWeight: 400,
        borderRadius: 5,
        cursor: "pointer",
      }}
    >
      Neste
    </div>
  );
};

const ContactButton = () => {
  return (
    <a
      href="mailto:christer@hyre.no?subject=Mobilitetsplan fra Hyre&body=Hei,%0D%0A%0D%0AJeg ønsker å vite mer om Mobilitetsplan fra Hyre!"
      style={{
        padding: "10px 20px",
        backgroundColor: "#1c5dff",
        color: "white",
        fontSize: 20,
        fontWeight: 400,
        borderRadius: 5,
        cursor: "pointer",
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      Ta kontakt!
    </a>
  );
};

const NextAndBackButtons = (props) => {
  return (
    <div
      style={{
        paddingTop: 30,
        display: "flex",
        justifyContent: "end",
        textAlign: "center",
        gap: 15,
      }}
    >
      {props.selectedStep > 0 && <BackButton updateStep={props.decreaseStep} />}
      {props.selectedStep < 3 ? (
        <NextButton updateStep={props.increaseStep} />
      ) : (
        <ContactButton />
      )}
    </div>
  );
};

class MobilityPlanCalculator extends React.Component {
  stepBar = createRef();

  constructor() {
    super();
    this.state = {
      activeStep: 0,
      carValues: carTypeDefaults,
      compareWithVan: false,
      costValues: setVATOnCosts(defaultCostValues, carTypeDefaults, false),
      manuallyEdited: {},
      adminValues: adminDefaults,
      mobilityPlanValues: mobilityPlanDefaults,
      isCostPerCategoryOpen: false,
    };
    this.formatter = new Intl.NumberFormat("nb-NO", {
      style: "currency",
      currency: "NOK",
    });
  }

  toggleVan() {
    const compareWithVan = !this.state.compareWithVan;
    this.setState({
      compareWithVan: compareWithVan,
      costValues: setVATOnCosts(
        defaultCostValues,
        this.state.carValues,
        compareWithVan
      ),
    });
  }

  toCurrency(number) {
    return this.formatter.format(number);
  }

  selectedDistanceNumberValue() {
    const distanceKey = this.state.carValues.distance;
    return carTypeCategories.distance.numberValue[distanceKey];
  }

  updateCostValues(updatedCarTypeKey, updatedCarTypeValue) {
    const newCarValues = {
      ...this.state.carValues,
      [updatedCarTypeKey]: updatedCarTypeValue,
    };
    this.setState({
      costValues: setVATOnCosts(
        defaultCostValues,
        newCarValues,
        this.state.compareWithVan
      ),
      manuallyEdited: {},
    });
  }

  calculateCost() {
    const costs = this.state.costValues;
    const adminCosts = this.state.adminValues;

    const monthlyCostsPerCategory = {
      leasing: Math.round(
        costs.leasingFee +
          (costs.startingFee + costs.wearFee) / costs.numberOfMonths
      ),
      insurance: Math.round((costs.trafficInsurance + costs.insurance) / 12),
      maintenanceAndService: Math.round(
        (costs.maintenance + costs.service) / 12
      ),
      tyresAndRouteTracking: Math.round(
        (costs.tyres +
          costs.tyreStorage +
          costs.tyreChange +
          costs.routeTracker) /
          12
      ),
      parking: Math.round(costs.parking / 12),
      admin: Math.round(
        (adminCosts.adminHourlyCost *
          adminCosts.adminHours *
          adminCosts.workWeeksInYear) /
          12
      ),
    };

    return monthlyCostsPerCategory;
  }

  calculateCO2Savings(mobilityPlan, leasingCarCategory, yearlyDistance) {
    //We assume that the Hyre Mobility Plan usage doesn't require any additional
    //cars as the organizational demand is during the low-utilization periods
    //of our fleet. Thus, as the HMP car would be produced "anyway", we
    //add only the CO2e from usage to the HMP option
    const mobilityPlanCategory = mobilityPlanToCarCategory[mobilityPlan];
    const mpValues = KgCO2ePerCategory[mobilityPlanCategory];
    const yearlyMobilityPlanCO2e = mpValues.perKm * yearlyDistance;

    const leasingValues = KgCO2ePerCategory[leasingCarCategory];
    const yearlyLeasingCO2e = leasingValues.perKm * yearlyDistance;
    const productionCO2eFromLeasingCar =
      leasingValues.fromProduction + leasingValues.fromRecycling;

    return {
      yearly: yearlyLeasingCO2e - yearlyMobilityPlanCO2e,
      productionAndRecycling: productionCO2eFromLeasingCar,
    };
  }

  comparisonOverview() {
    const selectedMobilityPlanTitle = mobilityPlanOptions.category.options[
      this.state.mobilityPlanValues.category
    ].replace(" ", "\xa0");
    let selectedCarCategory =
      carTypeCategories.category.options[
        this.state.carValues.category
      ].toLowerCase();
    if (!selectedCarCategory.includes("bil")) {
      selectedCarCategory = selectedCarCategory + "bil";
    }

    const VATFactorOnHyreDamageCost =
      selectedMobilityPlanTitle.includes("Electric") ||
      selectedMobilityPlanTitle.includes("Van")
        ? 1.0
        : 1.25;

    const monthlyCostPerCategory = this.calculateCost();
    const totalLeasingCost =
      monthlyCostPerCategory.leasing +
      monthlyCostPerCategory.insurance +
      monthlyCostPerCategory.maintenanceAndService +
      monthlyCostPerCategory.tyresAndRouteTracking +
      monthlyCostPerCategory.parking +
      monthlyCostPerCategory.admin;

    const monthlyCostGroupTitles = {
      leasing: "Leasingutgifter og påkost",
      insurance: "Forsikringsutgifter",
      maintenanceAndService: "Service og vedlikehold",
      tyresAndRouteTracking: "Dekk- og kjørebokutgifter",
      parking: "Parkeringsutgifter",
      admin: "Administrasjons\xadutgifter",
    };

    const mobilityPlanCost =
      mobilityPlanPrices[this.state.mobilityPlanValues.category].amount;
    const averageDamageCost = Math.round(
      (VATFactorOnHyreDamageCost *
        (damageCostPerKm * this.selectedDistanceNumberValue())) /
        12
    );
    const totalMobilityPlanCost = mobilityPlanCost + averageDamageCost;

    const savingAmount = totalLeasingCost - totalMobilityPlanCost;
    const savingPercentage = Math.round(
      (savingAmount / totalLeasingCost) * 100
    );

    const CO2savings = this.calculateCO2Savings(
      this.state.mobilityPlanValues.category,
      this.state.carValues.category,
      this.state.carValues.distance * 1000
    );

    const CO2Link = (
      <a
        href={
          "https://www.sciencedirect.com/science/article/pii/S1364032122000867"
        }
      >
        https://www.sciencedirect.com/science/article/pii/S1364032122000867
      </a>
    );

    return (
      <div>
        <Row>
          <Col xs="6">Månedsutgift ved leasing</Col>
          <Col xs="6" style={{ textAlign: "right", fontWeight: 500 }}>
            {this.toCurrency(totalLeasingCost)}
          </Col>
        </Row>

        <button
          style={{
            border: "none",
            background: "transparent",
            marginTop: 5,
            padding: 0,
            color: "#1c5dff",
            cursor: "pointer",
          }}
          onClick={() => {
            this.setState((prevState) => ({
              isCostPerCategoryOpen: !prevState.isCostPerCategoryOpen,
            }));
          }}
        >
          {this.state.isCostPerCategoryOpen
            ? "Skjul nedbrytning"
            : "Vis nedbrytning"}
        </button>
        <Collapse isOpen={this.state.isCostPerCategoryOpen}>
          <div style={{ borderLeft: "2px solid #1c5dff", paddingLeft: 10 }}>
            {Object.entries(monthlyCostPerCategory).map(
              ([costKey, costAmount]) => {
                if (costAmount === 0) return;
                return (
                  <Row key={costKey + "#row"}>
                    <Col xs="6">{monthlyCostGroupTitles[costKey]}</Col>
                    <Col xs="6" style={{ textAlign: "right" }}>
                      {this.toCurrency(costAmount)}
                    </Col>
                  </Row>
                );
              }
            )}
          </div>
        </Collapse>
        <hr />
        <Row>
          <Col xs="6">Kostnad for {selectedMobilityPlanTitle}</Col>
          <Col xs="6" style={{ textAlign: "right" }}>
            {this.toCurrency(mobilityPlanCost)}
          </Col>
        </Row>
        <Row>
          <Col xs="6" className="d-flex justify-content-between">
            Gjennomsnittlig skadekost ved{" "}
            {Math.round(this.selectedDistanceNumberValue() / 12)}&nbsp;km/mnd
            <InfoIconWithTooltip
              tooltipText={
                "I gjennomsnitt betaler sjåfører av Hyre-biler 0,23 kroner per kjørte kilometer for skader som oppstår når de leier en bil."
              }
            />
          </Col>
          <Col xs="6" style={{ textAlign: "right" }}>
            {this.toCurrency(averageDamageCost)}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs="6">Månedsutgift ved mobilitetsplan</Col>
          <Col xs="6" style={{ textAlign: "right", fontWeight: 500 }}>
            {this.toCurrency(totalMobilityPlanCost)}
          </Col>
        </Row>
        <br />
        {savingAmount > 0 && (
          <div>
            Mobilitetsplanen {selectedMobilityPlanTitle} sparer deg for&nbsp;
            {this.toCurrency(savingAmount)}&nbsp;hver måned. Det er en
            besparelse&nbsp;på&nbsp;
            <span style={{ fontWeight: 500 }}>{savingPercentage}&nbsp;%</span>!
          </div>
        )}
        {CO2savings.productionAndRecycling > 0 && (
          <div className="d-flex" style={{ paddingTop: 10 }}>
            <div>
              Sammenlignet med å lease en {selectedCarCategory}, vil
              mobilitetsplanen {selectedMobilityPlanTitle} være med på å{" "}
              <span style={{ fontWeight: 500 }}>
                spare miljøet for{" "}
                {Math.round(CO2savings.productionAndRecycling / 1000)}&nbsp;tonn{" "}
              </span>{" "}
              CO2-ekvivalenter.
            </div>
            <InfoIconWithTooltip
              tooltipText={
                "Besparelsen tilsvarer utslipp ved produksjon, minus fremtidige besparelser ved resirkulering, av en ny bil (Buberger et al., 2022)\u00B9. Siden Hyre Mobilitetsplan utnytter overskuddskapasitet i Hyre sin eksisterende flåte, beregner vi at tegning ikke utløser produksjon av en ny bil."
              }
            />
          </div>
        )}
        {CO2savings.yearly > 0 && (
          <Row
            style={{
              paddingTop: CO2savings.productionAndRecycling > 0 ? 5 : 10,
            }}
          >
            <Col xs="10">
              Overgangen fra en {selectedCarCategory} til mobilitetsplanen{" "}
              {selectedMobilityPlanTitle}{" "}
              <span style={{ fontWeight: 500 }}>
                sparer miljøet for {Math.round(CO2savings.yearly / 1000)}
                &nbsp;tonn{" "}
              </span>{" "}
              CO2-ekvivalenter <span style={{ fontWeight: 500 }}>hvert år</span>
              .
            </Col>
            <Col xs="2">
              <InfoIconWithTooltip
                tooltipText={
                  "Årlig besparelse av CO2-ekvivalenter er basert på tallene lagt inn i steg 1 for årlig kjørelengde, forbrukstall for ulike biltyper (Buberger et al., 2022)\u00B9, og NVEs beregninger av CO2-utslipp per kWt produsert i Norge."
                }
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col style={{ fontSize: 14, paddingTop: 30 }}>
            {"\u00B9"}
            {CO2Link}
          </Col>
        </Row>
      </div>
    );
  }

  Step1() {
    return (
      <div>
        {Object.entries(carTypeCategories).map(
          ([categoryKey, categoryOptions]) => {
            return (
              <Row key={categoryKey} style={{ paddingBottom: 10 }}>
                <Col xs="6">{categoryOptions.title}</Col>
                <Col xs="6">
                  <div className="d-flex flex-wrap" style={{ columnGap: 15 }}>
                    <Input
                      type="select"
                      defaultValue={this.state.carValues[categoryKey]}
                      style={{
                        textAlign: "right",
                        height: 34,
                        flex: "0 1 180px",
                        marginBottom: 5,
                        paddingTop: 0,
                        paddingBottom: 0,
                        color: "#1c5dff",
                        borderColor: "#1c5dff",
                        fontWeight: 500,
                      }}
                      bsSize="sm"
                      onChange={(e) => {
                        this.setState({
                          carValues: {
                            ...this.state.carValues,
                            [categoryKey]: e.target.value,
                          },
                        });
                        this.updateCostValues(categoryKey, e.target.value);
                      }}
                    >
                      {Object.entries(categoryOptions.options).map(
                        ([optionKey, optionValue]) => {
                          return (
                            <option key={optionKey} value={optionKey}>
                              {optionValue}
                            </option>
                          );
                        }
                      )}
                    </Input>
                    {categoryKey === "category" && (
                      <FormGroup
                        check
                        style={{
                          fontSize: 20,
                          fontWeight: 400,
                          color: "#1c5dff",
                        }}
                      >
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={this.state.compareWithVan}
                            onChange={() => {
                              this.toggleVan();
                            }}
                          />
                          <div style={{ marginTop: 4 }}>Varebil</div>
                        </Label>
                      </FormGroup>
                    )}
                  </div>
                </Col>
              </Row>
            );
          }
        )}
      </div>
    );
  }

  Step2() {
    const isElectric = this.state.carValues.category === "electric";
    const compareWithVan = this.state.compareWithVan;

    const vatDescription = compareWithVan
      ? vatOnVanLeasingDescription
      : isElectric
      ? parseInt(this.state.carValues.worth) > 500
        ? vatOnElectricLeasingAbove500kNOKDescription
        : vatOnElectricLeasingDescription
      : defaultVatOnLeasingDescription;

    return (
      <div>
        <div style={{ paddingBottom: 20, fontSize: 15 }}>{vatDescription}</div>
        {Object.entries(costTitles).map(([costKey, costTitle]) => {
          return (
            <Row key={costKey + "#row"} style={{ paddingBottom: 10 }}>
              <Col xs="6" className="d-flex justify-content-between">
                {costTitle}
                <InfoIconWithTooltip
                  tooltipText={
                    costTooltips[costKey] +
                    (sourceOfDefaultCost[costKey]
                      ? ` Kilde:\xa0${sourceOfDefaultCost[costKey]}`
                      : "")
                  }
                />
              </Col>
              <Col xs="6">
                <InputGroup>
                  <Input
                    key={costKey + "#input"}
                    type="number"
                    min={costInput[costKey].min}
                    max={costInput[costKey].max}
                    step={costInput[costKey].step}
                    value={this.state.costValues[costKey]}
                    style={{
                      borderRadius: 5,
                      borderColor: "#1c5dff",
                      textAlign: "right",
                      height: 25,
                      fontSize: 20,
                      paddingTop: 2,
                      paddingBottom: 0,
                      color: "#1c5dff",
                    }}
                    onKeyDown={(e) => {
                      blurIfEnter(e);
                    }}
                    onChange={(e) => {
                      this.setState({
                        costValues: {
                          ...this.state.costValues,
                          [costKey]: e.target.valueAsNumber,
                        },
                        manuallyEdited: {
                          ...this.state.manuallyEdited,
                          [costKey]: true,
                        },
                      });
                    }}
                  />
                  <InputGroupText
                    style={{
                      width: 50,
                      height: 25,
                      fontSize: 20,
                      paddingTop: 2,
                      paddingBottom: 0,
                      color: "#8490a3",
                      fontWeight: 400,
                      backgroundColor: "white",
                      borderStyle: "none",
                    }}
                  >
                    {costKey === "numberOfMonths" ? "mnd" : "NOK"}
                  </InputGroupText>
                </InputGroup>
              </Col>
            </Row>
          );
        })}
      </div>
    );
  }

  Step3() {
    return (
      <div>
        {Object.entries(adminTitles).map(([adminKey, adminTitle]) => {
          return (
            <Row key={adminKey + "#row"} style={{ paddingBottom: 10 }}>
              <Col xs="6" className="d-flex justify-content-between">
                {adminTitle}
                <InfoIconWithTooltip tooltipText={adminTooltips[adminKey]} />
              </Col>
              <Col xs="6">
                <InputGroup>
                  <Input
                    key={adminKey + "#input"}
                    type="number"
                    min={adminInput[adminKey].min}
                    max={adminInput[adminKey].max}
                    step={adminInput[adminKey].step}
                    value={this.state.adminValues[adminKey]}
                    style={{
                      borderRadius: 5,
                      borderColor: "#1c5dff",
                      textAlign: "right",
                      height: 25,
                      fontSize: 20,
                      paddingTop: 2,
                      paddingBottom: 0,
                      color: "#1c5dff",
                    }}
                    onChange={(e) => {
                      this.setState({
                        adminValues: {
                          ...this.state.adminValues,
                          [adminKey]: e.target.valueAsNumber,
                        },
                      });
                    }}
                  />
                  <InputGroupText
                    style={{
                      height: 25,
                      fontSize: 20,
                      width: 60,
                      paddingTop: 2,
                      paddingBottom: 0,
                      color: "#8490a3",
                      fontWeight: 400,
                      backgroundColor: "white",
                      borderStyle: "none",
                    }}
                  >
                    {adminKey === "adminHours"
                      ? this.state.adminValues.adminHours !== 1.0
                        ? "timer"
                        : "time"
                      : adminKey === "adminHourlyCost"
                      ? "NOK"
                      : "uker"}
                  </InputGroupText>
                </InputGroup>
              </Col>
            </Row>
          );
        })}
      </div>
    );
  }

  Step4() {
    return (
      <div>
        {Object.entries(mobilityPlanOptions).map(
          ([mobilityPlanKey, mobilityPlanOptions]) => {
            return (
              <div key={mobilityPlanKey + "#row"} style={{ maxWidth: 300 }}>
                <Input
                  key={mobilityPlanKey + "#input"}
                  type="select"
                  defaultValue={this.state.mobilityPlanValues.category}
                  style={{
                    height: 34,
                    color: "#1c5dff",
                    borderColor: "#1c5dff",
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                  bsSize="sm"
                  onChange={(e) => {
                    this.setState({
                      mobilityPlanValues: {
                        ...this.state.carValues,
                        [mobilityPlanKey]: e.target.value,
                      },
                    });
                  }}
                >
                  {Object.entries(mobilityPlanOptions.options).map(
                    ([optionKey, optionValue]) => {
                      return (
                        <option key={optionKey + "#option"} value={optionKey}>
                          {optionValue}
                        </option>
                      );
                    }
                  )}
                </Input>
              </div>
            );
          }
        )}
        <br />
        <div>{this.comparisonOverview()}</div>
      </div>
    );
  }

  render() {
    const steps = [this.Step1(), this.Step2(), this.Step3(), this.Step4()];

    return (
      <div className="mobility-plan-calculator">
        <StepBar
          ref={this.stepBar}
          selectedStep={this.state["activeStep"]}
          setStep={(i) => this.setState({ activeStep: i })}
        />
        {steps[this.state["activeStep"]]}
        <NextAndBackButtons
          selectedStep={this.state["activeStep"]}
          increaseStep={() => {
            this.setState({ activeStep: this.state["activeStep"] + 1 });
            if (this.stepBar.current) {
              this.stepBar.current.scrollIntoView();
            }
          }}
          decreaseStep={() => {
            this.setState({ activeStep: this.state["activeStep"] - 1 });
            if (this.stepBar.current) {
              this.stepBar.current.scrollIntoView();
            }
          }}
        />
      </div>
    );
  }
}

class Page extends React.Component {
  componentDidMount() {
    console.log("Interessert i Hyre? careers@hyre.no");
  }

  render() {
    return (
      <div>
        <Container style={{ maxWidth: 800 }}>
          <Row style={{ marginBottom: "100px", marginTop: "100px" }}>
            <Col>
              <div style={headerStyle}>
                <h1 style={{ color: "black" }}>
                  Sammenlign leasing med en mobilitetsplan
                </h1>
              </div>
              <div style={topTextStyle}>{topText}</div>
              <MobilityPlanCalculator />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Page;
