"use client";
import React from "react";
import { Col, Container, Row } from "reactstrap";

import Image from "next/image";

import Faq from "@/components/Faq";
import chevronRight from "../../../public/images/chevron-right.svg";
import Link from "next/link";

export default function Telia() {
  return (
    <div>
      <Container>
        <Row
          style={{
            marginBottom: "40px",
          }}
        >
          <Col xs="12">
            <h1
              className="hero-header2 text-center"
              style={{
                marginTop: "50px",
                color: "black",
              }}
            >
              Hyre for Telia
            </h1>

            <p
              style={{
                textAlign: "center",
                paddingLeft: "16px",
                paddingRight: "16px",
                maxWidth: "800px",
                margin: "32px auto 0",
              }}
            >
              Videoen under viser deg steg for steg hvordan du skal gå frem for
              å komme i gang med Hyre for Telia, men du kan også laste ned guide{" "}
              <a href="/hyre_for_telia.pdf" target="_blank">
                for ansatte
              </a>{" "}
              og{" "}
              <a href="/hyre_for_telia_admin.pdf" target="_blank">
                for administrator
              </a>
              .
            </p>
          </Col>
        </Row>

        <Row style={{ marginBottom: "60px" }}>
          <Col>
            <p
              style={{
                textAlign: "center",
                paddingLeft: "16px",
                paddingRight: "16px",
                maxWidth: "800px",
                margin: "0px auto 0",
              }}
            >
              Følg lenken for å få tilgang til å bestille Hyre-bil på Telia sin
              bedriftskonto:{" "}
              <a href="https://bedrift.hyre.no/telia/join?u=teliainfopage">
                https://bedrift.hyre.no/telia/join
              </a>
            </p>
          </Col>
        </Row>

        <Row style={{ marginBottom: "80px" }}>
          <Col>
            <style>{`
                .embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; height: auto; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
              `}</style>
            <div className="embed-container">
              <iframe
                src="https://player.vimeo.com/video/616052831"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          </Col>
        </Row>

        <Row style={{ marginBottom: "100px" }}>
          <Col>
            <h2>Ofte stilte spørsmål</h2>
            <Faq
              faqs={[
                {
                  category: "Ofte stilte spørsmål",
                  items: [
                    {
                      title: "Hvorfor får jeg en SMS fra Statens Vegvesen?",
                      content:
                        "Når du registrerer deg som bruker på Hyre sjekker vi automatisk opp mot Statens Vegvesen for å forvisse oss om at du har gyldig førerrett.",
                    },
                    {
                      title: "Kan jeg legge til ekstra sjåfør?",
                      content:
                        "Alle som kjører bilen må være registrert bruker på Hyre og være lagt til som ekstra sjåfør på leieforholdet for å følge vilkårene og være dekket av forsikringen. Du kan legge til så mange sjåfører som du trenger, og det er helt gratis. Merk evt. tillegg på forsikring for sjåfører under 23 år.",
                    },
                    {
                      title:
                        "Jeg har ikke WiFi eller 4G. Hvordan får jeg tilgang til bilen?",
                      content:
                        "I parkeringshus eller andre plasser som har dårlig eller ingen dekning, kan vi skru på mulighet for å hente bilen “Offline”. Prosessen er helt lik vanlig henting, og skadekontrollbildene lastes automatisk opp når du har dekning igjen.",
                    },
                    {
                      title:
                        "Jeg rekker ikke levere bilen til avtalt tid. Hva gjør jeg?",
                      content:
                        "I de aller fleste tilfeller kan du utvide leieforholdet enkelt i appen under “Utvid leieforhold”. Dersom bilen er booket av noen andre i tidsrommet du ønsker å utvide kan du kontakte oss på chat rett i appen eller på tlf. 22 44 49 73. Vi finner alltid en løsning sammen!",
                    },
                  ],
                },
              ]}
            />

            <div className="text-center pt-5">
              <Link
                href={`/hjelp`}
                style={{ fontSize: "1em", fontWeight: 600 }}
              >
                Klikk her for flere spørsmål og svar.
                <Image
                  src={chevronRight}
                  style={{ width: 24, margin: 0 }}
                  alt=""
                />
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
