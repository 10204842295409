"use client";
import React from "react";
import { Col, Container, Row } from "reactstrap";

import Link from "next/link";
import Image from "next/image";

import chevronRight from "../../../../public/images/chevron-right.svg";
import bensin from "../../../../public/images/b2b/fyllbensin.png";

class BrukerPage extends React.Component {
  render() {
    return (
      <div>
        {/* <Helmet title="Drivstoff og lading - Hyre for Bedrift" /> */}
        <Container
          className="text-center text-md-start"
          style={{
            marginTop: "100px",
            paddingTop: "70px",
            paddingBottom: "50px",
          }}
        >
          <Link href={`/bedrift/hjelp/`}>
            Hjelp Bedrift
            <Image src={chevronRight} style={{ width: 24, margin: 0 }} alt="" />
          </Link>
          <Link href={`/bedrift/hjelp/drivstoff`}>
            Fyll drivstoff og start lading i appen
          </Link>
          <hr></hr>
          <Row>
            <Col
              className="d-flex justify-content-center"
              xs="12"
              md="12"
              lg="6"
            >
              <p style={{ paddingTop: "10px" }}>
                <h1 className="big-fat-header">
                  Fyll drivstoff og start lading i appen
                </h1>
                Enten du kjører bensin-, diesel- eller elektrisk bil kan du
                enkelt belaste det rett på leieforholdet. Vi beregner antall
                liter på tanken før og etter leieforholdet. Det rabatteres 25kr
                per liter dersom bilen leveres med mer drivstoff, og belastes
                27kr per liter dersom bilen leveres med mindre drivstoff.
              </p>
            </Col>
            <Col
              className="d-flex align-items-center justify-content-center"
              xs="12"
              md="12"
              lg="6"
            >
              <Image src={bensin} alt="" className="align-self-center" />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default BrukerPage;
