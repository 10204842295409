"use client";
import React from "react";
import { Col, Container, Row } from "reactstrap";

import Image from "next/image";

import aboutUsStyles from "./about_us.module.css";

import phoneIcon from "../../../public/icons/phone.png";
import sameieBlue from "../../../public/icons/sameie-blue.svg";
import familiebilTesla from "../../../public/icons/familiebil-el_tesla.svg";
import demographics from "../../../public/icons/demographics.svg";

export default function SeAboutUs() {
  return (
    <div>
      <div
        className={`${aboutUsStyles.aboutUsBanner} dark-bottom-gradient-overlay"fat-header `}
      >
        <div className={aboutUsStyles.spacer} />
      </div>

      <Container>
        <h1 className="thin-header text-start" style={{ marginBottom: "20px" }}>
          Om Hyre
        </h1>
        <p>
          Hyre grundades 2018 och är nu ett av de största företagen inom
          bildelning i Norden med fler än 2 000 bilar i sitt utbud. Företaget är
          ett samarbete mellan{" "}
          <a
            href={"https://moller.no"}
            rel="noopener noreferrer"
            target="_blank"
          >
            Møller Mobility Group
          </a>{" "}
          och Hyre Management, där Møller Mobility Group är den största
          aktieägaren.
        </p>

        <h3>Snabb och smidig kundresa</h3>
        <p>
          Du behöver bara vår app för att boka en bil, låsa upp den och sedan
          betala bilhyran. Det är slut med att stå i tråkig kö för att hämta ut
          en bil. Vägtullar, bränsle/laddning och hyrpriser beräknas automatiskt
          i realtid, vilket gör att du alltid har fullständig översikt och
          slipper ägna tid åt att administrera diverse utgifter.
        </p>

        <p>
          Våra fordon är alltid tillgängliga, och vårt mål är att aldrig vara
          mer än 5 minuter bort från dig. Bilflottan ägs och hanteras av Hyre,
          vilket ger oss total kontroll över kundresan så att vi kan säkerställa
          en förutsägbar och säker tillgång till moderna bilar till
          konkurrenskraftiga priser.
        </p>

        <h3>Passion för teknik</h3>
        <p>
          Vi älskar ny teknik och de möjligheter den ger oss att skapa
          fantastiska kundupplevelser, och vi utmanar gärna status quo. Våra
          olika plattformar byggs och utvecklas av våra briljanta medarbetare,
          som ger oss möjlighet att agera snabbare och mer flexibelt än alla
          andra. Vi är idag 40 anställda teknikentusiaster i Norge, Sverige och
          Danmark.
        </p>

        <h3>
          Vi tror på en framtid med delade, självkörande elektriska bilar
          eftersom:
        </h3>
        <div className="row pt-3">
          <div className="col-12 col-md-4 d-flex flex-column">
            <Image
              src={familiebilTesla}
              alt=""
              height={100}
              width={180}
              className="align-self-start"
              style={{ objectFit: "contain" }}
            />
            <h3>Bilarna förändras.</h3>
            <p>
              Bilarna är nu anslutna till internet, vilket gör det mycket
              enklare med bildelning. Allt fler bilar är dessutom elbilar, och i
              framtiden kommer de att vara självkörande.
            </p>
          </div>

          <div className="col-12 col-md-4 d-flex flex-column">
            <Image
              src={demographics}
              alt=""
              height={100}
              width={180}
              className="align-self-start"
              style={{ objectFit: "contain" }}
            />
            <h3>Demografin förändras.</h3>
            <p>
              Bilen är inte längre en viktig statussymbol, och det är inte lika
              många unga vuxna som vill äga en bil idag. Folk vill ha enkelhet,
              flexibilitet och frihet.
            </p>
          </div>

          <div className="col-12 col-md-4 d-flex flex-column">
            <Image
              src={sameieBlue}
              alt=""
              height={100}
              width={150}
              className="align-self-start"
              style={{ objectFit: "contain" }}
            />
            <h3>Hållbarhet är ett krav.</h3>
            <p>
              Sättet vi förflyttar oss på idag är inte hållbart. Även med
              elbilar kommer förtätningen i våra största städer att tvinga fram
              alternativa lösningar inom persontransport.
            </p>
          </div>
        </div>

        <Container className="text-center pt-5">
          <h2 className="pt-4 thin-header mb-2">Våra kontaktuppgifter:</h2>

          <Row className="d-flex flex-row justify-content-center">
            <Col className="col-12 col-md-12 pt-2 pb-3 md-text-center text-center">
              <p>
                <strong>Hyre AB</strong>
                <br />
                org. nr 559370-4645
              </p>
              <p>
                <span
                  style={{
                    width: "26px",
                    fontSize: "38px",
                    lineHeight: "26px",
                    textAlign: "center",
                    color: "black",
                    fontWeight: 900,
                    display: "inline-block",
                    verticalAlign: "middle",
                    margin: "0 20px 0 0",
                  }}
                >
                  @
                </span>
                <a href="mailto:hej@hyre.se">hej@hyre.se</a>
              </p>
              <p>
                <Image
                  src={phoneIcon}
                  width={26}
                  height={26}
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    margin: "0 20px 0 0",
                  }}
                  alt=""
                />
                077-575 76 00
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
