"use client";

import buttonStyles from "@/styles/buttons.module.css";

export const MobilityPlanLeadForm = ({
  buttonText: button_text,
  internalComment: internal_comment,
}: {
  buttonText: string;
  internalComment: string;
}) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        fetch("https://api.hyre.no/register_organization_lead/", {
          method: "POST",
          headers: { "content-type": "application/json" },
          body: JSON.stringify({
            // @ts-ignore: weird form element access that TS doesn't like
            email_address: e.target.email_address.value,
            internal_comment,
          }),
        });
        // @ts-ignore: weird form element access that TS doesn't like
        e.target.submit.value = "Registrert!";
      }}
      style={{ margin: 0 }}
    >
      <input
        type="email"
        name="email_address"
        id="email"
        style={{
          height: 50,
          borderColor: "#1c5dff",
          fontSize: 16,
          borderRadius: 5,
          margin: 5,
          minWidth: 250,
          paddingBottom: 11,
          paddingTop: 16,
        }}
      />
      <input
        type="submit"
        name="submit"
        value={button_text}
        className={buttonStyles.btnElectricBlue}
        style={{ margin: 5 }}
      />
    </form>
  );
};
