export function formatCurrency(currency: string) {
  switch (currency.toLowerCase()) {
    case "nok":
      return "kr";
    case "dkk":
      return "kr";
    case "sek":
      return "kr";
  }
}
