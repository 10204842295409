"use client";
import React from "react";
import { Col, Container, Row } from "reactstrap";

import Link from "next/link";
import Image from "next/image";

import chevronRight from "../../../public/images/chevron-right.svg";

import en from "../../../public/images/background-numbers/1.png";
import to from "../../../public/images/background-numbers/2.png";
import tre from "../../../public/images/background-numbers/3.png";
import fire from "../../../public/images/background-numbers/4.png";
import fem from "../../../public/images/background-numbers/5.png";
import seks from "../../../public/images/background-numbers/6.png";
import syv from "../../../public/images/background-numbers/7.png";
import atte from "../../../public/images/background-numbers/8.png";
import ni from "../../../public/images/background-numbers/9.png";
import ti from "../../../public/images/background-numbers/10.png";
import elleve from "../../../public/images/background-numbers/11.png";

import bankid from "../../../public/images/app-screenshots/no/bankid.png";
import betaling from "../../../public/images/app-screenshots/no/betalingsprofil.png";
import reserver from "../../../public/images/app-screenshots/no/kjorreserver.png";
import skadekontroll from "../../../public/images/app-screenshots/no/skadekontroll.png";
import nokkel from "../../../public/images/app-screenshots/no/aktivbooking.png";
import bensin from "../../../public/images/app-screenshots/no/fyllbensin.png";
import easypark from "../../../public/images/app-screenshots/no/easypark.png";
import utvidleieperiode from "../../../public/images/app-screenshots/no/utvidleie.png";
import ekstrasjafor from "../../../public/images/app-screenshots/no/ekstrasjafor.png";
import meldskade from "../../../public/images/app-screenshots/no/meldskade.png";
import kvittering from "../../../public/images/app-screenshots/no/mineleieforhold.png";

import appStoreBadge from "../../../public/images/app-store-badge.svg";
import googlePlayBadge from "../../../public/images/google-play-badge.svg";

export default function HowItWorks() {
  return (
    <div>
      <div className="index-header">
        <Container>
          <h1 className="hero-header text-center text-md-center pt-4">
            This is how Hyre works
          </h1>
          <div className="hero-tagline text-center pb-4">
            Download. Unlock. Drive.
          </div>
        </Container>
      </div>

      <Container className="figcon text-center">
        <Row className="figrow3">
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="12"
            lg="6"
          >
            <figure className="figure">
              <Image src={en} className="img-fluid" alt="1" />
              <figcaption className="figcaption">
                <h2 className="fat-header text-center">
                  Download and log in with BankID or MitID
                </h2>
                <p className="text-justify">
                  To use Hyre, you must install the app on your phone and log in
                  with BankID. We validate your driver&apos;s license digitally,
                  which means that approval is quick, seamless and secure.
                </p>
                <p className="text-center pt-3">
                  <a href="https://play.google.com/store/apps/details?id=no.hyre.demand">
                    <Image
                      src={googlePlayBadge}
                      alt="Available on Google Play"
                      style={{
                        height: "40px",
                        marginRight: "13px",
                      }}
                    />
                  </a>
                  <a href="https://itunes.apple.com/no/app/hyre/id1294326121">
                    <Image
                      src={appStoreBadge}
                      alt="Download from App Store"
                      style={{ height: "40px" }}
                    />
                  </a>
                </p>
              </figcaption>
            </figure>
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="12"
            lg="6"
          >
            <figure className="figure2">
              <Image src={bankid} className="img-fluid" alt="bank id" />
            </figure>
          </Col>
        </Row>
        <Row className="figrow2">
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="12"
            lg="6"
          >
            <figure className="figure2">
              <Image src={betaling} className="img-fluid" alt="betaling" />
            </figure>
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="12"
            lg="6"
          >
            <figure className="figure">
              <Image src={to} className="img-fluid" alt="2" />
              <figcaption className="figcaption">
                <h2 className="fat-header text-center">
                  Register payment card
                </h2>
                <p className="text-justify">
                  Add multiple payment profiles to easily separate personal
                  trips from work trips. Here you can add both a personal
                  profile and a work profile. If you are connected to a business
                  account, you can also see and pay with it in the app.
                </p>
              </figcaption>
            </figure>
          </Col>
        </Row>
        <Row className="figrow4">
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="12"
            lg="6"
          >
            <figure className="figure">
              <Image src={tre} className="img-fluid" alt="3" />
              <figcaption className="figcaption3">
                <h2 className="fat-header text-center">
                  Drive now or schedule up-front
                </h2>
                <p className="text-justify">
                  Select &quot;Drive now&quot; to start a booking immediately.
                  The car is reserved to you for up to 24 hours, but you only
                  pay for the time you have the car. With &quot;Schedule&quot;
                  you can pre-order how long you want the car in advance, can
                  rent for hours or weeks, and you pay for the entire period you
                  book. The car is always returned to its permanent parking
                  space.
                </p>
              </figcaption>
            </figure>
          </Col>
          <Col className="d-flex justify-content-center" xs="12" md="12" lg="6">
            <figure className="figure2">
              <Image src={reserver} className="img-fluid" alt="" />
            </figure>
          </Col>
        </Row>
        <Row className="figrow2">
          <Col className="d-flex justify-content-center" xs="12" md="12" lg="6">
            <figure className="figure2">
              <Image src={skadekontroll} className="img-fluid" alt="bil" />
            </figure>
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="12"
            lg="6"
          >
            <figure className="figure">
              <Image src={fire} className="img-fluid" alt="4" />
              <figcaption className="figcaption">
                <h2 className="fat-header text-center">
                  Take damage control pictures
                </h2>
                <p className="text-justify">
                  Before you get access to the car, and before you return it,
                  you must take pictures of the car. The pictures document that
                  the car is in equally good condition before and after the
                  booking. You can take as many pictures as you want, whenever
                  you want.
                </p>
              </figcaption>
            </figure>
          </Col>
        </Row>
        <Row className="figrow">
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="12"
            lg="6"
          >
            <figure className="figure">
              <Image src={fem} className="img-fluid" alt="5" />
              <figcaption className="figcaption">
                <h2 className="fat-header text-center">
                  Unlock with your phone
                </h2>
                <p className="text-justify">
                  The phone is your car key. That way you get immediate access
                  to the car without ever waiting in line. Make sure you have
                  turned on bluetooth and fine-grained location services so your
                  phone allows connecting to the car.
                </p>
              </figcaption>
            </figure>
          </Col>
          <Col className="d-flex justify-content-center" xs="12" md="12" lg="6">
            <figure className="figure2">
              <Image src={nokkel} className="img-fluid" alt="nokkel" />
            </figure>
          </Col>
        </Row>
        <Row className="figrow2">
          <Col className="d-flex justify-content-center" xs="12" md="12" lg="6">
            <figure className="figure2">
              <Image src={bensin} className="img-fluid" alt="bensin" />
            </figure>
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="12"
            lg="6"
          >
            <figure className="figure">
              <Image src={seks} className="img-fluid" alt="6" />
              <figcaption className="figcaption5">
                <h2 className="fat-header text-center">Fuel and charging</h2>
                <p className="text-justify">
                  Whether you drive a petrol, diesel or electric car, you can
                  charge the cost directly to the booking. We calculate the
                  number of liters in the tank, or kWh in the battery, before
                  and after the booking, and you pay or get a discount for the
                  difference when returning the car.
                </p>
              </figcaption>
            </figure>
          </Col>
        </Row>
        <Row className="figrow">
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="12"
            lg="6"
          >
            <figure className="figure">
              <Image src={syv} className="img-fluid" alt="7" />
              <figcaption className="figcaption">
                <h2 className="fat-header text-center">Park with EasyPark</h2>
                <p className="text-justify">
                  With EasyPark integrated into the app, you can start and
                  charge for parking directly to your Hyre-booking, without
                  additional costs. When you leave the parking lot, the app will
                  automatically end your parking.
                </p>
              </figcaption>
            </figure>
          </Col>
          <Col className="d-flex justify-content-center" xs="12" md="12" lg="6">
            <figure className="figure2">
              <Image src={easypark} className="img-fluid" alt="easypark" />
            </figure>
          </Col>
        </Row>
        <Row className="figrow2">
          <Col className="d-flex justify-content-center" xs="12" md="12" lg="6">
            <figure className="figure2">
              <Image
                src={utvidleieperiode}
                className="img-fluid"
                alt="utvidleieperiode"
              />
            </figure>
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="12"
            lg="6"
          >
            <figure className="figure">
              <Image src={atte} className="img-fluid" alt="8" />
              <figcaption className="figcaption">
                <h2 className="fat-header text-center">Extend the booking</h2>
                <p className="text-justify">
                  If you want to rent the car for longer, you can easily extend
                  the rental period directly in the app. You will get an
                  overview of how long the car is available together with the
                  new total price. You can click on the new total price to see
                  your new receipt.
                </p>
              </figcaption>
            </figure>
          </Col>
        </Row>
        <Row className="figrow">
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="6"
          >
            <figure className="figure">
              <Image src={ni} className="img-fluid" alt="9" />
              <figcaption className="figcaption3">
                <h2 className="fat-header text-center">Add extra drivers</h2>
                <p className="text-justify">
                  You can add extra drivers free of charge. You do this by
                  pressing &quot;Add extra driver&quot; on the booking page in
                  the app. To be added as a driver, your friend or colleague
                  must have a Hyre account and an approved driver&apos;s license
                  to get access to the booking in the app.
                </p>
              </figcaption>
            </figure>
          </Col>
          <Col className="d-flex justify-content-center" xs="12" md="6">
            <figure className="figure2">
              <Image
                src={ekstrasjafor}
                className="img-fluid"
                alt="ekstra sjafor"
              />
            </figure>
          </Col>
        </Row>
        <Row className="figrow2">
          <Col className="d-flex justify-content-center" xs="12" md="6">
            <figure className="figure2">
              <Image src={meldskade} className="img-fluid" alt="meld skade" />
            </figure>
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="6"
          >
            <figure className="figure">
              <Image src={ti} className="img-fluid" alt="10" />
              <figcaption className="figcaption">
                <h2 className="fat-header text-center">Report damage</h2>
                <p className="text-justify">
                  If the car has visible damage when you pick it up, you get
                  some parking scratches or you have been in a major accident,
                  you report the damage through the app. If you have been in an
                  accident with others, you must also fill in a mutual damage
                  form.
                </p>
              </figcaption>
            </figure>
          </Col>
        </Row>
        <Row className="figrow">
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="12"
            lg="6"
          >
            <figure className="figure">
              <Image src={elleve} className="img-fluid" alt="11" />
              <figcaption className="figcaption">
                <h2 className="fat-header text-center">
                  Full overview in the app.
                </h2>
                <p className="text-justify">
                  You get a real time overview over costs during the entire
                  booking. We capture tollroads, parking with EasyPark, loads of
                  automatic parking garages, fueling or charging, to give you a
                  full overview of all costs in one place.
                </p>
              </figcaption>
            </figure>
          </Col>
          <Col className="d-flex justify-content-center" xs="12" md="12" lg="6">
            <figure className="figure2">
              <Image src={kvittering} className="img-fluid" alt="kvittering" />
            </figure>
          </Col>
        </Row>
        <div className="text-center pb-5 pt-3">
          <Link href={`/hjelp`} style={{ fontSize: "1em", fontWeight: 600 }}>
            Didn&apos;t find the answer you were looking for? See frequently
            asked questions here (Norwegian)
            <Image src={chevronRight} style={{ width: 24, margin: 0 }} alt="" />
          </Link>
        </div>
      </Container>
    </div>
  );
}
