"use client";
import React from "react";
import { Col, Container, Row } from "reactstrap";

import Image from "next/image";

import "./bedrift.css";
import buttonStyles from "@/styles/buttons.module.css";

import Faq from "@/components/Faq";

import elbil from "../../../public/images/elkompakt_vw_id3_liten.png";
import phones from "../../../public/images/app-screenshots/phones-sameie.png";
import sofa from "../../../public/images/b2b/sofa-ikon.svg";
import sameieHeader from "../../../public/images/sameie-header.svg";
import parkingGarage from "../../../public/images/parking-garage2.png";
import lasOpp from "../../../public/images/b2b/las_opp-ikon2.svg";
import velgBil from "../../../public/icons/velg_bil-ikon-4.svg";

import { HowCanWeImprove } from "../HowCanWeImprove";
import { HeaderBottomImage } from "../HeaderBottomImage";
import { ValueProp } from "../ValueProp";

export default function Sameie() {
  return (
    <div>
      <HeaderBottomImage
        background_color="#1c5dff"
        text={{
          body: "Velg mellom 1000 leiebiler på over 250 lokasjoner!",
          title: "Få sømløs bildeling i sameiet",
        }}
        image={{
          id: 0,
          title: "Lass opp",
          url: sameieHeader,
          height: 64,
          width: 64,
        }}
        subtitle_position="top"
      />

      <Container className="text-center">
        <Image src={elbil} alt="elbil" />
        <h2 className="big-fat-header">
          Få leieinntekter på p-plasser til glede for alle
        </h2>
        <p>
          Desto nærmere bilen er der hvor folk bor, jo enklere blir det å kvitte
          seg med egen bil og ta overgangen til bildeling. Mange biler står mye
          stille og opptar plasser som ellers kunne vært til nytte for alle som
          bor i nabolaget.
        </p>
        <p>
          Dersom dere har ledige parkeringsplasser leier vi den gjerne av dere.
        </p>
      </Container>

      <HowCanWeImprove
        background_color="#f5f5f5"
        title="Vil du vite mer?"
        text="<p>Dersom sameiet eller borettslaget ligger i Oslo, Bergen eller Trondheim kan vi i løpet av kort tid plassere ut biler i ditt nabolag. Så lenge dere har en parkeringsplass kan vi komme i gang på null komma niks.</p><p>Om dette virker interessant, så send oss en mail da vel!</p>"
        link_text="Send oss en mail"
        link_url="mailto:hei@hyre.no?subject=Parkeringsplass i sameiet"
      />

      <ValueProp
        headline={{
          title: `Så enkelt kan du leie bil med Hyre`,
          body: 'Hyre gjør det lekende lett for deg å leie bil i <a internal-href="/oslo/">Oslo</a>, <a internal-href="/bergen/">Bergen</a> og <a internal-href="/trondheim/">Trondheim</a> når du trenger. Det koster ingenting å lage bruker og du kan sitte bak rattet på under fem minutter. Bilene låses opp enkelt med mobilen, døgnet rundt, og utgiftene regnes ut automatisk når du avslutter reisen.',
        }}
        background_color="white"
        explainers={[
          {
            title: "Last ned og registrer deg",
            body: "For å leie bil og låse opp med mobilen må du laste ned Hyre-appen og lage bruker ved å logge inn med BankID.",
            icon: {
              id: 0,
              title: "Sofa",
              url: sofa,
              height: 64,
              width: 64,
            },
          },
          {
            title: "Velg bilen som passer deg",
            body: "Du kan leie i en time eller flere døgn. Velg å plukke opp bilen umiddelbart eller reserver frem i tid. Når du er ferdig leveres bilen tilbake til plassen den tilhører.",
            icon: {
              id: 0,
              title: "Velg bil",
              url: velgBil,
              height: 64,
              width: 64,
            },
          },
          {
            title: "Lås opp med mobilen",
            body: "Mobilen er bilnøkkelen din, så du trenger ikke vente på noen eller stå i kø for å få nøkkel. Appen kobler seg til bilen med bluetooth og du låser opp med et enkelt tastetrykk.",
            icon: {
              id: 0,
              title: "Lass opp",
              url: lasOpp,
              height: 64,
              width: 64,
            },
          },
        ]}
        end_text={`<a internal-href="/slik-fungerer-hyre" style="font-size: 1em; font-weight: 600;"><b>Les mer om hvordan leiebil med Hyre fungerer her &gt</b></a>`}
      />

      <Container className="text-center">
        <Image src={phones} alt="telefoner" />
        <h2 className="big-fat-header">
          Ingen faste kostnader. Kun månedlige leieinntekter
        </h2>
        <p>
          Leiebilene fra Hyre vil bli tilgjengelig for alle, uansett om man bor
          i sameiet eller like ved. Derfor betaler sameiet heller ingen ting for
          å få bilene utenfor - snarere tvert i mot får dere månedlige
          leieinntekter fra oss på parkeringsplassen. De som leier bilen betaler
          kun de faste lave leieprisene på Hyre.
        </p>
      </Container>

      <div className="mb-4 mt-4 pt-4 pb-4">
        <Container className="text-center">
          <Image
            src={parkingGarage}
            alt=""
            style={{ width: "100%", height: "auto" }}
          />
          <h2 className="big-fat-header pt-3">
            Høres bra ut, men hva skal til?
          </h2>
          <p className="pb-4">
            Det er ikke mye som skal til for å få biler i nabolaget ditt, men
            det er noen punkter vi trenger å huke av:
          </p>
        </Container>

        <Container>
          <div className="bedrift-explanation-wrapper bedrift-explanation-wrapper-vertical">
            <div className="bedrift-explanation-item-wrapper">
              <div className="bedrift-explanation-number"></div>
              <div className="bedrift-explanation-item mt-3">
                <h3>Sameiet er i Oslo, Bergen eller Trondheim</h3>
                <p>
                  Fordi vårt serviceapparat som ordner med vask, service,
                  dekkskift og annet vedlikehold er etablert i disse byene.
                </p>
              </div>
            </div>

            <div className="bedrift-explanation-item-wrapper">
              <div className="bedrift-explanation-number"></div>
              <div className="bedrift-explanation-item mt-3">
                <h3>Bilen er tilgjengelig for alle. Hele døgnet.</h3>
                <p>
                  Om p-plassen er i et lukket anlegg må man kunne åpne porten
                  med GSM-oppringing eller ved inntast av kode, slik at man får
                  tilgang til bilen. Koden vil kun bli synlig for de som leier
                  bilen, og under et aktivt leieforhold.
                </p>
              </div>
            </div>

            <div className="bedrift-explanation-item-wrapper">
              <div className="bedrift-explanation-number"></div>
              <div className="bedrift-explanation-item mt-3">
                <h3>Det er dekning der bilen står</h3>
                <p>
                  Fordi Hyre en en heldigital bildelingstjeneste må vi ha
                  dekning i det man plukker opp og leverer tilbake bilen. Men
                  slapp helt av, for bilen låser du opp med Bluetooth, så det er
                  bare å reise til fjells..
                </p>
              </div>
            </div>

            <div className="bedrift-explanation-item-wrapper">
              <div className="bedrift-explanation-number"></div>
              <div className="bedrift-explanation-item ">
                <h3>Elbiler trenger lading</h3>
                <p>
                  Elbil funker best med strøm, så dersom man helst vil ha en
                  elbil må plassen ha en ladeboks. Da slipper man å komme til en
                  bil som er tom for strøm. Deilig, ikke sant?
                </p>
              </div>
            </div>
          </div>

          <div className="text-center">
            <a
              className={`btn ${buttonStyles.btnElectricBlue} mb-3`}
              href="mailto:hei@hyre.no?subject=Parkeringsplass i sameiet"
            >
              Jeg vil vite mer om leiebil i mitt sameie
            </a>
          </div>
        </Container>
      </div>

      <Container>
        <Row style={{ marginBottom: "100px", marginTop: "100px" }}>
          <Col>
            <h2>Ofte stilte spørsmål</h2>
            <Faq
              faqs={[
                {
                  category: "Ofte stilte spørsmål",
                  items: [
                    {
                      title: "Koster det noe å få biler til sameiet vårt?",
                      content:
                        "Nei, det koster ingen ting å ha biler fra Hyre tilgjengelig på parkeringsplasser til sameiet eller borettslaget. Så lenge bilene er offentlig tilgjengelige for alle å leie vil det kun være de som leier bilen som betaler for bruken.",
                    },
                    {
                      title: "Hvordan gjør vi det med betaling for strøm?",
                      content:
                        "Normalt sett så betaler vi en fast månedlig leieavtale for parkeringsplassen som inkluderer strømforbruket. Ta kontakt med oss så finner vi en god løsning sammen.",
                    },
                    {
                      title: "Må vi binde oss for en lang periode?",
                      content:
                        "Nei, Hyre skal være superfleksibelt og det er ingen bindingstid utover løpende 1 måned for begge parter. På den måten kan vi bli enige om å fjerne biler dersom de ikke blir brukt, eller dere ønsker å bruke plassene til noe annet.",
                    },
                  ],
                },
              ]}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
