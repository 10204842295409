"use client";
import React from "react";
import { Col, Container, Row } from "reactstrap";

import Link from "next/link";
import Image from "next/image";

import chevronRight from "../../../../public/images/chevron-right.svg";
import kostnadskoder from "../../../../public/images/b2b/kostnadsnokler-oversikt.png";
import kostnadskoder2 from "../../../../public/images/b2b/kostnadsnokler-oversikt2.png";

class BrukerPage extends React.Component {
  render() {
    return (
      <div>
        {/* <Helmet title="Kostnadskoder - Hyre for Bedrift" /> */}
        <Container
          className="text-center text-md-start"
          style={{
            marginTop: "100px",
            paddingTop: "70px",
            paddingBottom: "50px",
          }}
        >
          <Link href={`/bedrift/hjelp/`}>
            Hjelp Bedrift
            <Image src={chevronRight} style={{ width: 24, margin: 0 }} alt="" />
          </Link>
          <Link href={`/bedrift/hjelp/azuread`}>Sette opp kostnadskoder</Link>
          <hr></hr>
          <Row style={{ paddingTop: "10px" }}>
            <Col xs="12" md="12">
              <h1 className="big-fat-header">Sette opp kostnadskoder</h1>
              <p>
                Som administrator kan du sette opp kostnadskoder for å enkelt
                allokere kostnader til rett prosjekt eller avdeling. Dette gjør
                du under &quot;Innstillinger&quot; og &quot;Betaling&quot;. Du
                kan sette opp inntil to kostnadsnøkler, og du kan sette opp
                forhåndsdefinerte valg.
              </p>
            </Col>
          </Row>
          <Row style={{ paddingTop: "10px" }}>
            <Col xs="12" md="12">
              <p>
                <strong>Følg stegene her:</strong>
              </p>
            </Col>
            <Col xs="12" md="12">
              <p>
                <strong>Steg 1: </strong> Under innstillinger kan du sette opp
                kostnadskoder for å enkelt allokere kostnader til rett prosjekt
                eller avdeling. Klikk på &quot;Betaling&quot; til venstre i
                vinduet. Gå til den nederste firkanten markert med
                &quot;Kostnadsnøkler&quot; og velg &quot;Endre&quot;.
              </p>
            </Col>
            <Col xs="12" md="8">
              <Image
                src={kostnadskoder}
                alt=""
                className="align-self-center"
                style={{ width: "100%", height: "auto", marginBottom: 32 }}
              />
            </Col>
            <Col xs="12" md="12">
              <p>
                <strong>Steg 2: </strong> Her kan du legge til inntil to
                kostnadsnøkler. Du kan velge tekst og forhåndsdefinerte valg i
                tillegg til den vanlige tekstlinjen.
              </p>
            </Col>
            <Col xs="12" md="8">
              <Image
                src={kostnadskoder2}
                alt=""
                className="align-self-center"
                style={{ width: "100%", height: "auto", marginBottom: 32 }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default BrukerPage;
