"use client";
import React from "react";
import { Col, Container, Row } from "reactstrap";

import Link from "next/link";
import Image from "next/image";

import chevronRight from "../../../public/images/chevron-right.svg";

import en from "../../../public/images/background-numbers/1.png";
import to from "../../../public/images/background-numbers/2.png";
import tre from "../../../public/images/background-numbers/3.png";
import fire from "../../../public/images/background-numbers/4.png";
import fem from "../../../public/images/background-numbers/5.png";
import seks from "../../../public/images/background-numbers/6.png";
import syv from "../../../public/images/background-numbers/7.png";
import atte from "../../../public/images/background-numbers/8.png";
import ni from "../../../public/images/background-numbers/9.png";
import ti from "../../../public/images/background-numbers/10.png";
import elleve from "../../../public/images/background-numbers/11.png";

import bankid from "../../../public/images/app-screenshots/no/bankid.png";
import betaling from "../../../public/images/app-screenshots/no/betalingsprofil.png";
import reserver from "../../../public/images/app-screenshots/no/kjorreserver.png";
import skadekontroll from "../../../public/images/app-screenshots/no/skadekontroll.png";
import nokkel from "../../../public/images/app-screenshots/no/aktivbooking.png";
import bensin from "../../../public/images/app-screenshots/no/fyllbensin.png";
import easypark from "../../../public/images/app-screenshots/no/easypark.png";
import utvidleieperiode from "../../../public/images/app-screenshots/no/utvidleie.png";
import ekstrasjafor from "../../../public/images/app-screenshots/no/ekstrasjafor.png";
import meldskade from "../../../public/images/app-screenshots/no/meldskade.png";
import kvittering from "../../../public/images/app-screenshots/no/mineleieforhold.png";

import appStoreBadge from "../../../public/images/app-store-badge.svg";
import googlePlayBadge from "../../../public/images/google-play-badge.svg";

export default function HowItWorks() {
  return (
    <div>
      <div className="index-header">
        <Container>
          <h1 className="hero-header text-center text-md-center pt-4">
            Slik fungerer leiebil med Hyre
          </h1>
          <div className="hero-tagline text-center pb-4">
            Last ned. Lås opp. Kjør.
          </div>
        </Container>
      </div>

      <Container className="figcon text-center">
        <Row className="figrow3">
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="12"
            lg="6"
          >
            <figure className="figure">
              <Image src={en} className="img-fluid" alt="1" />
              <figcaption className="figcaption">
                <h2 className="fat-header text-center">
                  Last ned og logg inn med BankID
                </h2>
                <p className="text-justify">
                  For å bruke Hyre må du installere appen på din telefon. Logg
                  gratis inn med BankID. Vi sjekker gyldig førerkort opp mot
                  Statens Vegvesens database, noe som gjør at godkjenningen går
                  raskt og sømløst.
                </p>
                <p className="text-center pt-3">
                  <a href="https://play.google.com/store/apps/details?id=no.hyre.demand">
                    <Image
                      src={googlePlayBadge}
                      alt="Tilgjengelig på Google Play"
                      style={{
                        height: "40px",
                        marginRight: "13px",
                      }}
                    />
                  </a>
                  <a href="https://itunes.apple.com/no/app/hyre/id1294326121">
                    <Image
                      src={appStoreBadge}
                      alt="Last ned fra App Store"
                      style={{ height: "40px" }}
                    />
                  </a>
                </p>
              </figcaption>
            </figure>
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="12"
            lg="6"
          >
            <figure className="figure2">
              <Image src={bankid} className="img-fluid" alt="bank id" />
            </figure>
          </Col>
        </Row>
        <Row className="figrow2">
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="12"
            lg="6"
          >
            <figure className="figure2">
              <Image src={betaling} className="img-fluid" alt="betaling" />
            </figure>
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="12"
            lg="6"
          >
            <figure className="figure">
              <Image src={to} className="img-fluid" alt="2" />
              <figcaption className="figcaption">
                <h2 className="fat-header text-center">
                  Registrer betalingskort
                </h2>
                <p className="text-justify">
                  Legg til flere betalingsprofiler for å enkelt skille mellom
                  personlige turer og jobbturer. Her kan du legge til både
                  personlig profil og jobbprofil. Dersom du er tilknyttet en
                  bedriftskonto vil du også få en oversikt dette her.
                </p>
              </figcaption>
            </figure>
          </Col>
        </Row>
        <Row className="figrow4">
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="12"
            lg="6"
          >
            <figure className="figure">
              <Image src={tre} className="img-fluid" alt="3" />
              <figcaption className="figcaption3">
                <h2 className="fat-header text-center">
                  Kjør nå eller reserver
                </h2>
                <p className="text-justify">
                  Velg &quot;Kjør nå&quot; for å starte et leieforhold
                  umiddelbart. Bilen reserveres i 24t og du betaler kun for
                  tiden du har hatt bilen. Med &quot;Reserver&quot; velger du
                  tidspunkt selv, og hvor lenge du vil ha bilen. Du betaler for
                  hele perioden. Bilen leveres tilbake til sin faste
                  parkeringsplass.
                </p>
              </figcaption>
            </figure>
          </Col>
          <Col className="d-flex justify-content-center" xs="12" md="12" lg="6">
            <figure className="figure2">
              <Image src={reserver} className="img-fluid" alt="" />
            </figure>
          </Col>
        </Row>
        <Row className="figrow2">
          <Col className="d-flex justify-content-center" xs="12" md="12" lg="6">
            <figure className="figure2">
              <Image src={skadekontroll} className="img-fluid" alt="bil" />
            </figure>
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="12"
            lg="6"
          >
            <figure className="figure">
              <Image src={fire} className="img-fluid" alt="4" />
              <figcaption className="figcaption">
                <h2 className="fat-header text-center">
                  Ta skadekontrollbilder
                </h2>
                <p className="text-justify">
                  Før du får tilgang til bilen, og før du leverer den tilbake,
                  må du ta bilder av bilen. Bildene er din forsikring om at
                  bilen er i like god stand før og etter leieforhold. Du kan ta
                  så mange bilder du vil, når du vil.{" "}
                </p>
              </figcaption>
            </figure>
          </Col>
        </Row>
        <Row className="figrow">
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="12"
            lg="6"
          >
            <figure className="figure">
              <Image src={fem} className="img-fluid" alt="5" />
              <figcaption className="figcaption">
                <h2 className="fat-header text-center">Lås opp med mobilen </h2>
                <p className="text-justify">
                  Mobilen er bilnøkkelen din, så du slipper å stå i kø eller
                  vente på noen for å få nøkkel. Sørg for at du har skrudd på
                  bluetooth og stedstjenester for å kunne koble til bilen.
                </p>
              </figcaption>
            </figure>
          </Col>
          <Col className="d-flex justify-content-center" xs="12" md="12" lg="6">
            <figure className="figure2">
              <Image src={nokkel} className="img-fluid" alt="nokkel" />
            </figure>
          </Col>
        </Row>
        <Row className="figrow2">
          <Col className="d-flex justify-content-center" xs="12" md="12" lg="6">
            <figure className="figure2">
              <Image src={bensin} className="img-fluid" alt="bensin" />
            </figure>
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="12"
            lg="6"
          >
            <figure className="figure">
              <Image src={seks} className="img-fluid" alt="6" />
              <figcaption className="figcaption5">
                <h2 className="fat-header text-center">Drivstoff og lading</h2>
                <p className="text-justify">
                  Enten du kjører bensin-, diesel- eller elektrisk bil kan du
                  belaste kostnaden rett på leieforholdet. Vi beregner antall
                  liter på tanken før og etter leieforholdet. Det rabatteres per
                  liter dersom bilen leveres med mer drivstoff, og belastes per
                  liter dersom bilen leveres med mindre drivstoff. Prisene per
                  liter vises i appen ved bestilling. Dersom leieforholdet i
                  minus genereres en tilgodelapp.
                </p>
              </figcaption>
            </figure>
          </Col>
        </Row>
        <Row className="figrow">
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="12"
            lg="6"
          >
            <figure className="figure">
              <Image src={syv} className="img-fluid" alt="7" />
              <figcaption className="figcaption">
                <h2 className="fat-header text-center">Parker med EasyPark</h2>
                <p className="text-justify">
                  Med EasyPark integrert i appen kan du starte parkering rett på
                  leieforholdet, uten ekstra utlegg. Når du kjører ut av
                  takstgruppen vil betalingen avsluttes automatisk.
                </p>
              </figcaption>
            </figure>
          </Col>
          <Col className="d-flex justify-content-center" xs="12" md="12" lg="6">
            <figure className="figure2">
              <Image src={easypark} className="img-fluid" alt="easypark" />
            </figure>
          </Col>
        </Row>
        <Row className="figrow2">
          <Col className="d-flex justify-content-center" xs="12" md="12" lg="6">
            <figure className="figure2">
              <Image
                src={utvidleieperiode}
                className="img-fluid"
                alt="utvidleieperiode"
              />
            </figure>
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="12"
            lg="6"
          >
            <figure className="figure">
              <Image src={atte} className="img-fluid" alt="8" />
              <figcaption className="figcaption">
                <h2 className="fat-header text-center">Utvid leieperioden</h2>
                <p className="text-justify">
                  Dersom du vil leie bilen lenger kan du enkelt utvide
                  leieperioden rett i appen. Du vil få en oversikt over hvor
                  lenge bilen er tilgjengelig sammen med ny totalpris. Du kan
                  klikke deg inn på den nye totalprisen for å se din nye
                  kvittering.
                </p>
              </figcaption>
            </figure>
          </Col>
        </Row>
        <Row className="figrow">
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="6"
          >
            <figure className="figure">
              <Image src={ni} className="img-fluid" alt="9" />
              <figcaption className="figcaption3">
                <h2 className="fat-header text-center">Ekstra sjåfør</h2>
                <p className="text-justify">
                  Du kan kostnadsfritt legge til ekstra sjåfører. Dette gjør du
                  ved å trykke på «Registrer ekstra sjåfør» på bookingsiden i
                  appen. Personen(e) du legger til som ekstra sjåfør må ha
                  Hyre-konto, godkjent førerrett og til slutt kunne se
                  leieforholdet i sin Hyre-app.
                </p>
              </figcaption>
            </figure>
          </Col>
          <Col className="d-flex justify-content-center" xs="12" md="6">
            <figure className="figure2">
              <Image
                src={ekstrasjafor}
                className="img-fluid"
                alt="ekstra sjafor"
              />
            </figure>
          </Col>
        </Row>
        <Row className="figrow2">
          <Col className="d-flex justify-content-center" xs="12" md="6">
            <figure className="figure2">
              <Image src={meldskade} className="img-fluid" alt="meld skade" />
            </figure>
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="6"
          >
            <figure className="figure">
              <Image src={ti} className="img-fluid" alt="10" />
              <figcaption className="figcaption">
                <h2 className="fat-header text-center">Meld skade</h2>
                <p className="text-justify">
                  Enten bilen har synlig skade når du plukker opp bilen,
                  parkeringsripe eller du har vært i en større ulykke melder du
                  skaden inn via appen. Har du vært i en ulykke med andre må du
                  også fylle ut gjensidig skadeskjema som normalt.
                </p>
              </figcaption>
            </figure>
          </Col>
        </Row>
        <Row className="figrow">
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="12"
            lg="6"
          >
            <figure className="figure">
              <Image src={elleve} className="img-fluid" alt="11" />
              <figcaption className="figcaption">
                <h2 className="fat-header text-center">
                  Full oversikt i appen.
                </h2>
                <p className="text-justify">
                  Du kan følge med på oppgjøret under hele leieforholdet. Vi
                  fanger opp bompasseringer, parkering med EasyPark og fylling
                  av drivstoff eller lading, og legger det til på oppgjøret. Du
                  får full oversikt over alle kostnader på ett og samme sted.{" "}
                </p>
              </figcaption>
            </figure>
          </Col>
          <Col className="d-flex justify-content-center" xs="12" md="12" lg="6">
            <figure className="figure2">
              <Image src={kvittering} className="img-fluid" alt="kvittering" />
            </figure>
          </Col>
        </Row>
        <div className="text-center pb-5 pt-3">
          <Link href={`/hjelp`} style={{ fontSize: "1em", fontWeight: 600 }}>
            Fant du ikke svaret du så etter? Se flere spørsmål og svar her
            <Image src={chevronRight} style={{ width: 24, margin: 0 }} alt="" />
          </Link>
        </div>
      </Container>
    </div>
  );
}
