"use client";
import React from "react";
import { Col, Container, Row } from "reactstrap";

import Link from "next/link";
import Image from "next/image";

import chevronRight from "../../../../public/images/chevron-right.svg";
import azure from "../../../../public/images/b2b/azure-sync.png";
import azure2 from "../../../../public/images/b2b/azure-sync2.png";
import azure3 from "../../../../public/images/b2b/azure-sync3.png";

class BrukerPage extends React.Component {
  render() {
    return (
      <div>
        {/* <Helmet title="Sync Azure AD - Hyre for Bedrift" /> */}
        <Container
          className="text-center text-md-start"
          style={{
            marginTop: "100px",
            paddingTop: "70px",
            paddingBottom: "50px",
          }}
        >
          <Link href={`/bedrift/hjelp`}>
            Hjelp Bedrift
            <Image src={chevronRight} style={{ width: 24, margin: 0 }} alt="" />
          </Link>
          <Link href={`/bedrift/hjelp/azuread`}>Azure Ad Sync</Link>
          <hr></hr>
          <Row style={{ paddingTop: "10px" }}>
            <Col xs="12" md="12">
              <h1 className="big-fat-header">
                Autentisere Azure AD for synkronisering av ansattliste
              </h1>
              <p>
                Hyre er i gang med å implementere synkronisering av ansattlister
                fra Azure AD for å administrere tilgang til bestilling av bil
                gjennom vår bedriftsportal. For å sette opp selve
                synkroniseringen trenger vi tilgang til bedriftens Azure-konto
                nå slik at vi kan tilpasse oppsettet basert på dataen som er
                tilgjengelig, og formatet den er på.
              </p>
            </Col>
            <Col xs="12" md="12">
              <p>Steg for å sette opp godkjenning av tilgang til Azure AD:</p>
              <p>
                <li>Send oss domenet til deres Azure AD</li>
                <li>Vi hvitelister da bedriften for autentisering</li>
                <li>
                  Noen med administratorprivilegier i deres Azure AD-konto og i
                  bedriften på bedrift.hyre.no gjennomfører autentisering
                </li>
                <li>
                  Vi implementerer synkronisering basert på deres ansattliste
                </li>
              </p>
            </Col>
          </Row>
          <Row style={{ paddingTop: "10px" }}>
            <Col xs="12" md="12">
              <p>
                Når vi har mottatt Azure AD-domene vil man kunne sette opp
                autentisering fra bedrift.hyre.no slik:
              </p>
            </Col>
            <Col xs="12" md="6">
              <Image
                src={azure}
                alt=""
                className="align-self-center"
                style={{ width: "100%", height: "auto" }}
              />
              <p>Klikk “Koble til Azure AD-konto”</p>
            </Col>
          </Row>
          <Row style={{ paddingTop: "10px" }}>
            <Col xs="12" md="6">
              <Image
                src={azure2}
                alt=""
                height={662}
                className="align-self-center"
              />
            </Col>
            <Col xs="12" md="6">
              <Image
                src={azure3}
                alt=""
                height={662}
                className="align-self-center"
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="12" style={{ paddingBottom: "20px" }}>
              <p>
                Du blir nå tatt til Azure for å akseptere tilgang til vår
                applikasjon. Her vises en liste over hvilke permissions vi
                trenger.
              </p>
              <p>Ved å godta blir du sendt tilbake til bedrift.hyre.no</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default BrukerPage;
