export const topText =
  "Få tilgang til hundrevis av biler, men betal kun for én. For en fast lav månedspris får dere tilgang til hundrevis av biler i alle byene vi er etablert i. Trenger\xa0dere\xa0flere biler på samme tid? Velg det antall mobilitetsplaner som tilsvarer bedriftens samtidige bilbehov, og dra nytte av problemfritt bilhold uten administrasjonskostnader!";

export const stepDescription = {
  0: "Velg bil for sammenligning",
  1: "Anslå kostnader for bilhold",
  2: "Anslå administrasjonskostnader",
  3: "Velg mobilitetsplan",
};

export const vatOnElectricLeasingDescription =
  "Kostnadene oppgis ekskl. mva. da langtidsleie av elbil med verdi lavere enn NOK 500 000 er fritatt mva. og retten til fradrag for mva. på kostnader tilknyttet bilholdet følger fradragsretten til kjøretøyet.";
export const vatOnElectricLeasingAbove500kNOKDescription =
  "Kostnadene oppgis med mva. tilsvarende den delvise avgiftsplikten innført 1. januar 2023 ettersom retten til fradrag for mva. på kostnader tilknyttet bilholdet følger fradragsretten til kjøretøyet.";
export const vatOnVanLeasingDescription =
  "Kostnadene oppgis ekskl. mva. da leasing av varebil gir fradragsrett for mva. og retten til fradrag for mva. på kostnader tilknyttet bilholdet følger fradragsretten til kjøretøyet.";
export const defaultVatOnLeasingDescription =
  "Kostnadene oppgis inkl. mva. da leasing av personbil ikke gir fradragsrett for mva. og retten til fradrag for mva. på kostnader tilknyttet bilholdet følger fradragsretten til kjøretøyet.";

export const carTypeCategories = {
  category: {
    title: "Biltype",
    options: {
      electric: "Elbil",
      hybrid: "Hybrid",
      petrol: "Bensin",
      diesel: "Diesel",
    },
  },
  worth: {
    title: "Verdi av nybil",
    options: { 320: "320 000 NOK", 480: "480 000 NOK", 640: "640 000 NOK" },
  },
  distance: {
    title: "Årlig kjøredistanse",
    options: {
      10: "10 000 km",
      15: "15 000 km",
      20: "20 000 km",
      30: "30 000 km",
    },
    numberValue: {
      10: 10000,
      15: 15000,
      20: 20000,
      30: 30000,
    },
  },
};

export const carTypeDefaults = {
  category: "electric",
  worth: "480",
  distance: "20",
};

export const costTitles = {
  leasingFee: "Månedlig leasingavgift",
  startingFee: "Oppstarts\xadinnskudd",
  wearFee: "Forventet påkost",
  numberOfMonths: "Antall måneder",
  insurance: "Forsikring per år",
  trafficInsurance: "Trafikkforsikrings\xadavgift per år",
  service: "Service (inkl. olje) per år",
  maintenance: "Vedlikehold per år",
  tyres: "Dekkostnad per år",
  tyreStorage: "Dekkhotell per år",
  tyreChange: "Dekkskift per år",
  parking: "Parkering per år",
  routeTracker: "GPS-/Kjørebok\xadkostnader per år",
};

export const costTooltips = {
  leasingFee: "Den månedlige leasingavgiften.",
  startingFee: "Eventuelt innskudd gjort ved oppstart av leasingen.",
  wearFee:
    "Ved tilbakelevering av leaset bil er det vanlig å måtte betale noe for slitasjeskader utover normal slitasje, såkalt påkost.",
  numberOfMonths: "Antall måneder leasingavtalen strekker seg over.",
  insurance: "Ansvar- og kaskoforsikring tilknyttet bilen.",
  trafficInsurance:
    "Trafikkforsikringsavgiften vedtas av Stortinget hvert år, og betales sammen med forsikringen.",
  service:
    "Vanlig kilometerservice, mindre reparasjoner, og eventuelle kostnader tilknyttet olje og oljeskift.",
  maintenance:
    "Kostnader tilknyttet vask, rekvisita, rustbeskyttelse, og lignende.",
  tyres: "Kostnad for dekkjøp fordelt over dekkenes levetid.",
  tyreStorage: "Årlige kostnader tilknyttet oppbevaring av bilens dekk.",
  tyreChange: "Årlige kostnader tilknyttet skifte til sommer- og vinterdekk.",
  parking:
    "Årlige parkeringskostnader, alternativt potensiell inntjening ved utleie av plassen bilen står på.",
  routeTracker: "Årlige kostnader tilknyttet GPS- og kjørebokløsninger.",
};

export const otherCostDefaults = {
  wearFee: 7500,
  numberOfMonths: 48,
  tyreStorage: 1632,
  tyreChange: 1200,
  routeTracker: 1668,
};

export const defaultCostValues = {
  electric: {
    320: {
      10: {
        ...otherCostDefaults,
        leasingFee: 4916.0,
        startingFee: 0,
        depreciation: 22820.0,
        interest: 13440.0,
        insurance: 6499.0,
        trafficInsurance: 3059.0,
        maintenance: 4937.0,
        fuel: 2346.0,
        tyres: 2260.0,
        service: 3444.0,
        toll: 1233.0,
        parking: 18000.0,
      },
      15: {
        ...otherCostDefaults,
        leasingFee: 5138.0,
        startingFee: 0,
        depreciation: 31031.0,
        interest: 14400.0,
        insurance: 7093.0,
        trafficInsurance: 3059.0,
        maintenance: 5536.0,
        fuel: 3518.0,
        tyres: 3390.0,
        service: 4898.0,
        toll: 1850.0,
        parking: 18000.0,
      },
      20: {
        ...otherCostDefaults,
        leasingFee: 5360.0,
        startingFee: 0,
        depreciation: 33374.0,
        interest: 16200.0,
        insurance: 7706.0,
        trafficInsurance: 3059.0,
        maintenance: 6147.0,
        fuel: 4691.0,
        tyres: 4519.0,
        service: 5884.0,
        toll: 2467.0,
        parking: 18000.0,
      },
      30: {
        ...otherCostDefaults,
        leasingFee: 6118.0,
        startingFee: 0,
        depreciation: 39661.0,
        interest: 17760.0,
        insurance: 9889.0,
        trafficInsurance: 3059.0,
        maintenance: 7397.0,
        fuel: 7037.0,
        tyres: 6779.0,
        service: 8448.0,
        toll: 3700.0,
        parking: 18000.0,
      },
    }, // Leasing fee: Nissan Leaf 39 kWh N-Connecta hos Autolease + 2-tone pearl lakk + Pearlescent, NOK 321,550.00. 31.07.2023
    480: {
      10: {
        ...otherCostDefaults,
        leasingFee: 7629.0,
        startingFee: 0,
        depreciation: 34322.0,
        interest: 20160.0,
        insurance: 7897.0,
        trafficInsurance: 3059.0,
        maintenance: 5241.0,
        fuel: 2346.0,
        tyres: 2961.0,
        service: 3947.0,
        toll: 1233.0,
        parking: 18000.0,
      },
      15: {
        ...otherCostDefaults,
        leasingFee: 7865.0,
        startingFee: 0,
        depreciation: 44883.0,
        interest: 22140.0,
        insurance: 8597.0,
        trafficInsurance: 3059.0,
        maintenance: 5888.0,
        fuel: 3518.0,
        tyres: 4442.0,
        service: 5205.0,
        toll: 1850.0,
        parking: 18000.0,
      },
      20: {
        ...otherCostDefaults,
        leasingFee: 8128.0,
        startingFee: 0,
        depreciation: 45443.0,
        interest: 25380.0,
        insurance: 9556.0,
        trafficInsurance: 3059.0,
        maintenance: 6502.0,
        fuel: 4691.0,
        tyres: 5923.0,
        service: 6783.0,
        toll: 2467.0,
        parking: 18000.0,
      },
      30: {
        ...otherCostDefaults,
        leasingFee: 9175.0,
        startingFee: 0,
        depreciation: 59765.0,
        interest: 26640.0,
        insurance: 12076.0,
        trafficInsurance: 3059.0,
        maintenance: 7749.0,
        fuel: 7037.0,
        tyres: 8884.0,
        service: 9728.0,
        toll: 3700.0,
        parking: 18000.0,
      },
    }, // Leasing fee: Nissan Ariya Advance 63 kWt hos Autolease + alt utstyr bortsett fra Pearl lakk, NOK 481,700.00. 31.07.2023
    640: {
      10: {
        ...otherCostDefaults,
        leasingFee: 10405.0,
        startingFee: 0,
        depreciation: 45204.0,
        interest: 27120.0,
        insurance: 9181.0,
        trafficInsurance: 3059.0,
        maintenance: 5603.0,
        fuel: 2662.0,
        tyres: 3943.0,
        service: 4436.0,
        toll: 1233.0,
        parking: 18000.0,
      },
      15: {
        ...otherCostDefaults,
        leasingFee: 10610.0,
        startingFee: 0,
        depreciation: 59806.0,
        interest: 29520.0,
        insurance: 10046.0,
        trafficInsurance: 3059.0,
        maintenance: 6227.0,
        fuel: 3993.0,
        tyres: 5915.0,
        service: 5764.0,
        toll: 1850.0,
        parking: 18000.0,
      },
      20: {
        ...otherCostDefaults,
        leasingFee: 10986.0,
        startingFee: 0,
        depreciation: 59474.0,
        interest: 34080.0,
        insurance: 11161.0,
        trafficInsurance: 3059.0,
        maintenance: 6837.0,
        fuel: 5324.0,
        tyres: 7887.0,
        service: 7353.0,
        toll: 2467.0,
        parking: 18000.0,
      },
      30: {
        ...otherCostDefaults,
        leasingFee: 12354.0,
        startingFee: 0,
        depreciation: 76412.0,
        interest: 36000.0,
        insurance: 14151.0,
        trafficInsurance: 3059.0,
        maintenance: 8079.0,
        fuel: 7986.0,
        tyres: 11784.0,
        service: 10278.0,
        toll: 3700.0,
        parking: 18000.0,
      },
    }, // Leasing fee: Tesla Model 3 Performance Dual Motor AWD hos Autolease + Enhanced Autopilot + Full Self-Driving Capability, NOK 640,692.00. 31.07.2023
  },
  petrol: {
    320: {
      10: {
        ...otherCostDefaults,
        leasingFee: 4079.0,
        startingFee: 0,
        depreciation: 22554.0,
        interest: 13560.0,
        insurance: 6499.0,
        trafficInsurance: 3059.0,
        maintenance: 4937.0,
        fuel: 10890.0,
        tyres: 2260.0,
        service: 6737.0,
        toll: 2467.0,
        parking: 18000.0,
      },
      15: {
        ...otherCostDefaults,
        leasingFee: 4265.0,
        startingFee: 0,
        depreciation: 29831.0,
        interest: 14760.0,
        insurance: 7093.0,
        trafficInsurance: 3059.0,
        maintenance: 5536.0,
        fuel: 16335.0,
        tyres: 3390.0,
        service: 9484.0,
        toll: 3700.0,
        parking: 18000.0,
      },
      20: {
        ...otherCostDefaults,
        leasingFee: 4503.0,
        startingFee: 0,
        depreciation: 34441.0,
        interest: 15960.0,
        insurance: 7706.0,
        trafficInsurance: 3059.0,
        maintenance: 6147.0,
        fuel: 21780.0,
        tyres: 4519.0,
        service: 11611.0,
        toll: 4933.0,
        parking: 18000.0,
      },
      30: {
        ...otherCostDefaults,
        leasingFee: 5152.0,
        startingFee: 0,
        depreciation: 45261.0,
        interest: 16920.0,
        insurance: 9889.0,
        trafficInsurance: 3059.0,
        maintenance: 7397.0,
        fuel: 32670.0,
        tyres: 6779.0,
        service: 16810.0,
        toll: 7400.0,
        parking: 18000.0,
      },
    }, // Leasing fee: Ford Transit Courier hos Autolease + Førerassistentpakk + Tilhengerfeste, NOK 321,200.00. 31.07.2023
    480: {
      10: {
        ...otherCostDefaults,
        leasingFee: 6281.0,
        startingFee: 0,
        depreciation: 34001.0,
        interest: 20340.0,
        insurance: 7897.0,
        trafficInsurance: 3059.0,
        maintenance: 5241.0,
        fuel: 13504.0,
        tyres: 2692.0,
        service: 7693.0,
        toll: 2467.0,
        parking: 18000.0,
      },
      15: {
        ...otherCostDefaults,
        leasingFee: 6450.0,
        startingFee: 0,
        depreciation: 45002.0,
        interest: 22140.0,
        insurance: 8597.0,
        trafficInsurance: 3059.0,
        maintenance: 5888.0,
        fuel: 20255.0,
        tyres: 4039.0,
        service: 10193.0,
        toll: 3700.0,
        parking: 18000.0,
      },
      20: {
        ...otherCostDefaults,
        leasingFee: 6770.0,
        startingFee: 0,
        depreciation: 48003.0,
        interest: 24840.0,
        insurance: 9556.0,
        trafficInsurance: 3059.0,
        maintenance: 6502.0,
        fuel: 27007.0,
        tyres: 5384.0,
        service: 13340.0,
        toll: 4933.0,
        parking: 18000.0,
      },
      30: {
        ...otherCostDefaults,
        leasingFee: 7984.0,
        startingFee: 0,
        depreciation: 62404.0,
        interest: 26280.0,
        insurance: 12076.0,
        trafficInsurance: 3059.0,
        maintenance: 7749.0,
        fuel: 40511.0,
        tyres: 8076.0,
        service: 19295.0,
        toll: 7400.0,
        parking: 18000.0,
      },
    }, // Couldn't find any relevant leasing offers, thus "leasing fee" is estimated based on other vehicle categories. 31.07.2023
    640: {
      10: {
        ...otherCostDefaults,
        leasingFee: 8052.0,
        startingFee: 0,
        depreciation: 47003.0,
        interest: 26400.0,
        insurance: 9181.0,
        trafficInsurance: 3059.0,
        maintenance: 5603.0,
        fuel: 15246.0,
        tyres: 3285.0,
        service: 8670.0,
        toll: 2467.0,
        parking: 18000.0,
      },
      15: {
        ...otherCostDefaults,
        leasingFee: 8258.0,
        startingFee: 0,
        depreciation: 62505.0,
        interest: 28800.0,
        insurance: 10046.0,
        trafficInsurance: 3059.0,
        maintenance: 6227.0,
        fuel: 22869.0,
        tyres: 4928.0,
        service: 11312.0,
        toll: 3700.0,
        parking: 18000.0,
      },
      20: {
        ...otherCostDefaults,
        leasingFee: 8625.0,
        startingFee: 0,
        depreciation: 67340.0,
        interest: 32400.0,
        insurance: 11161.0,
        trafficInsurance: 3059.0,
        maintenance: 6837.0,
        fuel: 30492.0,
        tyres: 6571.0,
        service: 14552.0,
        toll: 4933.0,
        parking: 18000.0,
      },
      30: {
        ...otherCostDefaults,
        leasingFee: 10043.0,
        startingFee: 0,
        depreciation: 88210.0,
        interest: 34320.0,
        insurance: 14151.0,
        trafficInsurance: 3059.0,
        maintenance: 8079.0,
        fuel: 45738.0,
        tyres: 9856.0,
        service: 20626.0,
        toll: 7400.0,
        parking: 18000.0,
      },
    }, // Couldn't find any relevant leasing offers, thus "leasing fee" is estimated based on other vehicle categories. 31.07.2023
  },
  diesel: {
    320: {
      10: {
        ...otherCostDefaults,
        leasingFee: 4040.0,
        startingFee: 0,
        depreciation: 23354.0,
        interest: 13200.0,
        insurance: 6499.0,
        trafficInsurance: 3059.0,
        maintenance: 4937.0,
        fuel: 10426.0,
        tyres: 2260.0,
        service: 6737.0,
        toll: 2812.0,
        parking: 18000.0,
      },
      15: {
        ...otherCostDefaults,
        leasingFee: 4230.0,
        startingFee: 0,
        depreciation: 31031.0,
        interest: 14400.0,
        insurance: 7093.0,
        trafficInsurance: 3059.0,
        maintenance: 5536.0,
        fuel: 15638.0,
        tyres: 3390.0,
        service: 9484.0,
        toll: 4218.0,
        parking: 18000.0,
      },
      20: {
        ...otherCostDefaults,
        leasingFee: 4471.0,
        startingFee: 0,
        depreciation: 34974.0,
        interest: 15840.0,
        insurance: 7706.0,
        trafficInsurance: 3059.0,
        maintenance: 6147.0,
        fuel: 20851.0,
        tyres: 4519.0,
        service: 11611.0,
        toll: 5624.0,
        parking: 18000.0,
      },
      30: {
        ...otherCostDefaults,
        leasingFee: 5137.0,
        startingFee: 0,
        depreciation: 44461.0,
        interest: 17040.0,
        insurance: 9889.0,
        trafficInsurance: 3059.0,
        maintenance: 7397.0,
        fuel: 31277.0,
        tyres: 6779.0,
        service: 16810.0,
        toll: 8436.0,
        parking: 18000.0,
      },
    }, // Leasing fee: Ford Transit Courier 1.5 EcoBlue 100HP Trend + Førerassistentpakke, NOK 323,500.00. 31.07.2023
    480: {
      10: {
        ...otherCostDefaults,
        leasingFee: 6281.0,
        startingFee: 0,
        depreciation: 35201.0,
        interest: 19800.0,
        insurance: 7897.0,
        trafficInsurance: 3059.0,
        maintenance: 5241.0,
        fuel: 11077.0,
        tyres: 2692.0,
        service: 7693.0,
        toll: 2812.0,
        parking: 18000.0,
      },
      15: {
        ...otherCostDefaults,
        leasingFee: 6450.0,
        startingFee: 0,
        depreciation: 46802.0,
        interest: 21600.0,
        insurance: 8597.0,
        trafficInsurance: 3059.0,
        maintenance: 5888.0,
        fuel: 16616.0,
        tyres: 4039.0,
        service: 10193.0,
        toll: 4218.0,
        parking: 18000.0,
      },
      20: {
        ...otherCostDefaults,
        leasingFee: 6770.0,
        startingFee: 0,
        depreciation: 48803.0,
        interest: 24660.0,
        insurance: 9556.0,
        trafficInsurance: 3059.0,
        maintenance: 6502.0,
        fuel: 22154.0,
        tyres: 5384.0,
        service: 13340.0,
        toll: 5624.0,
        parking: 18000.0,
      },
      30: {
        ...otherCostDefaults,
        leasingFee: 7984.0,
        startingFee: 0,
        depreciation: 61204.0,
        interest: 26460.0,
        insurance: 12076.0,
        trafficInsurance: 3059.0,
        maintenance: 7749.0,
        fuel: 33232.0,
        tyres: 8076.0,
        service: 19295.0,
        toll: 8436.0,
        parking: 18000.0,
      },
    }, // Leasing fee: Skoda Octavia 2,0 TDI 150 Hk DSG Style hos Autolease, NOK 505,235.00. 31.07.2023
    640: {
      10: {
        ...otherCostDefaults,
        leasingFee: 8052.0,
        startingFee: 0,
        depreciation: 47003.0,
        interest: 26400.0,
        insurance: 9181.0,
        trafficInsurance: 3059.0,
        maintenance: 5603.0,
        fuel: 11946.0,
        tyres: 3285.0,
        service: 8670.0,
        toll: 2812.0,
        parking: 18000.0,
      },
      15: {
        ...otherCostDefaults,
        leasingFee: 8258.0,
        startingFee: 0,
        depreciation: 62505.0,
        interest: 28800.0,
        insurance: 10046.0,
        trafficInsurance: 3059.0,
        maintenance: 6227.0,
        fuel: 17919.0,
        tyres: 4928.0,
        service: 11312.0,
        toll: 4218.0,
        parking: 18000.0,
      },
      20: {
        ...otherCostDefaults,
        leasingFee: 8625.0,
        startingFee: 0,
        depreciation: 65206.0,
        interest: 32880.0,
        insurance: 11161.0,
        trafficInsurance: 3059.0,
        maintenance: 6837.0,
        fuel: 23892.0,
        tyres: 6571.0,
        service: 14552.0,
        toll: 5624.0,
        parking: 18000.0,
      },
      30: {
        ...otherCostDefaults,
        leasingFee: 10043.0,
        startingFee: 0,
        depreciation: 81810.0,
        interest: 35280.0,
        insurance: 14151.0,
        trafficInsurance: 3059.0,
        maintenance: 8079.0,
        fuel: 35838.0,
        tyres: 9856.0,
        service: 20626.0,
        toll: 8436.0,
        parking: 18000.0,
      },
    }, // Leasing fee: Skoda Octavia 2,0 TDI 150 Hk DSG Style 4WD hos Autolease + Alarm + Ergonomiske seter + soltak, NOK 641,302.00. 31.07.2023
  },
  hybrid: {
    320: {
      10: {
        ...otherCostDefaults,
        leasingFee: 3507.0,
        startingFee: 0,
        depreciation: 23354.0,
        interest: 13200.0,
        insurance: 6499.0,
        trafficInsurance: 3059.0,
        maintenance: 4937.0,
        fuel: 6088.0,
        tyres: 2260.0,
        service: 6737.0,
        toll: 2467.0,
        parking: 18000.0,
      },
      15: {
        ...otherCostDefaults,
        leasingFee: 3769.0,
        startingFee: 0,
        depreciation: 31031.0,
        interest: 14400.0,
        insurance: 7093.0,
        trafficInsurance: 3059.0,
        maintenance: 5536.0,
        fuel: 9132.0,
        tyres: 3390.0,
        service: 9484.0,
        toll: 3700.0,
        parking: 18000.0,
      },
      20: {
        ...otherCostDefaults,
        leasingFee: 4067.0,
        startingFee: 0,
        depreciation: 34441.0,
        interest: 15960.0,
        insurance: 7706.0,
        trafficInsurance: 3059.0,
        maintenance: 6147.0,
        fuel: 12175.0,
        tyres: 4519.0,
        service: 11611.0,
        toll: 4933.0,
        parking: 18000.0,
      },
      30: {
        ...otherCostDefaults,
        leasingFee: 4912.0,
        startingFee: 0,
        depreciation: 43661.0,
        interest: 17160.0,
        insurance: 9889.0,
        trafficInsurance: 3059.0,
        maintenance: 7397.0,
        fuel: 18264.0,
        tyres: 6779.0,
        service: 16810.0,
        toll: 7400.0,
        parking: 18000.0,
      },
    }, // Leasing fee: Toyota Yaris 1.5 Bensin Hybrid Auto Active hos Autolease, NOK 322,700.00. 31.07.2023
    480: {
      10: {
        ...otherCostDefaults,
        leasingFee: 5191.0,
        startingFee: 0,
        depreciation: 35201.0,
        interest: 19800.0,
        insurance: 7897.0,
        trafficInsurance: 3059.0,
        maintenance: 5241.0,
        fuel: 5840.0,
        tyres: 2692.0,
        service: 7693.0,
        toll: 2467.0,
        parking: 18000.0,
      },
      15: {
        ...otherCostDefaults,
        leasingFee: 5524.0,
        startingFee: 0,
        depreciation: 46802.0,
        interest: 21600.0,
        insurance: 8597.0,
        trafficInsurance: 3059.0,
        maintenance: 5888.0,
        fuel: 8760.0,
        tyres: 4039.0,
        service: 10193.0,
        toll: 3700.0,
        parking: 18000.0,
      },
      20: {
        ...otherCostDefaults,
        leasingFee: 5883.0,
        startingFee: 0,
        depreciation: 50403.0,
        interest: 24300.0,
        insurance: 9556.0,
        trafficInsurance: 3059.0,
        maintenance: 6502.0,
        fuel: 11681.0,
        tyres: 5384.0,
        service: 13340.0,
        toll: 4933.0,
        parking: 18000.0,
      },
      30: {
        ...otherCostDefaults,
        leasingFee: 7066.0,
        startingFee: 0,
        depreciation: 63604.0,
        interest: 26100.0,
        insurance: 12076.0,
        trafficInsurance: 3059.0,
        maintenance: 7749.0,
        fuel: 17520.0,
        tyres: 8076.0,
        service: 19295.0,
        toll: 7400.0,
        parking: 18000.0,
      },
    }, // Leasing fee: Toyota Corolla 1.8 HV e-CVT TS Active Tech Pearl White pearl hos Autolease, NOK 477,300.00. 31.07.2023
    640: {
      10: {
        ...otherCostDefaults,
        leasingFee: 6800.0,
        startingFee: 0,
        depreciation: 47003.0,
        interest: 26400.0,
        insurance: 9181.0,
        trafficInsurance: 3059.0,
        maintenance: 5603.0,
        fuel: 4668.0,
        tyres: 3285.0,
        service: 8670.0,
        toll: 2467.0,
        parking: 18000.0,
      },
      15: {
        ...otherCostDefaults,
        leasingFee: 7200.0,
        startingFee: 0,
        depreciation: 62505.0,
        interest: 28800.0,
        insurance: 10046.0,
        trafficInsurance: 3059.0,
        maintenance: 6227.0,
        fuel: 6986.0,
        tyres: 4928.0,
        service: 11312.0,
        toll: 3700.0,
        parking: 18000.0,
      },
      20: {
        ...otherCostDefaults,
        leasingFee: 7600.0,
        startingFee: 0,
        depreciation: 67340.0,
        interest: 32400.0,
        insurance: 11161.0,
        trafficInsurance: 3059.0,
        maintenance: 6837.0,
        fuel: 9316.0,
        tyres: 6571.0,
        service: 17552.0,
        toll: 4933.0,
        parking: 18000.0,
      },
      30: {
        ...otherCostDefaults,
        leasingFee: 8400.0,
        startingFee: 0,
        depreciation: 85010.0,
        interest: 34800.0,
        insurance: 14151.0,
        trafficInsurance: 3059.0,
        maintenance: 8079.0,
        fuel: 13972.0,
        tyres: 9856.0,
        service: 20626.0,
        toll: 7400.0,
        parking: 18000.0,
      },
    }, // Couldn't find any relevant leasing offers, thus "leasing fee" is estimated based on other vehicle categories. 31.07.2023
  },
};

export const costInput = {
  leasingFee: { min: 0, max: 20000, step: 1 },
  startingFee: { min: 0, max: 100000, step: 1 },
  wearFee: { min: 0, max: 40000, step: 1 },
  numberOfMonths: { min: 0, max: 120, step: 1 },
  insurance: { min: 0, max: 40000, step: 1 },
  trafficInsurance: { min: 0, max: 10000, step: 1 },
  service: { min: 0, max: 40000, step: 1 },
  maintenance: { min: 0, max: 40000, step: 1 },
  tyres: { min: 0, max: 20000, step: 1 },
  tyreStorage: { min: 0, max: 20000, step: 1 },
  tyreChange: { min: 0, max: 10000, step: 1 },
  parking: { min: 0, max: 50000, step: 1 },
  routeTracker: { min: 0, max: 20000, step: 1 },
};

export const costVATRate = {
  leasingFee: 1.25,
  startingFee: 1.25,
  wearFee: 1.25,
  numberOfMonths: 1.0,
  insurance: 1.0,
  trafficInsurance: 1.0,
  service: 1.25,
  maintenance: 1.25,
  tyres: 1.25,
  tyreStorage: 1.25,
  tyreChange: 1.25,
  parking: 1.25,
  routeTracker: 1.25,
  damageCostPerKm: 1.25,
};

// Costs originally from autolease are excluded VAT
// Hyre costs are excluded VAT
// OFV costs are including VAT
export const sourceOfDefaultCost = {
  leasingFee: "Hyre", //Originally from autolease.no, but displayed as an Hyre estimate
  startingFee: "Hyre", //Originally from autolease.no, but displayed as an Hyre estimate
  wearFee: "Hyre",
  numberOfMonths: "",
  insurance: "OFV",
  trafficInsurance: "OFV",
  service: "OFV",
  maintenance: "OFV",
  tyres: "OFV",
  tyreStorage: "Hyre", //Originally from autolease.no, but displayed as an Hyre estimate
  tyreChange: "Hyre",
  parking: "Hyre",
  routeTracker: "Hyre", //Originally from autolease.no, but displayed as an Hyre estimate
};

export const adminTitles = {
  adminHours: "Administrasjons\xadtimer per uke",
  adminHourlyCost: "Interntimepris",
  workWeeksInYear: "Arbeidsuker i året",
};

export const adminTooltips = {
  adminHours:
    "Gjennomsnittlig antall timer brukt på biladministrasjon, for eksempel kjøre bil til service og dekkskift, vaske bilen, lese av kilometerstand, spore opp nøkkel, og så videre.",
  adminHourlyCost:
    "Interntimepris til å beregne internkostnaden av biladministrasjonen.",
  workWeeksInYear:
    "Gjennomsnittlig arbeidsuker i et år (brukes for å beregne årlige administrasjonskostnader).",
};

export const adminInput = {
  adminHours: { min: 0, max: 10, step: 0.1 },
  adminHourlyCost: { min: 0, max: 5000, step: 10 },
  workWeeksInYear: { min: 0, max: 53, step: 1 },
};

export const adminDefaults = {
  adminHours: 1.5,
  adminHourlyCost: 450,
  workWeeksInYear: 43,
};

export const mobilityPlanOptions = {
  category: {
    title: "Velg\xa0mobilitetsplan",
    options: {
      compactGoElectric: "Go\xa0Electric:\xa0Compact",
      largeGoElectric: "Go\xa0Electric:\xa0Large",
      compact: "Compact",
      large: "Large",
      premium: "Premium",
      van: "Van",
    },
  },
};

export const mobilityPlanDefaults = {
  category: "largeGoElectric",
};

export const mobilityPlanToCarCategory = {
  compactGoElectric: "electric",
  largeGoElectric: "electric",
  compact: "petrol",
  large: "petrol",
  premium: "petrol",
  van: "diesel",
};

export const mobilityPlanPrices = {
  compactGoElectric: { amount: 6490, includingVAT: false },
  largeGoElectric: { amount: 7490, includingVAT: false },
  compact: { amount: 7490, includingVAT: true },
  large: { amount: 8490, includingVAT: true },
  premium: { amount: 13990, includingVAT: true },
  van: { amount: 8990, includingVAT: false },
};

export const damageCostPerKm = 0.23;

const norwegianKgCO2ePerKwh = 0.011; // https://www.nve.no/energi/energisystem/kraftproduksjon/hvor-kommer-strommen-fra/

export const KgCO2ePerCategory = {
  electric: {
    perKm: (norwegianKgCO2ePerKwh * 14.3) / 100, //https://www.sciencedirect.com/science/article/pii/S1364032122000867
    fromProduction: 11000, // https://www.sciencedirect.com/science/article/pii/S1364032122000867
    fromRecycling: -6000, // https://www.sciencedirect.com/science/article/pii/S1364032122000867
  },
  petrol: {
    perKm: (2.83 * 7.2) / 100, // https://www.sciencedirect.com/science/article/pii/S1364032122000867
    fromProduction: 8000, // https://www.sciencedirect.com/science/article/pii/S1364032122000867
    fromRecycling: -4000, // https://www.sciencedirect.com/science/article/pii/S1364032122000867
  },
  diesel: {
    perKm: (3.18 * 4.9) / 100, // https://www.sciencedirect.com/science/article/pii/S1364032122000867
    fromProduction: 8000, // https://www.sciencedirect.com/science/article/pii/S1364032122000867
    fromRecycling: -4000, // https://www.sciencedirect.com/science/article/pii/S1364032122000867
  },
  hybrid: {
    perKm: (2.83 * 1.4) / 100 + (norwegianKgCO2ePerKwh * 11.5) / 100, // https://www.sciencedirect.com/science/article/pii/S1364032122000867
    fromProduction: 9000, // https://www.sciencedirect.com/science/article/pii/S1364032122000867
    fromRecycling: -5000, // https://www.sciencedirect.com/science/article/pii/S1364032122000867
  },
};
