"use client";
import React from "react";
import { Col, Container, Row } from "reactstrap";

import Image from "next/image";

import aboutUsStyles from "./about_us.module.css";

import phoneIcon from "../../../public/icons/phone.png";
import sameieBlue from "../../../public/icons/sameie-blue.svg";
import familiebilTesla from "../../../public/icons/familiebil-el_tesla.svg";
import demographics from "../../../public/icons/demographics.svg";

export default function DkAboutUs() {
  return (
    <div>
      <div
        className={`${aboutUsStyles.aboutUsBanner} dark-bottom-gradient-overlay"fat-header `}
      >
        <div className={aboutUsStyles.spacer} />
      </div>

      <Container>
        <h1 className="thin-header text-start" style={{ marginBottom: "20px" }}>
          Om Hyre
        </h1>
        <p>
          {" "}
          Hyre blev lanceret i 2017 og er nu en af de største
          udlejningsvirksomheder af biler i Norden med mere end 1500
          tilgængelige biler på platformen. Vi er et joint venture mellem{" "}
          <a
            href={"https://moller.no"}
            rel="noopener noreferrer"
            target="_blank"
          >
            Møller Mobility Group
          </a>{" "}
          og Hyre Management, med MMG som største aktionær.
        </p>

        <h3>En hurtig og ukompliceret kunderejse</h3>
        <p>
          Med bare en enkelt app kan du booke, låse bilen op og afregne din
          billeje – uden nogensinde at skulle stå i kø for at hente din bil.
          Bro/vejafgift, brændstof og lejepriser beregnes automatisk i realtid,
          så du altid har det fulde overblik og sparer tid på administration og
          betaling.
        </p>

        <p>
          Vores biler er altid tilgængelige, og vores mission er, aldrig at være
          mere end fem minutter væk fra der, hvor du er. Flåden af biler er ejet
          og drives af Hyre, hvilket giver os komplet kontrol over kunderejsen
          og en sikker adgang til moderne biler til konkurrencedygtige priser.
        </p>

        <h3>Passion for teknologi</h3>
        <p>
          Vi elsker teknologi og de muligheder, den giver for at skabe
          fantastiske kundeoplevelser og for at udfordre status quo. De
          forskellige platforme er skabt og udviklet af vores dedikerede
          medarbejdere. Det er dem, der giver os mulighed for at bevæge os
          hurtigere og mere smidigt end nogen andre. I dag tæller vi 40
          entusiastiske tekniske medarbejdere på tværs af Norge, Sverige og
          Danmark.
        </p>

        <h3>
          Vi tror på en fremtid med selvkørende, elektriske lejebiler, fordi:
        </h3>

        <Container style={{ marginTop: "50px" }}>
          <Row style={{ marginBottom: "50px" }}>
            <div className="row">
              <div className="col-12 col-md-4 d-flex flex-column">
                <Image
                  src={familiebilTesla}
                  alt=""
                  height={100}
                  width={180}
                  className="align-self-start"
                  style={{ objectFit: "contain" }}
                />
                <h3>Selve bilen ændrer sig.</h3>
                <p>
                  Biler er nu forbundet til internettet, hvilket giver mulighed
                  for en mere effektiv udlejning af biler. Derudover er bilen nu
                  elektrisk, og bliver med tiden selvstyrende.
                </p>
              </div>

              <div className="col-12 col-md-4 d-flex flex-column">
                <Image
                  src={demographics}
                  alt=""
                  height={100}
                  width={180}
                  className="align-self-start"
                  style={{ objectFit: "contain" }}
                />
                <h3>Demografiske karakteristika ændrer sig.</h3>
                <p>
                  Bilen er ikke længere et vigtigt statussymbol, og færre og
                  færre ønsker at eje en bil. De vil have enkelhed,
                  fleksibilitet og frihed.
                </p>
              </div>

              <div className="col-12 col-md-4 d-flex flex-column">
                <Image
                  src={sameieBlue}
                  alt=""
                  height={100}
                  width={150}
                  className="align-self-start"
                  style={{ objectFit: "contain" }}
                />
                <h3>Bæredygtighed er et krav.</h3>
                <p>
                  Den måde, vi kommer omkring på i dag, er ikke bæredygtig. Selv
                  med elektriske biler vil den stigende befolkningstæthed i de
                  største byer kræve alternative løsninger til persontransport.
                </p>
              </div>
            </div>
          </Row>
        </Container>

        <h2
          className="thin-header"
          style={{ margin: "20px 0 60px", textAlign: "left" }}
        >
          Kontakt os!
        </h2>
        <address style={{ marginBottom: "150px" }}>
          <Row>
            <Col sm="4">
              <p>
                <strong>Hyre ApS</strong>
                <br />
                CVR-nummer: 42991481 <br />
                Danneskiold-Samsøes Allé 41, 1434 København
              </p>
            </Col>

            <Col sm="4">
              <p className={aboutUsStyles.contactList}>
                <span
                  style={{
                    width: "26px",
                    fontSize: "38px",
                    lineHeight: "26px",
                    textAlign: "center",
                    color: "black",
                    fontWeight: 900,
                    display: "inline-block",
                    verticalAlign: "middle",
                    margin: "0 30px 0 0",
                  }}
                >
                  @
                </span>
                <a href="mailto:hej@hyre.dk">hej@hyre.dk</a>
                <br />
                <br />
                <Image
                  src={phoneIcon}
                  width={26}
                  height={26}
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    margin: "0 30px 0 0",
                  }}
                  alt="phone icon"
                />
                +45 52 51 26 99
              </p>
            </Col>
          </Row>
        </address>
      </Container>
    </div>
  );
}
