"use client";
import React from "react";
import { Col, Container, Row } from "reactstrap";

import Link from "next/link";
import Image from "next/image";

import chevronRight from "../../../../public/images/chevron-right.svg";

class BrukerPage extends React.Component {
  render() {
    return (
      <div>
        {/* <Helmet title="Bedriftsportalen - Hyre for Bedrift" /> */}

        <Container
          className="text-center text-md-start"
          style={{ marginTop: "100px", paddingTop: "70px" }}
        >
          <Link href={`/bedrift/`}>
            Hyre for Bedrift
            <Image src={chevronRight} style={{ width: 24, margin: 0 }} alt="" />
          </Link>
          <Link href={`/bedrift/kom-i-gang`}>
            Kom i gang
            <Image src={chevronRight} style={{ width: 24, margin: 0 }} alt="" />
          </Link>
          <Link href={`/bedrift/hjelp/`}>Hjelp</Link>
          <hr></hr>
          <Row style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <Col xs="12" md="12">
              <h1 className="big-fat-header">Hyre for Bedrift - Hjelp</h1>
              <p>
                Her kan du som administrator for din bedrift få en innføring og
                gjennomgang av ulike funksjoner i bedriftsportalen og svar på
                vanlige spørsmål.
              </p>
            </Col>
          </Row>
        </Container>
        <Container className="text-md-start" style={{ paddingBottom: "50px" }}>
          <Row style={{ paddingTop: "10px", paddingBottom: "20px" }}>
            <Col xs="12" md="12" lg="6">
              <p>
                <strong>Administrasjon</strong>
              </p>
              <li>
                <Link href={`/bedrift/hjelp/bestille`}>
                  Bestille bil på desktop
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
              </li>
              <li>
                <Link href={`/bedrift/hjelp/azuread`}>
                  Azure AD Sync
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
              </li>
              <li>
                <Link href={`/bedrift/hjelp/kostnadskoder`}>
                  Sette opp kostnadskoder
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
              </li>
              <li>
                <Link href={`/bedrift/hjelp/rapporter`}>
                  Hent ut rapporter
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
              </li>
              <li>
                <Link href={`/bedrift/hjelp/leieforhold`}>
                  Se aktive og tidligere leieforhold
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
              </li>
              <li>
                <Link href={`/bedrift/hjelp/medlem`}>
                  Legge til medlemmer
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
              </li>
            </Col>
          </Row>
          <Row style={{ paddingTop: "10px", paddingBottom: "20px" }}>
            <Col xs="12" md="12" lg="6">
              <p>
                <strong>Bruker</strong>
              </p>
              <li>
                <Link href={`/bedrift/hjelp/easypark`}>
                  Parker med EasyPark
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
              </li>
              <li>
                <Link href={`/bedrift/hjelp/drivstoff`}>
                  Fyll drivstoff og start lading i appen
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
              </li>
              <li>
                <Link href={`/bedrift/hjelp/ekstrasjafor`}>
                  Legg til ekstra sjåfør
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
              </li>
              <li>
                <Link href={`/bedrift/hjelp/meldskade`}>
                  Meld skade i appen
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
              </li>
              <li>
                <Link href={`/bedrift/hjelp/utvidleie`}>
                  Utvid leieforhold
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
              </li>
              <li>
                <Link href={`/bedrift/hjelp/gjenglemt`}>
                  Gjenglemte ting
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
              </li>
            </Col>
          </Row>
          <Row style={{ paddingTop: "10px", paddingBottom: "20px" }}>
            <Col xs="12" md="12">
              <p>
                <strong>Regnskap og økonomi</strong>
              </p>
              <li>
                <Link href={`/bedrift/hjelp/fakturaavtale`}>
                  Sett opp fakturaavtale
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
              </li>
            </Col>
          </Row>
          <Row style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <Col xs="12" md="12">
              <p>
                <strong>Juridisk informasjon </strong>
              </p>
              <li>
                <Link href={`/vilkår/personvernerklæring/`}>
                  GDPR / personvern
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
              </li>
              <li>
                <Link href={`/vilkår/forsikring/`}>
                  Link til forsikringsavtale
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
              </li>
            </Col>
          </Row>
        </Container>
        <div className="muted-background">
          <Container
            className="text-center text-md-start"
            style={{
              marginTop: "20px",
              paddingTop: "50px",
              paddingBottom: "40px",
            }}
          >
            <Row>
              <Col xs="12" md="6">
                <div className="p-1">
                  <Link
                    href={`/bedrift/registrer`}
                    style={{ fontSize: "1em", fontWeight: 500 }}
                  >
                    Steg 1: Registrer din bedrift
                    <Image
                      src={chevronRight}
                      style={{ width: 24, margin: 0 }}
                      alt=""
                    />
                  </Link>
                  <hr></hr>
                </div>
                <div className="p-1">
                  <Link
                    href={`/bedrift/betaling`}
                    style={{ fontSize: "1em", fontWeight: 500 }}
                  >
                    Steg 2: Sett opp betaling
                    <Image
                      src={chevronRight}
                      style={{ width: 24, margin: 0 }}
                      alt=""
                    />
                  </Link>
                  <hr></hr>
                </div>
              </Col>
              <Col xs="12" md="6">
                <div className="p-1">
                  <Link
                    href={`/bedrift/lansering`}
                    style={{ fontSize: "1em", fontWeight: 500 }}
                  >
                    Steg 3: Planlegg lansering
                    <Image
                      src={chevronRight}
                      style={{ width: 24, margin: 0 }}
                      alt=""
                    />
                  </Link>
                  <hr></hr>
                </div>
                <div className="p-1">
                  <Link
                    href={`/bedrift/medlemmer`}
                    style={{ fontSize: "1em", fontWeight: 500 }}
                  >
                    Steg 4: Inviter medlemmer
                    <Image
                      src={chevronRight}
                      style={{ width: 24, margin: 0 }}
                      alt=""
                    />
                  </Link>
                  <hr></hr>
                </div>
                <div className="p-1">
                  <Link
                    href={`/bedrift/hjelp`}
                    style={{ fontSize: "1em", fontWeight: 500 }}
                  >
                    Hjelp
                    <Image
                      src={chevronRight}
                      style={{ width: 24, margin: 0 }}
                      alt=""
                    />
                  </Link>
                  <hr></hr>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default BrukerPage;
