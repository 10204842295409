"use client";
import React from "react";
import { Col, Container, Row } from "reactstrap";

import Link from "next/link";
import Image from "next/image";

import chevronRight from "../../../../public/images/chevron-right.svg";
import easypark from "../../../../public/images/b2b/easyparkapp.png";

class BrukerPage extends React.Component {
  render() {
    return (
      <div>
        {/* <Helmet title="EasyPark - Hyre for Bedrift" /> */}
        <Container
          className="text-center text-md-start"
          style={{
            marginTop: "100px",
            paddingTop: "70px",
            paddingBottom: "50px",
          }}
        >
          <Link href={`/bedrift/hjelp/`}>
            Hjelp Bedrift
            <Image src={chevronRight} style={{ width: 24, margin: 0 }} alt="" />
          </Link>
          <Link href={`/bedrift/hjelp/easypark`}>Parker med EasyPark</Link>
          <hr></hr>
          <Row>
            <Col
              className="d-flex justify-content-center"
              xs="12"
              md="12"
              lg="6"
            >
              <p style={{ paddingTop: "10px" }}>
                <h1 className="big-fat-header">Parker med EasyPark</h1>
                Med EasyPark integrert i appen kan du starte parkering uten
                ekstra utlegg. Når du kjører ut av takstgruppen vil betalingen
                avsluttes automatisk.
                <br></br>
                <br></br>
                Klikk på &quot;Betaling&quot; til venstre i vinduet. Gå til den
                nederste firkanten markert med &quot;Kostnadsnøkler&quot; og
                velg &quot;Endre&quot;.
              </p>
            </Col>
            <Col
              className="d-flex align-items-center justify-content-center"
              xs="12"
              md="12"
              lg="6"
            >
              <Image src={easypark} alt="" className="align-self-center" />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default BrukerPage;
