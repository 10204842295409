"use client";
import { Block, ExtractValueFromType } from "@/types";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useState } from "react";
import RichText from "../components/RichText";
import Image from "next/image";

import en from "../../public/images/background-numbers/1.png";
import to from "../../public/images/background-numbers/2.png";
import tre from "../../public/images/background-numbers/3.png";
import fire from "../../public/images/background-numbers/4.png";
import fem from "../../public/images/background-numbers/5.png";
import seks from "../../public/images/background-numbers/6.png";
import syv from "../../public/images/background-numbers/7.png";
import atte from "../../public/images/background-numbers/8.png";
import ni from "../../public/images/background-numbers/9.png";
import ti from "../../public/images/background-numbers/10.png";
import elleve from "../../public/images/background-numbers/11.png";
import { Col, Container, Row } from "reactstrap";

type FancyNumberedListValue = ExtractValueFromType<
  Block,
  "fancy_numbered_list_block"
>;

export const FancyNumberedListBlock = ({ items }: FancyNumberedListValue) => {
  const numberImageBackgrounds = [
    en,
    to,
    tre,
    fire,
    fem,
    seks,
    syv,
    atte,
    ni,
    ti,
    elleve,
  ];
  return (
    <Container className="figcon text-center pt-5 pt-xl-1">
      {items.map((item, i) => (
        <Row
          key={item.title}
          className={(i & 1 ? "flex-row-reverse" : "") + " mb-5 mb-lg-2"}
        >
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="12"
            md="12"
            lg="6"
          >
            <div style={{ position: "relative", minHeight: 256 }}>
              <Image
                src={numberImageBackgrounds[i]}
                className="img-fluid"
                style={{
                  position: "absolute",
                  top: -60,
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
                alt={(i + 1).toString()}
              />
              <div className="mx-4 text-start">
                <h2 className="fat-header text-center">
                  <RichText text={item.title} />
                </h2>
                <p className="text-justify">
                  <RichText text={item.text} />
                </p>
              </div>
            </div>
          </Col>
          <Col
            className="d-flex align-items-start align-items-center-md justify-content-center"
            xs="12"
            md="12"
            lg="6"
            style={{ position: "relative" }}
          >
            <figure className="figure2">
              <Image
                src={item.image.url}
                alt="bank id"
                sizes="100vw"
                style={{
                  width: "100%",
                  height: "auto",
                }}
                width={300}
                height={600}
              />
            </figure>
          </Col>
        </Row>
      ))}
    </Container>
  );
};
