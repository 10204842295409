"use client";
import React from "react";
import { Col, Container, Row } from "reactstrap";

import Link from "next/link";
import Image from "next/image";

import buttonStyles from "@/styles/buttons.module.css";
import aboutUsStyles from "./about_us.module.css";

import feature5minutter from "../../../public/images/app-screenshots/no/feature-5minutter.jpg";
import featureBedrift from "../../../public/images/app-screenshots/no/feature-bedrift.jpg";
import fleetScreenshot1 from "../../../public/images/app-screenshots/no/desktop-fleet.png";
import fleetScreenshot2 from "../../../public/images/app-screenshots/no/desktop-fleet2.png";
import carImage from "../../../public/images/articles/56-DSC04094.jpg";
import aws from "../../../public/images/logos/technologies/aws.png";
import django from "../../../public/images/logos/technologies/django.png";
import github from "../../../public/images/logos/technologies/github.png";
import kotlin from "../../../public/images/logos/technologies/kotlin.png";
import mypy from "../../../public/images/logos/technologies/mypy.png";
import postgres from "../../../public/images/logos/technologies/postgres.png";
import python from "../../../public/images/logos/technologies/python.png";
import reactNative from "../../../public/images/logos/technologies/react-native.png";
import react from "../../../public/images/logos/technologies/react.png";
import slack from "../../../public/images/logos/technologies/slack.png";
import swift from "../../../public/images/logos/technologies/swift.png";
import terraform from "../../../public/images/logos/technologies/terraform.png";
import typescript from "../../../public/images/logos/technologies/typescript.png";
import zulip from "../../../public/images/logos/technologies/zulip.png";

export default function AboutUsTechnology() {
  return (
    <div className={aboutUsStyles.technologyPage}>
      <Container style={{ marginBottom: "20px", marginTop: "120px" }}>
        <div className="row align-items-center">
          <div className="col-12 col-md-7 d-flex flex-column">
            <h3>Utviklingsteamet</h3>
            <h1 className="big-fat-header" style={{ color: "#1c5dff" }}>
              Slik bygger vi den aller beste bildelingstjenesten
            </h1>
          </div>
          <div className="col-12 col-md-5 d-flex flex-column">
            <Image
              src={carImage}
              alt="picture of blue hyre car"
              className="align-self-center"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </div>
        <p>
          Vi startet Hyre med en idé om at det skulle være like enkelt, smidig
          og raskt å dele bil som å eie egen. Vår idé var at vi skulle bruke
          teknologi til å oppnå dette. Siden starten har vi i Hyre ønsket å løse
          problemene vi møter med teknologi.
        </p>
        <p>
          Derfor er det utviklingsavdelingen i Hyre som er selve forretningen,
          og motoren som gir oss konkurransefortrinn, heller enn å stå i skyggen
          av andre områder.
        </p>
        <p>
          Mobilitet er en bransje som nå er i stor endring. Digitaliseringen
          skjer nå. Bli med å skape morgendagens mobilitet. Bli med å gjøre den
          brukervennlig, smart og miljøvennlig.
        </p>
        <p>
          Med et lite utviklingsteam har vi bygget en infrastruktur med mange
          systemer. De fleste av dem er egenutviklet, og når det trengs
          skreddersyr vi løsninger som ingen andre har laget for våre behov.
        </p>
      </Container>

      <div className="muted-background">
        <Container
          style={{
            marginBottom: "20px",
            marginTop: "80px",
            paddingTop: "100px",
          }}
        >
          <h2 className="big-fat-header" style={{ color: "#1c5dff" }}>
            Vi bygger og drifter en bildelingstjeneste med teknologi
          </h2>
          <p>
            Hyre er fortsatt et relativt lite selskap med en relativt liten
            utviklingsavdeling – så alle må gjøre litt av alt. Våre utviklere
            bygger helt nye muligheter for både kunder, ansatte og leverandører,
            og sørger for at vi ikke mister de vi allerede har bygget. Det betyr
            å bygge og vedlikeholde frontend- og backendsystemer, å bidra med
            analyser og å orkestrere infrastruktur og prosesser.
          </p>
        </Container>

        <Container style={{ marginBottom: "20px", marginTop: "50px" }}>
          <h2>Hvordan vi jobber</h2>
          <p>
            I Hyre har vi stor tillit til utviklingsavdelingen vår! Dermed
            forsøker vi å legge opp arbeidsdagen på en måte som gir muligheter
            til å løse problemer, og til å bygge løsninger.
          </p>
          <h3>Sprinter</h3>
          <p>
            Vi arbeider i to-ukers sprinter, generelt med ett eller to store mål
            i perioder på 1-6 måneder, etter behov. I planleggingsfasen legger
            vi vekt på motivasjon for problemet som skal løses, og estimerer
            grovt – mens i utviklingsfasen blir den endelige løsningen til, i
            møte med virkelighetens begrensninger.
          </p>
          <p>
            Vi setter av tid i sprinten til å løse uventede ting, for det eneste
            vi vet er at vi ikke har forutsett alt. Dersom denne overskuddstiden
            ikke trengs, da sysler vi med den kule nye featuren vi kom på på
            lønningspilsen, eller vi fikser den irriterende buggen som ingen
            andre tilsynelatende bryr seg om.
          </p>
          <h3>Check-ins</h3>
          <p>
            Underveis har vi check-ins annenhver dag der vi kan lufte
            utfordringer, dele status og diskutere ting som opptar oss – en
            mellomting mellom stand-up og mandagsmøte.
          </p>
          <h3>Demo</h3>
          <p>
            Til slutt deler vi hva vi har gjort med resten av teamet i demo, der
            de andre også presenterer for oss – både drift, marked,
            forretningsutvikling og finans presenterer hva de har gjort, og vi
            viser frem hva vi har bygget for å gjøre Hyre enda bedre enn for to
            uker siden.
          </p>
        </Container>

        <Container
          style={{
            marginBottom: "20px",
            marginTop: "50px",
            paddingBottom: "100px",
          }}
        >
          <h2>Teknologier brukt</h2>
          <Row className="pt-3">
            <Col className="d-flex flex-wrap justify-content-between">
              <Image src={aws} className="mx-2 mb-3" height={26} alt="AWS" />

              <Image
                src={django}
                className="mx-2 mb-3"
                height={26}
                alt="django"
              />

              <Image
                src={github}
                className="mx-2 mb-3"
                height={26}
                alt="github"
              />

              <Image
                src={kotlin}
                className="mx-2 mb-3"
                height={26}
                alt="kotlin"
              />

              <Image src={mypy} className="mx-2 mb-3" height={26} alt="mypy" />

              <Image
                src={postgres}
                className="mx-2 mb-3"
                height={26}
                alt="postgres"
              />

              <Image
                src={python}
                className="mx-2 mb-3"
                height={26}
                alt="python"
              />

              <Image
                src={react}
                className="mx-2 mb-3"
                height={26}
                alt="react"
              />

              <Image
                src={reactNative}
                className="mx-2 mb-3"
                height={26}
                alt="react native"
              />

              <Image
                src={slack}
                className="mx-2 mb-3"
                height={26}
                alt="slack"
              />

              <Image
                src={swift}
                className="mx-2 mb-3"
                height={26}
                alt="swift"
              />

              <Image
                src={terraform}
                className="mx-2 mb-3"
                height={26}
                alt="terraform"
              />

              <Image
                src={typescript}
                className="mx-2 mb-3"
                height={26}
                alt="typescript"
              />

              <Image
                src={zulip}
                className="mx-2 mb-3"
                height={26}
                alt="zulip"
              />
            </Col>
          </Row>
        </Container>
      </div>

      <Container style={{ marginBottom: "20px", marginTop: "100px" }}>
        <h2 className="big-fat-header" style={{ color: "#1c5dff" }}>
          Våre produkter - Egenutviklede systemer
        </h2>
        <p>
          Siden teknologi og automatisering er drivkraften i Hyre, lager vi
          produkter som løser problemer for mange typer brukere – det inkluderer
          flater for sluttbrukerne som leier bil, systemer vi bruker selv
          internt, portaler til servicepartnere og leverandører, samt en hel
          rekke usynlige integrasjoner som tikker og går i det stille.{" "}
        </p>
      </Container>

      <Container style={{ marginBottom: "20px", marginTop: "50px" }}>
        <h2>System mot kunde</h2>

        <h3>Hyre-appen - fra app til mobil på 5 minutter</h3>
        <p>
          Hyre-appen lar deg finne og låse opp en bil når som helst, og vårt mål
          er den smidigste mulige kundereisen.
        </p>
        <p>
          <b>Raskt</b> - Vårt mål er at det skal ta under 5 minutter fra du
          sitter i sofaen til du sitter bak rattet, også om det er din aller
          første Hyre-tur. Man logger enkelt inn med BankID, vi slår automatisk
          opp førerrett mot Statens Vegvesen, slik at du slipper å bruke tid på
          å ta bilde av førerkortet ditt, så velger du bare bil på kartet og
          låser opp med mobilen.
        </p>
        <p>
          <b>Enkelt</b> - Du trenger ikke medlemskap, og du betaler kun for
          bruken. Når du avslutter turen er alt utregnet og gjort opp
          automatisk. Du åpner selvfølgelig bilen med med appen, og vi
          etterstreber alltid å ha bilder med start og stop knapp slik at
          opplevelsen blir enda smidigere.
        </p>
        <p>
          <b>Trygt </b> - Med en moderne bilpark kjører du alltid sikre, trygge
          og miljøvennlige biler, og du er alltid forsikret dersom uhellet
          skulle være ute.
        </p>
        <p>
          <b>Rimelig</b> - Med en effektiv drift i stor skala sørger vi for å ha
          en av markedets rimligste bildelingstjenester, slik at du ikke bruker
          mer penger enn nødvendig på bil når behovet oppstår - enten du bare
          skal avsted noen timer, eller på hyttetur en langhelg.
        </p>
        <Image
          src={feature5minutter}
          alt=""
          className="align-self-start"
          style={{ width: "100%", height: "auto" }}
        />

        <h3>Hyre for Bedrift - Fleksibel firmabil</h3>
        <p>
          Hyre for bedrift er en tjeneste som lar bedrifter bruke bildeling som
          mobilitetsløsning. De ansatte slipper å føre utlegg, bedriften trenger
          ikke å lease eller eie biler, eller å ha ansatte som styrer og steller
          med dem. Alt kommer på én regning, inkludert bompenger, drivstoff,
          lading, parkering og mer. Administratorer får oversikt over kostnader
          i et nyttig webgrensesnitt, og ansatte kan enkelt bytte mellom private
          og jobbrelaterte kjøreturer i appen.
        </p>
        <p>
          Her skal vi også bli enda mye bedre: På bedriftssiden har vi i dag
          bare såvidt begynt å se potensialet. Ved å bli enda bedre på bedrift,
          og tilby bedre løsninger for korttids- og langtidsleie har vi mulighet
          til å senke bedrifters bilkostnader vesentlig, og også gi de bedre
          tilbud, samtidig som vi har færre biler totalt som blir brukt mer. Det
          er vennlig mot både lokalt og globalt miljø!
        </p>
        <Image
          src={featureBedrift}
          alt=""
          className="align-self-start"
          style={{ width: "100%", height: "auto" }}
        />
      </Container>

      <Container style={{ marginBottom: "20px", marginTop: "50px" }}>
        <h2>Interne systemer</h2>
        <h3>Smart orkestrering av bilflåte og eksterne systemer</h3>
        <p>
          Vår egenutviklede mobilitetsplatfogrm består av flere dedikerte
          moduler som hver spiller en avgjørende rolle I hvordan tjenesten vår
          fungerer og interagerer med kunder, leverandører, ansatte og eksterne
          systemleverandører. Med teknologi utvikler vi smarte systemer som
          sammen reduserer behovet for manuelle operasjoner sørger for at vi
          ikke trenger å skalere organsiasjonen I samme takt som tjenesten vår.
        </p>
        <div className="row">
          <div className="col-12 col-md-4 d-flex flex-column">
            <h3>Damages and repairs module</h3>
            <p>
              Med over 3 000 leieforhold hver eneste uke er det dømt å skje
              skader og uhell. Vårt skadesystem sørger for at vi kan håndtere
              hvem som er skyld i skader, taksere, utbedre og fakturere for
              skader på hele bilflåten vår med kun én fulltidsansatt
            </p>
          </div>
          <div className="col-12 col-md-4 d-flex flex-column">
            <h3>Supplier management module</h3>
            <p>
              Service platformen vår er laget for å kunne samkjøre et stort
              antall leverandører mot serviceoppdrag knyttet til bilflåten vår.
              Flere leverandører kan være knyttet til ett serviceoppdrag, som
              henting av bil, til ruteskift, til vask og kjøring tilbake til
              plassen sin.
            </p>
          </div>
          <div className="col-12 col-md-4 d-flex flex-column">
            <h3>Billing and invoicing system</h3>
            <p>
              At betalinger går riktig for seg er en nødvendighet. Siden
              leverandører er i konstant endring har vi bygget et fleksibelt og
              robust system som sørger for at alle kostnader knyttes til hvert
              leieforhold slik at våre kunder slipper å bekymre seg.
            </p>
          </div>
          <div className="col-12 col-md-4 d-flex flex-column">
            <h3>Vehicle module</h3>
            <p>
              Vehicle modulen vår er hjertet av flåte-systemet vårt og gir oss
              et fullstendig bilde av alle hendelser, leieforhold og
              avhengigheter knyttet til biler enkelt bil.
            </p>
          </div>
          <div className="col-12 col-md-4 d-flex flex-column">
            <h3>Parking management module</h3>
            <p>
              Med over 1 500 biler på over 400 forskjellige lokasjoner er
              parkering en av de største driftskostnadene våre og en kritisk del
              av tjenesten vår for å levere god tilgjengelighet der folk bor og
              jobber.
            </p>
          </div>
          <div className="col-12 col-md-4 d-flex flex-column">
            <h3>Service module</h3>
            <p>
              Når biler skal på service og reparasjon er det flere avhengigheter
              som må koordineres internt og med eksterne partnere.
            </p>
          </div>
          <div className="col-12 col-md-4 d-flex flex-column">
            <h3>Tire change module</h3>
            <p>
              Med over 6 000 dekk som skal skiftes to ganger i året over en
              veldig kort periode har vi bygget et automatisk dekkskiftsystem
              som orkestrerer ordre og klargjøring med et sett med leverandører
              for å sikre kort ståtid og raskt skifte.
            </p>
          </div>
          <div className="col-12 col-md-4 d-flex flex-column">
            <h3>Events module</h3>
            <p>
              Events-modulen vår er det som hjelper oss med å holde orden på alt
              som trengs å gjøres knyttet til bilflåten vår eller leieforhold.
              Det er bygget slik at det skal være raskt og effektivt å få løst
              oppgavene, og er derfor tett integrert med våre andre moduler.
            </p>
          </div>
        </div>
      </Container>

      <Container style={{ marginBottom: "20px", marginTop: "50px" }}>
        <h2>System mot leverandør</h2>
        <div
          className="d-flex flex-wrap align-self-center"
          style={{ marginBottom: "0px" }}
        >
          <div className="row">
            <div className="col-12 col-md-6">
              <Image
                src={fleetScreenshot1}
                alt=""
                className="align-self-center"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
            <div className="col-12 col-md-6">
              <Image
                src={fleetScreenshot2}
                alt=""
                className="align-self-center"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
          <h3>Flåte- og serviceportaler</h3>
          <p>
            Hyre som plattform har også funksjonalitet for servicepartnere som
            vasker, flytter eller repararerer biler – og for flåteeiere som
            leier ut gjennom oss. Portalene disse bruker i hverdagen er
            fullverdige produkter for Hyres utviklingsteam – vi bygger
            tilpassede grensesnitt for å se inntjening, planlegge dekkskift
            eller vask og håndtere foliering.
          </p>
          <p>
            På dette området er det fortsatt mange muligheter: Legge oppgaver ut
            på anbud, lage visuelle analyser av bruk, automatisk bestilling av
            riktige arbeidsordre.
          </p>
        </div>
      </Container>
    </div>
  );
}
