import { Block, ExtractValueFromType } from "@/types";
import Image from "next/image";
import RichText from "@/components/RichText";

export const HeaderBottomImage = ({
  background_color,
  text,
  image,
  subtitle_position,
}: ExtractValueFromType<Block, "header_bottom_image_block">) => {
  return (
    <div
      className="index-header pt-4"
      style={{
        backgroundColor: background_color,
        color: background_color === "#1c5dff" ? "white" : "black",
      }}
    >
      <div className="container pb-0 pt-2">
        <div style={{ paddingBottom: 32 }}>
          {subtitle_position === "top" && (
            <div className="hero-tagline text-center pb-1">
              <RichText text={text.body} inline />
            </div>
          )}
          {text.title && (
            <h1 className="big-fat-header text-center">
              <RichText text={text.title} inline />
            </h1>
          )}
          {subtitle_position === "bottom" && (
            <div className="hero-tagline text-center pt-1">
              <RichText text={text.body} inline />
            </div>
          )}
        </div>
        {image && (
          <Image
            src={image.url}
            alt={image.title}
            width={0}
            height={0}
            style={{ width: "100%", height: "auto", marginBottom: 0 }}
          />
        )}
      </div>
    </div>
  );
};
