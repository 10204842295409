"use client";
import React from "react";
import { Col, Container, Row } from "reactstrap";

import Link from "next/link";
import Image from "next/image";

import chevronRight from "../../../../public/images/chevron-right.svg";
import meldskade from "../../../../public/images/b2b/meldskade.png";

class BrukerPage extends React.Component {
  render() {
    return (
      <div>
        {/* <Helmet title="Meld skade - Hyre for Bedrift" /> */}
        <Container
          className="text-center text-md-start"
          style={{
            marginTop: "100px",
            paddingTop: "70px",
            paddingBottom: "50px",
          }}
        >
          <Link href={`/bedrift/hjelp/`}>
            Hjelp Bedrift
            <Image src={chevronRight} style={{ width: 24, margin: 0 }} alt="" />
          </Link>
          <Link href={`/bedrift/hjelp/meldskade`}>Meld skade i appen</Link>
          <hr></hr>
          <Row>
            <Col
              className="d-flex justify-content-center"
              xs="12"
              md="12"
              lg="6"
            >
              <p style={{ paddingTop: "10px" }}>
                <h1 className="big-fat-header">Meld skade i appen</h1>
                Enten bilen har synlig skade når du plukker opp bilen,
                parkeringsripe eller du har vært i en større ulykke melder du
                skaden inn via appen.
                <br></br>
                <br></br>
                Har du vært i en ulykke med andre må du også fylle ut gjensidig
                skadeskjema som normalt.
              </p>
            </Col>
            <Col
              className="d-flex align-items-center justify-content-center"
              xs="12"
              md="12"
              lg="6"
            >
              <Image src={meldskade} alt="" className="align-self-center" />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default BrukerPage;
