"use client";

import React from "react";
import { Col, Container, Row } from "reactstrap";
import Link from "next/link";
import Image from "next/image";
import BedriftLink from "@/components/B2BLink";
import Faq from "@/components/Faq";

import chevronRight from "../../../public/images/chevron-right.svg";
import betalingskort from "../../../public/images/b2b/screenshot-betalingskort2.png";
import fakturaavtrale from "../../../public/images/b2b/screenshot-fakturaavtale2.png";

export default function BedriftGetStartedPayment() {
  return (
    <div>
      {/* <Helmet title="Sett opp betaling - Hyre for Bedrift" /> */}

      <Container
        className="text-center text-md-start"
        style={{ marginTop: "100px", paddingTop: "40px" }}
      >
        <Row>
          <Col xs="12" md="12">
            <h1 className="header big-fat-header" style={{ color: "black" }}>
              Steg 2: Sett opp betaling
            </h1>
            <p>
              Fordelene med Bedriftskonto er at du får samlet alt på én og samme
              betalingsprofil, slik at ansatte slipper å bruke tid på å legge ut
              og selskapet får bedre oversikt over alle kostnadene og bruk av
              bil.
            </p>
          </Col>
        </Row>
      </Container>

      <Container
        className="text-center text-md-start mb-2 mb-md-3"
        style={{ marginTop: "50px" }}
      >
        <Row style={{ marginBottom: "15px" }}>
          <Col xs="12" md="6">
            <div>
              <Image
                src={betalingskort}
                alt=""
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </Col>
          <Col xs="12" md="6">
            <h2 className="header fat-header">Legg inn betalingsmiddel </h2>
            <p>
              Når du registrerer din bedrift må du legge inn et betalingsmiddel
              for å kunne begynne å leie bil på bedriftskontoen. Du betaler
              ingenting for å ha bedriftskontoen aktiv. Du betaler kun når du
              leier bil.
            </p>
          </Col>
        </Row>
      </Container>

      <Container
        className="text-center text-md-start mb-2 mb-md-3"
        style={{ marginTop: "50px" }}
      >
        <Row style={{ marginBottom: "15px" }}>
          <Col xs="12" md="6">
            <div>
              <Image
                src={fakturaavtrale}
                alt=""
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </Col>
          <Col xs="12" md="6">
            <h2 className="header fat-header">Be om fakturaavtale</h2>
            <p>
              Dersom du ønsker faktura fremfor å belaste et betalingskort kan du
              enkelt søke om dette, og du vil få svar i løpet av neste virkedag.
              For å få faktura må bedriften ha kredittscore B eller bedre.
            </p>
            <BedriftLink
              country="NOR"
              style={{ fontSize: "1em", fontWeight: 600 }}
            >
              Gå til bedriftsportalen
              <Image
                src={chevronRight}
                style={{ width: 24, margin: 0 }}
                alt=""
              />
            </BedriftLink>
          </Col>
        </Row>
      </Container>

      <div className="muted-background">
        <Container
          className="text-center text-md-start"
          style={{
            marginTop: "70px",
            paddingTop: "50px",
            paddingBottom: "40px",
          }}
        >
          <Row>
            <Col xs="12" md="6">
              <div className="p-1">
                <Link
                  href={`/bedrift/registrer`}
                  style={{ fontSize: "1em", fontWeight: 500 }}
                >
                  Steg 1: Registrer din bedrift
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
                <hr></hr>
              </div>
              <div className="p-1">
                <Link
                  href={`/bedrift/betaling`}
                  style={{ fontSize: "1em", fontWeight: 600 }}
                >
                  Steg 2: Sett opp betaling
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
                <hr></hr>
              </div>
            </Col>
            <Col xs="12" md="6">
              <div className="p-1">
                <Link
                  href={`/bedrift/lansering`}
                  style={{ fontSize: "1em", fontWeight: 500 }}
                >
                  Steg 3: Planlegg lansering
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
                <hr></hr>
              </div>
              <div className="p-1">
                <Link
                  href={`/bedrift/medlemmer`}
                  style={{ fontSize: "1em", fontWeight: 500 }}
                >
                  Steg 4: Inviter medlemmer
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
                <hr></hr>
              </div>
              <div className="p-1">
                <Link
                  href={`/bedrift/hjelp`}
                  style={{ fontSize: "1em", fontWeight: 500 }}
                >
                  Hjelp
                  <Image
                    src={chevronRight}
                    style={{ width: 24, margin: 0 }}
                    alt=""
                  />
                </Link>
                <hr></hr>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row style={{ marginBottom: "100px", marginTop: "100px" }}>
          <Col>
            <h2 className="header">Ofte stilte spørsmål</h2>
            <Faq
              faqs={[
                {
                  category: "Ofte stilte spørsmål",
                  items: [
                    {
                      title: "Kan jeg få faktura?",
                      content:
                        "Ja, du kan søke om å få månedlig faktura eller enkeltfaktura når du opprettet bedriftskonto, og du får svar innen en virkedag.",
                    },
                    {
                      title: "Er det andre leiepriser for bedrifter?",
                      content:
                        "Du betaler de samme lave leieprisene enten du leier privat eller via en bedriftskonto på Hyre.",
                    },
                    {
                      title:
                        "Hvorfor er det bedre å betale med bedriftskonto hos Hyre?",
                      content:
                        "Hvis en ansatt betaler med bedriftskonto i stedet for å betale med sitt vanlige betalingskort har det flere fordeler. Regningen går direkte til bedriften, så den ansatte slipper å gjøre utlegg. Kvitteringen skrives ut til bedriften, og kan brukes som bilag. Alle kostnader knyttet til leieforholdet, inkludert parkering, lading og tanking som er gjort gjennom appen kommer i bedriftens kostnadsrapporter.",
                    },
                  ],
                },
              ]}
            />

            <div className="text-center pt-5">
              <Link
                href={`/hjelp`}
                style={{ fontSize: "1em", fontWeight: 600 }}
              >
                Klikk her for flere spørsmål og svar.
                <Image
                  src={chevronRight}
                  style={{ width: 24, margin: 0 }}
                  alt=""
                />
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
