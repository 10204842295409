"use client";
import React from "react";
import { Col, Container, Row } from "reactstrap";

import Link from "next/link";
import Image from "next/image";

import chevronRight from "../../../../public/images/chevron-right.svg";
import bestillebil from "../../../../public/images/b2b/bestillebil_oversikt.png";
import bestillebil2 from "../../../../public/images/b2b/bestillbil.png";
import bestillebil3 from "../../../../public/images/b2b/bestillebil3.png";

class BrukerPage extends React.Component {
  render() {
    return (
      <div>
        {/* <Helmet title="Bestille bil - Hyre for Bedrift" /> */}
        <Container
          className="text-center text-md-start"
          style={{
            marginTop: "100px",
            paddingTop: "70px",
            paddingBottom: "50px",
          }}
        >
          <Link href={`/bedrift/hjelp`}>
            Hjelp Bedrift
            <Image src={chevronRight} style={{ width: 24, margin: 0 }} alt="" />
          </Link>
          <Link href={`/bedrift/hjelp/bestille`}>Bestille bil på desktop</Link>
          <hr></hr>
          <Row style={{ paddingTop: "20px" }}>
            <Col xs="12" md="12">
              <h1 className="big-fat-header">Bestille bil på desktop</h1>
              <p>
                Som administrator kan du bestille bil på vegne av deg selv eller
                andre i bedriftsportalen. Kom i gang ved å velge &quot;Bestill
                nå&quot;-knappen. I bestillingsvinduet velges dato, tidspunkt og
                ønsket bil. Legg til sjåfører og trykk &quot;Bestill.&quot;
              </p>
            </Col>
          </Row>
          <Row style={{ paddingTop: "20px" }}>
            <Col xs="12" md="12">
              <p>
                <strong>Følg stegene her:</strong>
              </p>
            </Col>
            <Col xs="12" md="12">
              <p>
                <strong>Steg 1:</strong> Trykk den store blå «Bestill
                nå»-knappen øverst til høyre.
              </p>
            </Col>
            <Col xs="12" md="5">
              <Image
                src={bestillebil}
                alt=""
                style={{ width: "100%", height: "auto", marginBottom: 32 }}
              />
            </Col>
            <Col xs="12" md="12">
              <p>
                <strong>Steg 2:</strong> Velg dato, tidspunkt og ønsket bil.
              </p>
            </Col>
            <Col xs="12" md="5">
              <Image
                src={bestillebil3}
                alt=""
                style={{ width: "100%", height: "auto", marginBottom: 32 }}
              />
            </Col>
            <Col xs="12" md="12">
              <p>
                <strong>Steg 3:</strong> Legg til sjåfører ved å legge inn
                mobilnummer eller navnet under feltet &quot;Velg sjåfører&quot;.
              </p>
            </Col>
            <Col xs="12" md="5">
              <Image
                src={bestillebil2}
                alt=""
                style={{ width: "100%", height: "auto", marginBottom: 32 }}
              />
            </Col>
            <Col xs="12" md="12">
              <p>
                <strong>Steg 4:</strong> Trykk så &quot;Bestill&quot;. Nå sendes
                en SMS ut til alle sjåfører som er lagt til slik at de får
                tilgang til leieforholdet.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default BrukerPage;
