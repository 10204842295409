"use client";
import React from "react";
import { Col, Container, Row } from "reactstrap";

import Link from "next/link";
import Image from "next/image";

import chevronRight from "../../../../public/images/chevron-right.svg";
import rapporter from "../../../../public/images/b2b/rapporter_oversikt.png";

class BrukerPage extends React.Component {
  render() {
    return (
      <div>
        {/* <Helmet title="Hent ut rapporter - Hyre for Bedrift" /> */}
        <Container
          className="text-center text-md-start"
          style={{
            marginTop: "100px",
            paddingTop: "70px",
            paddingBottom: "50px",
          }}
        >
          <Link href={`/bedrift/hjelp/`}>
            Hjelp Bedrift
            <Image src={chevronRight} style={{ width: 24, margin: 0 }} alt="" />
          </Link>
          <Link href={`/bedrift/hjelp/azuread`}>Hent ut rapporter</Link>
          <hr></hr>
          <Row style={{ paddingTop: "10px" }}>
            <Col xs="12" md="12">
              <h1 className="big-fat-header">Hent ut rapporter</h1>
              <p>
                I denne fanen kan du laste ned en regnskapsrapport som viser en
                fullstendig oversikt over alle leieforhold bedriften har betalt
                for, med nedbrytning på kostnadskategorier og avgifter. Dette
                gjelder også klimarapporter.
              </p>
              <p>
                For å laste ned rapportene går du til fanen
                &quot;Rapporter&quot; og velger &quot;Eksporter&quot;.
              </p>
            </Col>
            <Col xs="12" md="6">
              <Image
                src={rapporter}
                alt=""
                className="align-self-center"
                style={{ width: "100%", height: "auto", marginBottom: 32 }}
              />
            </Col>
            <Col xs="12" md="12">
              <p>
                Dersom du i tillegg ønsker salgsdokument, er det to ulike måter:
              </p>
              <p>
                <li>
                  Dersom bedriften har betalingskort kan du finne
                  salgsdokumentet som kvittering på hvert enkelt leieforhold.
                </li>
                <li>
                  Dersom bedriften har fakturaavtale er fakturaen det offisielle
                  salgsdokumentet.
                </li>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default BrukerPage;
