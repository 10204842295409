import RichText from "@/components/RichText";
import { Block, ExtractValueFromType } from "@/types";
import Image from "next/image";

export const ValueProp = ({
  background_color,
  headline,
  explainers,
  end_text,
  image,
}: ExtractValueFromType<Block, "value_prop">) => {
  const explainerColClass =
    !(explainers.length % 2) && explainers.length % 3 ? "col-md-6" : "col-md-4";

  return (
    <div
      style={{
        backgroundColor: background_color,
        color: background_color === "#1c5dff" ? "white" : "black",
      }}
    >
      <div className="my-3 pb-4 pt-5 container text-center">
        <div style={{ flex: 1 }}>
          {headline.title && (
            <div
              style={{
                fontWeight: 600,
                fontSize: 44,
                marginBottom: 12,
              }}
            >
              <RichText text={headline.title} inline />
            </div>
          )}
          {image && (
            <div className="pt-2">
              <Image
                width={600}
                height={400}
                style={{ height: "auto", maxWidth: 600, width: "100%" }}
                src={image.url}
                alt=""
              />
            </div>
          )}
          {headline.body && (
            <div className="pb-2 pt-2 text-center">
              <RichText text={headline.body} />
            </div>
          )}
          <div className="row text-center">
            {explainers.map((e, i) => (
              <div
                key={e.body + i}
                className={` col-12 ${explainerColClass} pt-3 d-flex flex-column`}
              >
                {e.icon && (
                  <Image
                    style={{
                      marginBottom: "1.45rem",
                      alignSelf: "center",
                      objectFit: "contain",
                    }}
                    width={150}
                    height={150}
                    src={e.icon.url}
                    alt={e.icon.title}
                  />
                )}
                {e.title && (
                  <div style={{ fontWeight: 800, marginBottom: 12 }}>
                    <RichText text={e.title} inline />
                  </div>
                )}
                <RichText text={e.body} />
              </div>
            ))}
          </div>
          {end_text && (
            <div className="text-center pt-4">
              <RichText text={end_text} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
