"use client";
import React from "react";
import { Col, Container, Row } from "reactstrap";

import Image from "next/image";

import phoneIcon from "../../../public/icons/phone.png";
import Link from "next/link";
import DownloadLink from "@/components/Downloadlink";

import appStoreBadge from "../../../public/images/app-store-badge.svg";
import googlePlayBadge from "../../../public/images/google-play-badge.svg";

export default function DkContactUs() {
  return (
    <div>
      <div className="index-header">
        <Container>
          <h1 className="hero-header text-center text-md-center pt-4">
            Kontakt Hyre
          </h1>
        </Container>
      </div>
      <Container className="text-center pt-5">
        <p>
          Du kan ringe, sende en e-mail eller chatte direkte med os i appen. Vi
          er tilgængelige på chatten alle dage mellem kl. 07.00 og 23.59. Du kan
          også læse vores
          <Link href={`/hjaelp`}>&nbsp;ofte stillede spørgsmål og svar</Link>.
        </p>
        <Container className="pt-2">
          <h2 className="thin-header mb-2">Vores kontaktoplysninger:</h2>

          <Row className="d-flex flex-row">
            <Col className="col-12 col-md-12 pt-2 pb-3">
              <p>
                <strong>Hyre ApS</strong>
                <br />
                CVR-nummer: 42991481
              </p>
              <p>
                <span
                  style={{
                    width: "26px",
                    fontSize: "38px",
                    lineHeight: "26px",
                    color: "black",
                    fontWeight: 900,
                    display: "inline-block",
                    verticalAlign: "middle",
                    margin: "0 20px 0 0",
                  }}
                >
                  @
                </span>
                <a href="mailto:hej@hyre.dk">hej@hyre.dk</a>
              </p>
              <p>
                <Image
                  src={phoneIcon}
                  width={26}
                  height={26}
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    margin: "0 20px 0 0",
                  }}
                  alt=""
                />
                +45 52 51 26 99
              </p>
            </Col>
          </Row>
          <p className="pt-3">
            <DownloadLink className={`show-on-mobile btn`}>
              Download appen for at chatte med os
            </DownloadLink>
          </p>
          <Row className="show-on-desktop text-center mt-3">
            <Col>
              <a href="https://play.google.com/store/apps/details?id=no.hyre.demand">
                <Image
                  src={googlePlayBadge}
                  alt="Available on Google Play"
                  style={{
                    height: "40px",
                    marginRight: "13px",
                  }}
                />
              </a>
              <a href="https://itunes.apple.com/no/app/hyre/id1294326121">
                <Image
                  src={appStoreBadge}
                  alt="Download from App Store"
                  style={{ height: "40px" }}
                />
              </a>
              <p>Download appen for at chatte med os</p>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
