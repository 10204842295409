"use client";
import React from "react";
import { Col, Container, Row } from "reactstrap";

import Link from "next/link";
import Image from "next/image";

import chevronRight from "../../../../public/images/chevron-right.svg";
import gjenglemt from "../../../../public/images/b2b/gjenglemt.png";

class BrukerPage extends React.Component {
  render() {
    return (
      <div>
        {/* <Helmet title="Gjenglemt - Hyre for Bedrift" /> */}
        <Container
          className="text-center text-md-start"
          style={{
            marginTop: "100px",
            paddingTop: "70px",
            paddingBottom: "50px",
          }}
        >
          <Link href={`/bedrift/hjelp/`}>
            Hjelp Bedrift
            <Image src={chevronRight} style={{ width: 24, margin: 0 }} alt="" />
          </Link>
          <Link href={`/bedrift/hjelp/gjenglemt`}>Gjenglemt</Link>
          <hr></hr>
          <Row>
            <Col
              className="d-flex justify-content-center"
              xs="12"
              md="12"
              lg="6"
            >
              <p style={{ paddingTop: "10px" }}>
                <h1 className="big-fat-header">Gjenglemt</h1>
                Når du nå avslutter et leieforhold vil du se en ny knapp i
                oversikten over dine tidligere leieforhold. I 15 minutter har du
                mulighet til å låse opp bilen og hente ut det som ble lagt
                igjen. Du kan også bruke samme knapp til å gi beskjed om at du
                er ferdig, og bilen låses igjen. Så enkelt er det!
                <br></br>
                <br></br>
                For å legge til medlem trykker du på «Inviter» oppe til høyre.
              </p>
            </Col>
            <Col
              className="d-flex align-items-center justify-content-center"
              xs="12"
              md="12"
              lg="6"
            >
              <Image src={gjenglemt} alt="" className="align-self-center" />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default BrukerPage;
