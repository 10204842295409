"use client";

import React, { useState } from "react";
import Linkify from "react-linkify";
import styles from "./faq.module.css";
import { Collapse, TabContent, TabPane } from "reactstrap";
import { FaqData } from "@/types";
import RichText from "./RichText";

export default function Faq({
  faqs,
  alwaysExpanded,
}: {
  faqs: FaqData;
  alwaysExpanded?: boolean;
}) {
  const [activeTab, setActiveTab] = useState(0);
  const [toggledFaqs, setToggledFaqs] = useState<Record<string, boolean>>({});

  return (
    <div className={styles.faq}>
      {faqs.length > 1 && (
        <div className={styles["navigation-header"]}>
          {faqs.map((faq, i) => (
            <a
              key={i}
              className={i == activeTab ? styles["active"] : ""}
              onClick={(e) => {
                e.preventDefault(), setActiveTab(i);
              }}
            >
              {faq.category}
            </a>
          ))}
        </div>
      )}

      <TabContent activeTab={activeTab}>
        {faqs.map((faq, i) => (
          <TabPane tabId={i} key={i}>
            {faq.items.map((item, j) => (
              <div key={j} className={styles["faq-item"]}>
                <h2
                  role="button"
                  onClick={() =>
                    setToggledFaqs((old) => ({
                      ...old,
                      [`${i}-${j}`]: !old[`${i}-${j}`],
                    }))
                  }
                  className="d-flex justify-content-between"
                  style={alwaysExpanded ? {} : { cursor: "pointer" }}
                >
                  <div>{item.title}</div>
                  {!alwaysExpanded && (
                    <div
                      className={
                        styles["chevron"] +
                        " " +
                        (toggledFaqs[`${i}-${j}`] ? styles["active"] : "")
                      }
                    />
                  )}
                </h2>
                <Collapse isOpen={alwaysExpanded || toggledFaqs[`${i}-${j}`]}>
                  {item.text_block ? (
                    <RichText text={item.text_block} /> // RichTextBlock
                  ) : (
                    item.content!.split("\n").map(
                      // string hardcoded in js/json
                      (part, k) => (
                        <p key={`${i}-${j}-${k}`}>
                          <Linkify>{part}</Linkify>
                        </p>
                      )
                    )
                  )}
                </Collapse>
              </div>
            ))}
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
}
