"use client";
import { Block, ExtractValueFromType } from "@/types";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useState } from "react";
import RichText from "../components/RichText";
import DownloadLink from "@/components/Downloadlink";
import buttonStyles from "@/styles/buttons.module.css";
import chevronRight from "../../public/images/chevron-right.svg";
import Image from "next/image";
import { Prices } from "@/components/Prices";
import Link from "next/link";

type PriceCarouselValue = ExtractValueFromType<Block, "price_carousel_block">;

export const PriceCarousel = ({
  background_color,
  title,
  body,
  download_app_button,
  custom_button_link,
  currency_abbreviation,
  show_currency_after_number,
  category_prices,
}: PriceCarouselValue) => {
  const [selectedItem, setSelectedItem] = useState(0);

  return (
    <div
      className="price-carousel-block my-3"
      style={{
        backgroundColor: background_color,
        color: background_color === "#1c5dff" ? "white" : "black",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 44px 44px 44px",
      }}
    >
      <div
        className="row justify-content-between align-items-center"
        style={{
          maxWidth: "1200px",
          position: "relative",
        }}
      >
        <div style={{ fontWeight: 400 }} className="p-0 pt-4 col">
          <h2 style={{ fontWeight: 600, fontSize: "32px" }}>{title}</h2>
          <RichText text={body} />
        </div>

        <div
          className="col-12 col-md-6"
          style={{
            position: "relative",
            maxWidth: "min(95vw, 500px)",
            width: "100%",
          }}
        >
          <Carousel
            selectedItem={selectedItem}
            onChange={setSelectedItem}
            infiniteLoop={true}
            interval={3000}
            labels={{
              leftArrow: "Previous car price.",
              rightArrow: "Next car price.",
              item: "Car price.",
            }}
            showArrows={false}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            stopOnHover={false}
            swipeable
            transitionTime={500}
          >
            {category_prices.map((category) => (
              <div key={category.title}>
                <Image
                  className="m-0"
                  src={category.image.url}
                  alt=""
                  width={500}
                  height={312}
                  style={{ height: "auto" }}
                />
                <h5
                  style={{
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  {category.title}
                </h5>
                <Prices
                  prices={category.prices}
                  currency={currency_abbreviation}
                  wrappedClassname="price-carousel-prices"
                  pricePointClassname=""
                  kmPrice={category.price_per_km}
                  includedKm={category.km_included}
                  disclaimer={category.disclaimer}
                  showCurrencyAfterNumber={show_currency_after_number}
                />
              </div>
            ))}
          </Carousel>
          <a
            style={{
              position: "absolute",
              top: "50%",
              right: 0,
              cursor: "pointer",
            }}
            onClick={() => setSelectedItem((i) => i + 1)}
          >
            <img src={chevronRight.src} />
          </a>
          <a
            style={{
              position: "absolute",
              top: "50%",
              left: 0,
            }}
            onClick={() => setSelectedItem((i) => i - 1)}
          >
            <img
              src={chevronRight.src}
              style={{ transform: "rotate(180deg)" }}
            />
          </a>
        </div>
        {custom_button_link ? (
          <Link
            href={custom_button_link}
            className={`btn price-carousel-download-button ${buttonStyles.btnElectricBlue} `}
          >
            {download_app_button}
          </Link>
        ) : (
          <DownloadLink
            className={`btn price-carousel-download-button ${buttonStyles.btnElectricBlue} `}
          >
            {download_app_button}
          </DownloadLink>
        )}
      </div>
    </div>
  );
};
