"use client";

import RichText from "@/components/RichText";
import { Block, ExtractValueFromType } from "@/types";
import { useSearchParams } from "next/navigation";

export const MigratedFromLetsgoAlertBlock = ({
  app_letsgo_dk_text,
  booking_letsgo_dk_text,
}: ExtractValueFromType<Block, "migrated_from_letsgo_alert">) => {
  const searchParams = useSearchParams();

  const letsgoSource = searchParams.get("from");

  if (!letsgoSource) {
    return null;
  }

  return (
    <div
      className="p-3"
      style={{ backgroundColor: "rgb(0, 255, 187)", color: "#002b1f" }}
    >
      <div className="container">
        {letsgoSource === "app" && <RichText text={app_letsgo_dk_text} />}

        {letsgoSource === "booking" && (
          <RichText text={booking_letsgo_dk_text} />
        )}
      </div>
    </div>
  );
};
