"use client";
import React from "react";
import { Col, Container, Row } from "reactstrap";

import Image from "next/image";

import aboutUsStyles from "./about_us.module.css";

import phoneIcon from "../../../public/icons/phone.png";
import sameieBlue from "../../../public/icons/sameie-blue.svg";
import familiebilTesla from "../../../public/icons/familiebil-el_tesla.svg";
import demographics from "../../../public/icons/demographics.svg";
import Link from "next/link";
import DownloadLink from "@/components/Downloadlink";

import appStoreBadge from "../../../public/images/app-store-badge.svg";
import googlePlayBadge from "../../../public/images/google-play-badge.svg";

export default function SeContactUs() {
  return (
    <div>
      <div className="index-header">
        <Container>
          <h1 className="hero-header text-center text-md-center pt-4">
            Kontakta Hyre
          </h1>
        </Container>
      </div>
      <Container className="text-center pt-5">
        <p>
          Du kan ringa, mejla eller chatta med oss direkt i appen. Vi finns
          tillgängliga på chatten alla dagar mellan 07.00 och 23.59. Du kan
          också läsa vanliga frågor och svar
          <Link href={`/hjalp`}>&nbsp;vanliga frågor och svar</Link>.
        </p>
        <Container className="pt-2">
          <h2 className="thin-header mb-2">Våra kontaktuppgifter:</h2>

          <Row className="d-flex flex-row">
            <Col className="col-12 col-md-12 pt-2 pb-3">
              <p>
                <strong>Hyre AB</strong>
                <br />
                org. nr 559370-4645
              </p>
              <p>
                <span
                  style={{
                    width: "26px",
                    fontSize: "38px",
                    lineHeight: "26px",
                    color: "black",
                    fontWeight: 900,
                    display: "inline-block",
                    verticalAlign: "middle",
                    margin: "0 20px 0 0",
                  }}
                >
                  @
                </span>
                <a href="mailto:hej@hyre.se">hej@hyre.se</a>
              </p>
              <p>
                <Image
                  src={phoneIcon}
                  width={26}
                  height={26}
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    margin: "0 20px 0 0",
                  }}
                  alt=""
                />
                077-575 76 00
              </p>
            </Col>
          </Row>
          <p className="pt-3">
            <DownloadLink className={`show-on-mobile btn`}>
              Ladda ner appen för att chatta med oss
            </DownloadLink>
          </p>
          <Row className="show-on-desktop text-center mt-3">
            <Col>
              <a href="https://play.google.com/store/apps/details?id=no.hyre.demand">
                <Image
                  src={googlePlayBadge}
                  alt="Available on Google Play"
                  style={{
                    height: "40px",
                    marginRight: "13px",
                  }}
                />
              </a>
              <a href="https://itunes.apple.com/no/app/hyre/id1294326121">
                <Image
                  src={appStoreBadge}
                  alt="Download from App Store"
                  style={{ height: "40px" }}
                />
              </a>
              <p>Ladda ner appen för att chatta med oss.</p>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
