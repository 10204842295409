"use client";
import React from "react";
import { Col, Container, Row } from "reactstrap";

import Link from "next/link";
import Image from "next/image";

import chevronRight from "../../../../public/images/chevron-right.svg";
import medlemmer from "../../../../public/images/b2b/medlemmer_oversikt.png";
import inviter from "../../../../public/images/b2b/inviter-medlemmer.png";
import inviter2 from "../../../../public/images/b2b/inviter-medlemmer_2.png";

class BrukerPage extends React.Component {
  render() {
    return (
      <div>
        {/* <Helmet title="Legg til medlemmer - Hyre for Bedrift" /> */}
        <Container
          className="text-center text-md-start"
          style={{
            marginTop: "100px",
            paddingTop: "70px",
            paddingBottom: "50px",
          }}
        >
          <Link href={`/bedrift/hjelp/`}>
            Hjelp Bedrift
            <Image src={chevronRight} style={{ width: 24, margin: 0 }} alt="" />
          </Link>
          <Link href={`/bedrift/hjelp/medlem`}>Medlemmer</Link>
          <hr></hr>
          <Row style={{ paddingTop: "10px" }}>
            <Col xs="12" md="12">
              <h1 className="big-fat-header">Legg til medlemmer</h1>
              <p>
                I denne har du full oversikt over medlemmer. Her kan du
                administrere medlemmer og legge til nye.
              </p>
              <p>
                For å invitere medlemmer velger du &quot;Inviter&quot;-knappen i
                Medlemmer-fanen. Fyll inn epost eller telefonnummer og velg
                tilgangsnivå. Trykk deretter &quot;Send invitasjoner&quot;.
              </p>
            </Col>
          </Row>
          <Row style={{ paddingTop: "10px" }}>
            <Col xs="12" md="12">
              <p>
                <strong>Følg stegene her:</strong>
              </p>
            </Col>
            <Col xs="12" md="12">
              <p>
                <strong>Steg 1: </strong> Gå til fanen medlemmer. Trykk på
                &quot;Inviter&quot;
              </p>
            </Col>
            <Col xs="12" md="6">
              <Image
                src={medlemmer}
                alt=""
                className="align-self-center"
                style={{ width: "100%", height: "auto", marginBottom: 32 }}
              />
            </Col>
            <Col xs="12" md="12">
              <p>
                <strong>Steg 2: </strong> Fyll inn epost eller telefonnummer og
                velg tilgangsnivå.Du kan også importere CSV-liste.
              </p>
            </Col>
            <Col xs="6" md="4">
              <Image
                src={inviter}
                alt=""
                className="align-self-center"
                style={{ width: "100%", height: "auto", marginBottom: 32 }}
              />
            </Col>
            <Col xs="6" md="4">
              <Image
                src={inviter2}
                alt=""
                className="align-self-center"
                style={{ width: "100%", height: "auto", marginBottom: 32 }}
              />
            </Col>
            <Col xs="12" md="12">
              <p>
                <strong>Steg 3: </strong> Trykk på &quot;Send
                invitasjoner&quot;.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default BrukerPage;
