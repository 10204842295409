"use client";
import buttonStyles from "@/styles/buttons.module.css";
import { useSearchParams } from "next/navigation";

export const CustomCTA = ({
  label,
  link,
  style,
}: {
  label: string;
  link: { to: string; new_window: boolean };
  style: "link" | "green-button";
}) => {
  const buttonStyleToClassName = {
    "green-button": `btn ${buttonStyles.btnGreenishTurquoise}`,
    link: "text-white text-decoration-underline",
  };

  const searchParams = useSearchParams();
  const letsgoSource = searchParams.get("from");

  let url = link.to;
  try {
    // Defensively parse the URL, to avoid crashing should we encounter a CTA wired to a relative URL or just an anchor link.
    const urlObject = new URL(link.to);
    if (["app", "booking"].includes(letsgoSource ?? "")) {
      urlObject.searchParams.set("from", "letsgo");
    }
    url = urlObject.href;
  } catch {}

  return (
    <a
      className={`${buttonStyleToClassName[style]} align-self-center align-self-md-start`}
      href={url}
      target={link.new_window ? "_blank" : ""}
    >
      {label}
    </a>
  );
};
